import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import { authUserSelector } from '../Session/selectors';

import { styles } from './Follow.style';
import FollowersBase from './Followers';
import FollowingBase from './Following';

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});
const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

const condition = authUser => !!authUser;

export const FollowersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withFirebase
)(withStyles(styles)(FollowersBase));

export const FollowingPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withFirebase
)(withStyles(styles)(FollowingBase));

export default {
  FollowersPage,
  FollowingPage
};
