/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Table, Select, Popconfirm } from 'antd';

import { nanoid } from 'nanoid';

import { withFirebase } from '../Firebase';
// import {
//   allArticlesSelector,
//   limitSelector,
//   categorySelector
// } from './selectors';
import { styles } from './AdminPanel.style';

import { getStrippedString, formatDate } from '../../common/Helpers';

const { Option } = Select;
const scrollX = { x: 'max-content' };
const selectStyle = { width: 110 };

const AdminPanel = ({ authUser, classes, history, firebase }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (!data) {
      getArticles();
    }
  }, []);

  const getStrippedSummary = content => {
    const words = content.split(' ');

    const maxWords = 7;

    let summary = words.join(' ');
    if (words.length > maxWords) {
      summary = `${words.slice(0, maxWords).join(' ')} ...`;
    }

    return summary;
  };

  const getArticles = async () => {
    setLoading(true);
    try {
      const args = {
        filters: [
          {
            field: 'status',
            condition: 'in',
            value: ['in review', 'rejected']
          }
        ],
        orders: [
          {
            field: 'created',
            value: 'desc'
          }
        ]
      };
      const argsss = {
        filters: [
          {
            field: 'reviewed',
            condition: '==',
            value: true
          }
        ]
      };
      let data = [];
      const inReview = await firebase.getPostsWithArgs(args);
      data.push(...inReview);

      const reviewed = await firebase.getPostsWithArgs(argsss);
      data.push(...reviewed);

      const dataArticles = {};
      const dataArticlesFormatted = [];
      const anonUserForGet = [];
      let anonData = [];

      data.forEach(s => {
        if (s.anonymous) {
          anonUserForGet.push(s.originalContributor);
        }
      });
      for (let i = 0; i < anonUserForGet.length; i += 10) {
        const requests = [];

        requests.push(
          getAnonsUser10(anonUserForGet.slice(i, i + 10)).catch(e =>
            console.log(e)
          )
        );

        await Promise.all(requests)
          .then(a => {
            anonData = [...anonData, ...a[0]];
          })
          .catch(e => console.log(e));
      }

      data.forEach(s => {
        dataArticles[s.uid] = s;
        let anon = null;
        if (s.anonymous) {
          anon = anonData.find(el => el.uid === s.originalContributor);
        }
        dataArticlesFormatted.push({
          key: s.uid,
          content:
            s.content.replace(/<[^>]+>/g, '').length <= 60
              ? s.content.replace(/<[^>]+>/g, '')
              : getStrippedSummary(s.content.replace(/<[^>]+>/g, '')),
          contentFull: s.content,
          contributor: s.contributorRef,
          contributorOrigin: s.originalContributor
            ? anon.uid
            : s.contributorRef,
          usernameOrigin: anon
            ? anon && anon.firstName && anon.lastName
              ? `${anon.firstName} ${anon.lastName}`
              : anon.username
            : '',
          status: s.status,
          type: s.type,
          created: formatDate(s.created),
          username:
            s.contributor.firstName && s.contributor.lastName
              ? `${s.contributor.firstName} ${s.contributor.lastName}`
              : s.contributor.username
        });
      });

      setData(dataArticlesFormatted);

      setLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };
  const getAnonsUser10 = async arr => {
    try {
      const args = {
        filters: [
          {
            field: 'documentId',
            condition: 'in',
            value: arr
          }
        ]
      };
      const followersRefs = await firebase.getUsersWithArgs(args);

      return followersRefs;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleSelectChange = useCallback(
    (props, value) => {
      if (selected.length === 0) {
        return setSelected([...[{ key: props.key, status: value }]]);
      }
      let change = selected.findIndex(el => el.key === props.key);

      if (change === -1) {
        return setSelected(prev => [
          ...prev,
          ...[{ key: props.key, status: value }]
        ]);
      }

      if (change !== -1) {
        let arr = [...selected];
        arr[change].status = value;
        return setSelected(arr);
      }
    },
    [selected]
  );

  const onBtnClick = useCallback(
    props => {
      let elem = selected.find(el => el.key === props.key);
      if (elem) {
        updateComments(elem, props);
      }
    },
    [selected]
  );

  const updateComments = (elem, props) => {
    const body = {
      data: {
        status: elem.status,
        reviewed: elem.status === 'published' ? true : false
      },
      merge: true
    };

    firebase
      .editPost(elem.key, body, authUser.uid)
      .then(() => {
        makeNotification('postApproved', elem, props);

        const newData = [...data];
        const articleIndex = newData.findIndex(a => a.key === elem.key);

        if (articleIndex !== -1) {
          let newSelectedArr = selected.filter(e => e.key !== elem.key);

          setSelected(newSelectedArr);
          newData[articleIndex].status = elem.status;
          newData[articleIndex].reviewed = true;
          setData(newData);
        }
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  const makeNotification = (type, elem, props) => {
    const MAX_CHARACTERS = 30;

    const title = props.content;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      // user: {
      //   avatar: avatar || '',
      //   uid: authUser.uid,
      //   fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      // },
      post: {
        uid: elem.key,
        title: getStrippedString(title, MAX_CHARACTERS),
        postStatus: elem.status
      },
      hasRead: false
    };

    sendNotification(notification, props);
  };

  const sendNotification = async (notification, props) => {
    const followers = [props.contributorOrigin];

    const body = {
      followers,
      notification
    };

    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  const columns = [
    {
      title: 'Сontributor',
      // dataIndex: 'contributor',
      key: 'contributor',
      render: props => (
        <a href={`/author/${props.contributor}`}>{props.username}</a>
      ),
      filters: [
        {
          text: 'anon users',
          value: 'anonymous'
        },
        {
          text: 'users',
          value: 'users'
        }
      ],
      onFilter: (value, record) => {
        if (value === 'anonymous') {
          return record.username.indexOf('Zonotho Anonymous') === 0;
        }
        if (value === 'users') {
          return record.username.indexOf('Zonotho Anonymous') === -1;
        }
      }
    },
    {
      title: 'Сontributor Origin',
      key: 'contributorOrigin',
      render: props => (
        <a href={`/author/${props.contributorOrigin}`}>
          {props.usernameOrigin ? props.usernameOrigin : props.username}
        </a>
      )
    },
    {
      title: 'Content',
      dataIndex: 'content'
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: props => <div>{moment(props).format('DD/MMM/YYYY, HH:mm')}</div>
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },

    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'in review',
          value: 'in review'
        },
        {
          text: 'published',
          value: 'published'
        },
        {
          text: 'rejected',
          value: 'rejected'
        }
      ],
      onFilter: (value, record) => {
        return record.status.indexOf(value) === 0;
      }
    },

    {
      title: 'Change status',
      key: 'action',
      render: props => (
        <Select
          onChange={e => handleSelectChange(props, e)}
          placeholder="Change"
          style={selectStyle}>
          <Option value=""></Option>
          <Option value="in review" disabled={props.status === 'in review'}>
            in review
          </Option>
          <Option value="published" disabled={props.status === 'published'}>
            published
          </Option>
          <Option value="rejected" disabled={props.status === 'rejected'}>
            rejected
          </Option>
        </Select>
      )
    },
    {
      title: '',
      key: 'action2',
      render: props => (
        <Popconfirm title="Sure to submit?" onConfirm={() => onBtnClick(props)}>
          <a>Submit</a>
        </Popconfirm>
      )
    }
  ];

  const number = useMemo(() => columns, [columns]);

  return (
    <Grid container item lg={12} md={12} xs={12} className={classes.content}>
      <Table
        loading={loading}
        className={classes.tableRoot}
        pagination={false}
        columns={number}
        dataSource={data}
        scroll={scrollX}
        expandable={{
          expandedRowRender: record => (
            <div
              className={classes.contentFullStyle}
              dangerouslySetInnerHTML={{
                __html: record.contentFull
              }}></div>
          ),
          expandIcon: ({ expanded, onExpand, record }, props) =>
            expanded ? (
              <div onClick={e => onExpand(record, e)}>
                <a>Show less</a>
              </div>
            ) : (
              <div onClick={e => onExpand(record, e)}>
                <a>Show more</a>
              </div>
            ),
          expandIconColumnIndex: 4
        }}
      />
    </Grid>
  );
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
  //   articles: allArticlesSelector(state),
  //   limit: limitSelector(state),
  //   category: categorySelector(state)
});

const mapDispatchToProps = dispatch => ({
  // onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  // onSetArticles: articles => dispatch({ type: 'ARTICLES_SET', articles }),
  // onSetArticlesLimit: limit => dispatch({ type: 'ARTICLES_LIMIT_SET', limit }),
  // showAlert: alert => dispatch({ type: 'ALERT_SET', alert }),
  // showSighInDialog: (locationState, message) =>
  //   dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(AdminPanel));
