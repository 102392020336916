export const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 400
  },
  noFollowTitle: {},
  followList: {
    listStyle: 'none',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  }
});

export default styles;
