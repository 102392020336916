export default (content, size = 30) => {
  const stripedHtml = content.replace(/<[^>]+>/g, '');
  const words = stripedHtml.split(' ');
  const stripped = [];
  let freePlace = size;

  words.forEach(word => {
    let part = word;
    if (freePlace === 0) {
      return;
    }

    if (word.length > freePlace) {
      part = `${word.substring(0, freePlace)}...`;
      freePlace = 0;
    }

    if (freePlace > 0) {
      freePlace -= word.length;
    }

    stripped.push(part);
  });

  return stripped.join(' ');
};
