import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid, Avatar, Typography, Button, Popover } from '@material-ui/core';
import { MoreOptions } from '../moreOptions';
import { Delete } from '../../../PopoverMessages';
import { styles } from './Contributor.style';

const DEFAULT_AVATAR = '/images/default-avatar.png';
const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center'
};
const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center'
};
const DELETE_MESSAGE = 'Are you sure you want to delete this post?';
const TEMPERORY_EDIT_MESSAGE =
  'Edit functionality is coming soon. Would you like to delete instead?';

const Contributor = ({
  classes,
  avatar,
  fullName,
  status,
  isAuthor,
  isFollowed,
  onFollowClick,
  onDeleteClick,
  anonymous
}) => {
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [shouldShowDeleteMessage, showDeleteMessage] = useState(false);
  const [shouldShowEditMessage, showEditMessage] = useState(false);
  const articleRef = useRef();

  const handleOptionsClick = e => {
    setOptionAnchorEl(e.currentTarget);
  };

  const closeOptions = () => {
    setOptionAnchorEl(null);
  };

  const handleEditOptionClick = () => {
    showEditMessage(true);
    closeOptions();
  };

  const handleDeleteOptionClick = () => {
    showDeleteMessage(true);
    closeOptions();
  };

  const closeEditMessage = () => {
    showEditMessage(false);
  };

  const closeDeleteMessage = () => {
    showDeleteMessage(false);
  };

  const handleDeletePostClick = () => {
    if (showDeleteMessage) closeDeleteMessage();
    if (showEditMessage) closeEditMessage();
    onDeleteClick();
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      spacing={2}
      ref={articleRef}>
      <Grid item>
        <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography variant="h5">{fullName}</Typography>
        <Typography component="span" variant="body1">
          {status}
        </Typography>
      </Grid>
      {!isAuthor && !anonymous && (
        <Grid item>
          <Button
            id="follow-btn"
            className={classes.followBtn}
            variant="outlined"
            size="small"
            disabled={isFollowed}
            onClick={onFollowClick}>
            {isFollowed ? 'Following' : 'Follow'}
          </Button>
        </Grid>
      )}
      {isAuthor && (
        <Grid item style={{ marginBottom: 'auto' }}>
          <MoreOptions
            size="small"
            anchorEl={optionAnchorEl}
            onOptionsClick={handleOptionsClick}
            onOptionClose={closeOptions}
            onEditClick={handleEditOptionClick}
            onDeleteClick={handleDeleteOptionClick}
            isEditable={isAuthor}
          />
        </Grid>
      )}
      <Popover
        anchorEl={articleRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowDeleteMessage}
        onClose={closeDeleteMessage}>
        <Delete
          message={DELETE_MESSAGE}
          onCancelClick={closeDeleteMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
      <Popover
        anchorEl={articleRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowEditMessage}
        onClose={closeEditMessage}>
        <Delete
          message={TEMPERORY_EDIT_MESSAGE}
          onCancelClick={closeEditMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
    </Grid>
  );
};

Contributor.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isFollowed: PropTypes.bool,
  onFollowClick: PropTypes.func.isRequired
};

Contributor.defaultProps = {
  avatar: DEFAULT_AVATAR,
  isFollowed: false
};

export default withStyles(styles)(Contributor);
