import { Tabs, Tab, Badge, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.topBar
  },
  navBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0,
    fontSize: 20,
    color: theme.palette.icon,
    [theme.breakpoints.up('lg')]: {
      fontSize: 28
    }
  },
  logoContainer: {
    marginLeft: theme.spacing(4)
  },
  logo: {
    display: 'block',
    width: 111,
    height: 46,
    '& img': {
      width: '100%',
      height: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: 172,
      height: 71
    }
  },
  tabs: {},
  tab: {
    '&.newPostTab': {
      marginRight: theme.spacing(3.5),
      backgroundColor: '#82637C1A',
      color: '#755970',
      opacity: 1,
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(5)
      }
    },
    '&.searchTab': {
      marginLeft: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(7.5)
      }
    }
  }
});

export const StyledTabs = withStyles(() => ({
  indicator: {
    display: 'none'
  }
}))(Tabs);

export const StyledTab = withStyles(theme => ({
  root: {
    minWidth: 'auto',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: 1.25,
    '&.Mui-selected': {
      color: theme.palette.text.brand
    },
    '& svg': {
      width: 28,
      height: 28
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '19px'
    }
  }
}))(Tab);

export const StyledBadge = withStyles(() => ({
  badge: {
    top: 1,
    right: 1,
    height: 14,
    minWidth: 12,
    padding: '0 3px 0 4px',
    fontSize: '0.42em',
    lineHeight: '1em',
    backgroundColor: '#D01801',
    color: 'white'
  }
}))(Badge);

export const RoundIconButton = withStyles(theme => ({
  root: {
    padding: 5,
    fontSize: 22,
    color: theme.palette.text.brand,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 5px #00000033',
    '& .MuiSvgIcon-root': {
      width: 21,
      height: 21
    }
  }
}))(IconButton);

export default styles;
