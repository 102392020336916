import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import articleReducer from './article';
import categoryReducer from './category';
import messagesReducer from './messages';
import authorReducer from './author';
import searchReducer from './search';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  articleState: articleReducer,
  authorState: authorReducer,
  categoryState: categoryReducer,
  messagesState: messagesReducer,
  searchState: searchReducer
});

export default rootReducer;
