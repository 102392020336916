import { withStyles } from '@material-ui/styles';
import { Radio, Button } from '@material-ui/core';

export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0, 0),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  },
  pageTitle: {
    fontWeight: 400,
    padding: theme.spacing(0, 2, 3)
  },
  form: {
    padding: theme.spacing(1.5, 0, 0),
    borderRadius: '9px',
    margin: theme.spacing(0, 2, 0, 2)
  },
  typeGrid: {
    marginBottom: theme.spacing(3)
  },
  fieldGrid: {},
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  typeLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.6667,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    letterSpacing: 1.88,
    '&.Mui-focused': {
      color: theme.palette.text.secondary
    }
  },
  typeList: {
    display: 'flex',
    flexDirection: 'row'
  },
  typeItem: {
    margin: 0,
    '&:not(:last-child)': {
      marginRight: theme.spacing(3)
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
      lineHeight: 1.3334,
      color: theme.palette.text.secondary,
      letterSpacing: 0.4
    }
  },
  photoLabel: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    letterSpacing: 1.88
  },
  photoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: theme.palette.text.secondary,
    '&.Mui-disabled': {
      opacity: '0.2'
    }
  },
  photoImage: {
    maxHeight: '80px',
    padding: '8px 0 6px 6px',
    objectFit: 'contain'
  },
  videoPlayer: {
    height: 128,
    padding: '8px 0 6px 6px',
    '& iframe': {
      display: 'block'
    }
  },
  photoUploadButton: {
    height: '100%',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    color: 'inherit',
    textTransform: 'none'
  },
  removeBtn: {
    height: '100%',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    color: 'inherit',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    opacity: 0.6
  },

  photoAdditional: {
    fontSize: 9,
    lineHeight: 2,
    color: theme.palette.text.secondary,
    opacity: 0.8,
    '& a': {
      color: theme.palette.text.brand
    }
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5',
    marginRight: '6px'
  },
  addBtn: {
    color: '#64A6B8',
    marginLeft: '4px',
    height: '32px',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  padingBtn: {
    padding: '6px 0px'
  },
  postBtn: {
    backgroundColor: theme.palette.background.brand
  },
  successMessage: {
    borderRadius: '9px',
    margin: theme.spacing(0, 2, 0, 2),
    padding: theme.spacing(6, 2)
  },
  successMessageContent: {
    marginBottom: theme.spacing(7),
    padding: 0
  },
  successMessageTitle: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  successMessageActions: {
    justifyContent: 'center',
    padding: 0
  },
  viewPostBtn: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: '#ffffff',
    backgroundColor: theme.palette.background.brand
  },
  label: {
    position: 'static',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    padding: theme.spacing(0, 2, 2)
  },
  slateRoot: {
    color: '#666666',
    border: '1px solid #666666'
  },
  border: {
    borderTop: '1px solid rgb(102,102,102, 0.2)'
  },
  borderBtn: {
    borderRight: '1px solid rgb(102,102,102, 0.2)',
    borderRadius: '0px'
  },
  wrapBtnGroup: {
    padding: theme.spacing(1.5, 2)
  },
  containerBtn: {
    height: '32px',
    borderTop: `1px solid rgb(102,102,102, 0.2)`
  },
  containerHeight: {
    height: '100%'
  },
  commentInput: {
    width: '100%',
    minWidth: '170px',
    fontSize: '12px',
    height: '27px',
    padding: '1px 10px 0 10px',
    backgroundColor: 'rgba(87, 156, 177, 0.2)',
    border: '1px solid #7070701A',
    borderRadius: '2px',
    margin: theme.spacing(1, 2)
  }
});

export const StyledRadio = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: 20,
    '&.MuiSwitch-colorSecondary.Mui-checked': {
      color: '#008265'
    }
  }
}))(Radio);

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);

export default styles;
