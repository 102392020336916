import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import { authUserSelector } from '../Session/selectors';

import PostsWithSpecificTypesBase from './PostsWithSpecificTypes';
import { styles } from './style';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

const PostsWithSpecificTypes = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(withStyles(styles)(PostsWithSpecificTypesBase));

export default PostsWithSpecificTypes;
