import React, { Component, lazy, Suspense } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { ARTICLES } from '../../constants/routes';
import { changeUserCoins, getStrippedString } from '../../common/Helpers';
import { ArticleTypeFilterCard } from '../common';
import { nanoid } from 'nanoid';

const ArticleListComponent = lazy(() => import('../Articles/ArticleList'));

const renderLoader = () => <p>Loading</p>;

const LOAD_MORE_LIMIT = 10;

const getArticles = async ({ firebase, limit, articlesType, success }) => {
  const args = {
    filters: [
      {
        field: 'status',
        condition: '==',
        value: 'published'
      }
    ],
    orders: [
      {
        field: 'popularityScore',
        value: 'desc'
      }
    ],
    limit
  };

  if (articlesType !== 'all') {
    args.filters.push({
      field: 'type',
      condition: '==',
      value: `${articlesType}`
    });
  }

  const articles = await firebase.getPostsWithArgs(args);

  success(articles);
};

class TrendsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      limit: LOAD_MORE_LIMIT,
      filter: 'all',
      loading: true,
      loadingMore: false
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    const { limit, filter } = this.state;

    const requestOptions = {
      firebase,
      limit,
      articlesType: filter,
      success: this.handleSuccessGettingArticles
    };

    getArticles(requestOptions);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { firebase } = this.props;
    const { limit: prevLimit, filter: prevFilter } = prevState;
    const { limit, filter } = this.state;

    if (prevLimit !== limit || prevFilter !== filter) {
      const requestOptions = {
        firebase,
        limit,
        articlesType: filter,
        success: this.handleSuccessGettingArticles
      };

      getArticles(requestOptions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { loadingMore } = this.state;
    const isBottom =
      document.documentElement.scrollHeight - window.innerHeight <
      window.scrollY + 20;

    if (!isBottom || loadingMore) return;

    this.onLoadMore();
  };

  handleSuccessGettingArticles = articles => {
    this.setState({
      articles,
      loading: false,
      loadingMore: false
    });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value,
      limit: LOAD_MORE_LIMIT,
      loading: true
    });
  };

  onLoadMore = () => {
    this.setState({
      limit: this.state.limit + LOAD_MORE_LIMIT,
      loadingMore: true
    });
  };

  onLikeClick = articleId => {
    const { authUser } = this.props;
    const { articles } = this.state;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { firebase, authUser } = this.props;
    const { articles } = this.state;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(articleId, body, authUser.uid)
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        this.setState({
          articles: [...newArticles]
        });
      })
      .catch(error => {
        console.log('error :', error);
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  onCommentLikeClick = (commentId, articleId) => {
    const { authUser } = this.props;
    const { articles } = this.state;

    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the comment'
      );
      return;
    }

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid),
        article
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid),
        article
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid),
      article
    );
  };

  updateArticleComments = (commentId, comment, doUpdateUserCoins, article) => {
    const { firebase } = this.props;
    const { articles } = this.state;

    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };
    firebase
      .article(article.uid)
      .set(
        {
          comments
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        doUpdateUserCoins();
        this.setState({ articles: newArticles });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  makeNewComment = (comment, articleId) => {
    const { authUser, firebase } = this.props;
    const { articles } = this.state;
    const article = articles.find(a => a.uid === articleId);

    const id = nanoid();
    const isNewComment = true;

    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date())
    };
    const comments = article.comments ? { ...article.comments } : {};
    comments[id] = { ...newComment };

    this.updateComments(comments, isNewComment, article);
  };

  updateComments = (comments, isNewComment = false, article) => {
    const { firebase, history, authUser } = this.props;

    const body = {
      data: {
        comments: { ...comments }
      }
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response', article);
        }
        history.push({
          pathname: `${ARTICLES}/${article.uid}`,
          state: { fromArticles: true }
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  makeNotification = (type, article) => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;

    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification, article);
  };

  sendNotification = async (notification, article) => {
    const { firebase, authUser } = this.props;
    const followers = await this.collectUsersToNotification(article);

    const body = {
      followers,
      notification
    };
    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  collectUsersToNotification = async article => {
    const { authUser, firebase } = this.props;

    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: article.uid
        }
      ]
    };

    const savedArticleUsersRef = await firebase.getUsersWithArgs(args);

    if (savedArticleUsersRef.length > 0) {
      for (const userRef of savedArticleUsersRef) {
        if (userRef.uid !== authUser.uid) {
          collectedUsers.push(userRef.uid);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };

  render() {
    const { classes, authUser } = this.props;
    const { articles, filter, loading, loadingMore } = this.state;
    const userSavedArticlesIds = (authUser && authUser.savedArticles) || [];

    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item className={classes.headerGrid}>
            <div className={classes.header}>
              <Typography className={classes.pageTitle} variant="h1">
                Discover the hottest content
              </Typography>
              <Typography className={classes.pageSubtitle}>
                The most liked and talked about content on Zonotho right now
              </Typography>
            </div>
          </Grid>
          <Grid item className={classes.filterGrid}>
            <ArticleTypeFilterCard
              value={filter}
              isDisabled={loading}
              onChange={this.handleFilterChange}
            />
          </Grid>
          <Grid item className={classes.articlesGrid}>
            <>
              {!loading && articles.length === 0 && (
                <Typography align="center" variant="body1">
                  There are not any posts
                </Typography>
              )}
              {!loading && articles.length > 0 && (
                <Suspense fallback={renderLoader()}>
                  <ArticleListComponent
                    userId={authUser ? authUser.uid : null}
                    articles={articles}
                    savedArticlesIds={userSavedArticlesIds}
                    onLikeClick={this.onLikeClick}
                    onSaveClick={this.onSaveClick}
                    onCommentLikeClick={this.onCommentLikeClick}
                    makeNewComment={this.makeNewComment}
                  />
                </Suspense>
              )}
            </>
          </Grid>

          {(loading || loadingMore) && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default TrendsBase;
