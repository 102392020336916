export const styles = theme => ({
  avatar: {
    width: 40,
    height: 40
  },
  followBtn: {
    minWidth: 78,
    padding: '3px 3px 2px',
    fontSize: 11,
    lineHeight: '15px',
    letterSpacing: 0.98,
    color: theme.palette.text.brand,
    borderColor: theme.palette.text.brand,
    '&:disabled': {
      color: '#8E6C88',
      borderColor: '#8E6C88'
    }
  }
});

export default styles;
