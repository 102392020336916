import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import {
  ARTICLES,
  TRENDS,
  POPULAR_USERS,
  SIGN_IN,
  NOTIFICATIONS,
  NEW_POST,
  PROFILE,
  ALL_ARTICLES,
  ALL_VIDEOS,
  SETTINGS,
  SEARCH,
  PRIVACY
} from '../../constants/routes';

import {
  AppBar,
  Typography,
  SwipeableDrawer,
  ListItem,
  ListItemIcon,
  Grid,
  Avatar
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import {
  LibraryBooks,
  Notifications,
  Add,
  People,
  OndemandVideo,
  Search,
  Settings,
  Description,
  Menu
} from '@material-ui/icons';

// import { Icon } from '@iconify/react';
// import fireIcon from '@iconify/icons-mdi/fire';

import {
  OpenTextPage as OpenTextPageIcon,
  Flame as FlameIcon
} from '../../icons';

import {
  styles,
  StyledTabs,
  StyledTab,
  StyledBadge,
  RoundIconButton
} from './BottomBar.style';
import { newNotificationsAmount } from '../../components/Notifications/selectors';

const TAB_VALUES = [ARTICLES, NOTIFICATIONS, NEW_POST, TRENDS];

const DEFAULT_AVATAR = '/images/default-avatar.png';
class BottomBar extends Component {
  constructor(props) {
    super(props);

    this.state = { openDrawer: false };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { openDrawer } = this.state;
    if (!openDrawer) {
      return;
    }
    const emojiBlock = document.getElementById('burgerMenu');
    if (event.path && !event.path.includes(emojiBlock)) {
      if (
        openDrawer &&
        this.wrapperRef &&
        !this.wrapperRef.contains(event.target)
      ) {
        this.setState({ openDrawer: false });
      }
    }
  }

  toggleDrawer = () => {
    const { openDrawer } = this.state;
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    this.setState({ openDrawer: !openDrawer });
  };

  closeIOS = () => {
    const { openDrawer } = this.state;
    if (openDrawer) {
      this.setState({ openDrawer: false });
    }
  };

  render() {
    const { classes, location, authUser, newNotificationsAmount } = this.props;
    const { openDrawer } = this.state;
    const avatar = (authUser && authUser.avatar) || DEFAULT_AVATAR;

    const MENU_ITEMS = [
      {
        icon: (
          <Avatar alt="Avatar" className={classes.userAvatar} src={avatar} />
        ),
        title: 'Profile',
        linkTo: PROFILE
      },
      {
        icon: <LibraryBooks className={classes.icon} htmlColor="#906D8A" />,
        title: 'Articles',
        linkTo: ALL_ARTICLES
      },
      {
        icon: <OndemandVideo className={classes.icon} htmlColor="#BF0900" />,
        title: 'Videos',
        linkTo: ALL_VIDEOS
      },
      {
        icon: <People className={classes.icon} htmlColor="#64A7BA" />,
        title: 'People To Follow',
        linkTo: POPULAR_USERS
      },
      {
        icon: <Search className={classes.icon} htmlColor="#FE8807" />,
        title: 'Explore Topics',
        linkTo: SEARCH
      },
      {
        icon: <Settings className={classes.icon} htmlColor="#3B93FA" />,
        title: 'Settings',
        linkTo: SETTINGS
      },
      {
        icon: <Description className={classes.icon} htmlColor="#980076" />,
        title: 'Legal Stuff',
        linkTo: PRIVACY
      }
    ];
    const selectedTab = TAB_VALUES.includes(location.pathname)
      ? location.pathname
      : false;

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
      <>
        <SwipeableDrawer
          ref={this.setWrapperRef}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          anchor={'bottom'}
          open={openDrawer}
          className={classes.rootDrawer}
          onOpen={this.toggleDrawer}
          onClose={this.toggleDrawer}>
          {
            <>
              <Typography className={classes.pageTitle} variant="h1">
                Menu
              </Typography>
              <Grid container className={classes.listContainer}>
                {MENU_ITEMS.map(el => (
                  <ListItem
                    button
                    key={el.title}
                    onClick={this.toggleDrawer}
                    component={RouterLink}
                    to={
                      !authUser &&
                      (el.linkTo === SETTINGS || el.linkTo === PROFILE)
                        ? SIGN_IN
                        : el.linkTo
                    }
                    className={classes.wrapItem}>
                    <ListItemIcon>{el.icon}</ListItemIcon>
                    <Typography className={classes.listTitle}>
                      {el.title}
                    </Typography>
                  </ListItem>
                ))}
              </Grid>
            </>
          }
        </SwipeableDrawer>
        <AppBar className={classes.root} component="nav">
          <StyledTabs
            className={classes.tabs}
            value={selectedTab}
            variant="fullWidth"
            aria-label="page tabs">
            <StyledTab
              onClick={this.closeIOS}
              className={classes.tab}
              icon={<OpenTextPageIcon className={classes.icon} />}
              // icon={<LibraryBooks className={classes.icon} />}
              component={RouterLink}
              to={ARTICLES}
              value={ARTICLES}
              label={'Feed'}
            />
            <StyledTab
              onClick={this.closeIOS}
              className={classes.tab}
              icon={<FlameIcon className={classes.icon} />}
              // icon={<Icon icon={fireIcon} className={classes.icon} />}
              component={RouterLink}
              to={TRENDS}
              value={TRENDS}
              label={'Trending'}
            />
            <StyledTab
              className={classes.addTab}
              icon={
                <RoundIconButton className={classes.addIcon}>
                  <Add />
                </RoundIconButton>
              }
              component={RouterLink}
              to={!authUser ? SIGN_IN : NEW_POST}
              value={!authUser ? SIGN_IN : NEW_POST}
              disableRipple={true}
            />
            <StyledTab
              onClick={this.closeIOS}
              className={classes.tab}
              icon={
                <StyledBadge
                  badgeContent={newNotificationsAmount}
                  className={classes.icon}>
                  <Notifications />
                </StyledBadge>
              }
              component={RouterLink}
              to={!authUser ? SIGN_IN : NOTIFICATIONS}
              value={!authUser ? SIGN_IN : NOTIFICATIONS}
              label={'Notifications'}
            />
            <StyledTab
              id="burgerMenu"
              className={classes.tab}
              disableRipple={true}
              onClick={this.toggleDrawer}
              icon={
                <StyledBadge className={classes.icon}>
                  <Menu htmlColor={(openDrawer && '#64A6B8').toString()} />
                </StyledBadge>
              }
              label={'More'}
            />
          </StyledTabs>
        </AppBar>
      </>
    );
  }
}

BottomBar.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  newNotificationsAmount: newNotificationsAmount(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(withStyles(styles)(BottomBar));
