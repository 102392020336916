/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Card, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 2, 0)
  },
  mainContainer: {
    marginBottom: theme.spacing(1)
  },
  userCard: {
    padding: theme.spacing(2)
  },
  userCardHeader: {
    alignItems: 'center'
  },
  title: {
    fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
    fontSize: 18,
    paddingBottom: theme.spacing(2)
  },
  content: {
    fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
    fontSize: 14,
    paddingBottom: theme.spacing(1),
    color: '#666666',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.22px'
  },
  contentSplit: {
    fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
    fontSize: 14,
    color: '#666666',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.22px'
  }
});

const INITIAL_STATE = {};

const ProfilePage = () => (
  <div>
    <Grid>
      <ProfileDetails />
    </Grid>
  </div>
);

class ProfileDetailsBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container direction="column" className={classes.root}>
          <Grid container item>
            <Grid item xs={12}>
              <Card className={classes.userCard}>
                <p className={classes.title} variant="body1">
                  Terms of Use and Privacy Policy
                </p>

                <p className={classes.content} variant="body1">
                  Terms and conditions of use
                </p>
                <p className={classes.content} variant="body2">
                  Last updated: September 22, 2020 Please read these terms and
                  conditions carefully before using Our Service.
                </p>

                <p className={classes.content} variant="body2">
                  Interpretation and Definitions
                </p>
                <p className={classes.content} variant="body2">
                  Interpretation
                </p>
                <p className={classes.content} variant="body2">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <p className={classes.content} variant="body2">
                  Definitions
                </p>
                <p className={classes.content} variant="body2">
                  For the purposes of these Terms and Conditions:
                </p>
                <p className={classes.content} variant="body2">
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device, named Zonotho
                </p>
                <p className={classes.content} variant="body2">
                  Application Store means the digital distribution service
                  operated and developed by Apple Inc. (Apple App Store) or
                  Google Inc. (Google Play Store) or any other platform from
                  which the Application has been downloaded.
                </p>
                <p className={classes.content} variant="body2">
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where
                  &quot;control&quot; means ownership of 50% or more of the
                  shares, equity interest or other securities entitled to vote
                  for election of directors or other managing authority.
                </p>
                <p className={classes.content} variant="body2">
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </p>
                <p className={classes.content} variant="body2">
                  Country refers to: South Africa
                </p>
                <p className={classes.content} variant="body2">
                  Company (referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to Zonotho (PTY) Ltd, with registered
                  address 270 Von willich ave, Centurion.
                </p>
                <p className={classes.content} variant="body2">
                  Content refers to content such as text, images, or other
                  information that can be posted, uploaded, linked to or
                  otherwise made available by You, regardless of the form of
                  that content.
                </p>
                <p className={classes.content} variant="body2">
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </p>
                <p className={classes.content} variant="body2">
                  Feedback means feedback, innovations or suggestions sent by
                  You regarding the attributes, performance or features of our
                  Service.
                </p>
                <p className={classes.content} variant="body2">
                  Free Trial refers to a limited period of time that may be free
                  when purchasing a Subscription.
                </p>
                <p className={classes.content} variant="body2">
                  Service refers to the Application or the Website or both.
                </p>
                <p className={classes.content} variant="body2">
                  Subscriptions refer to the services or access to the Service
                  offered on a subscription basis by the Company to You.
                </p>
                <p className={classes.content} variant="body2">
                  Terms and Conditions (also referred as &quot;Terms&quot;) mean
                  Terms and Conditions that form the entire agreement between
                  You and the Company regarding the use of the Service.
                </p>
                <p className={classes.content} variant="body2">
                  Third-party Social Media Service means any services or content
                  (including data, information, products or services) provided
                  by a third-party that may be displayed, included or made
                  available by the Service.
                </p>
                <p className={classes.content} variant="body2">
                  Website refers to Zonotho, accessible from
                  https://zonotho.com/ or https://beta.zonotho.com/
                </p>
                <p className={classes.content} variant="body2">
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Acknowledgment
                </p>
                <p className={classes.contentSplit} variant="body2">
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service. By accessing or using
                  the Service You agree to be bound by these Terms and
                  Conditions. If You disagree with any part of these Terms and
                  Conditions then You may not access the Service. You represent
                  that you are over the age of 18. The Company does not permit
                  those under 18 to use the Service.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Subscriptions
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Subscription period
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Service or some parts of the Service are available only
                  with a paid Subscription. You will be billed in advance on a
                  recurring and periodic basis (such as daily, weekly, monthly
                  or annually), depending on the type of Subscription plan you
                  select when purchasing the Subscription. At the end of each
                  period, Your Subscription will automatically renew under the
                  exact same conditions unless You cancel it or the Company
                  cancels it. Subscription cancellations
                </p>
                <p className={classes.contentSplit} variant="body2">
                  You may cancel Your Subscription renewal either through Your
                  Account settings page or by contacting the Company. You will
                  not receive a refund for the fees You already paid for Your
                  current Subscription period and You will be able to access the
                  Service until the end of Your current Subscription period.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Billing
                </p>

                <p className={classes.contentSplit} variant="body2">
                  You shall provide the Company with accurate and complete
                  billing information including full name, address, state, zip
                  code, telephone number, and a valid payment method
                  information. Should automatic billing fail to occur for any
                  reason, the Company will issue an electronic invoice
                  indicating that you must proceed manually, within a certain
                  deadline date, with the full payment corresponding to the
                  billing period as indicated on the invoice.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Fee Changes
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Company, in its sole discretion and at any time, may
                  modify the Subscription fees. Any Subscription fee change will
                  become effective at the end of the then-current Subscription
                  period. The Company will provide You with reasonable prior
                  notice of any change in Subscription fees to give You an
                  opportunity to terminate Your Subscription before such change
                  becomes effective. Your continued use of the Service after the
                  Subscription fee change comes into effect constitutes Your
                  agreement to pay the modified Subscription fee amount.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Refunds
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Except when required by law, paid Subscription fees are
                  non-refundable. Certain refund requests for Subscriptions may
                  be considered by the Company on a case-by-case basis and
                  granted at the sole discretion of the Company.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Free Trial
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Company may, at its sole discretion, offer a Subscription
                  with a Free trial for a limited period of time. You may be
                  required to enter Your billing information in order to sign up
                  for the Free trial. If You do enter Your billing information
                  when signing up for a Free Trial, You will not be charged by
                  the Company until the Free trial has expired. On the last day
                  of the Free Trial period, unless You cancelled Your
                  Subscription, You will be automatically charged the applicable
                  Subscription fees for the type of Subscription You have
                  selected. At any time and without notice, the Company reserves
                  the right to (i) modify the terms and conditions of the Free
                  Trial offer, or (ii) cancel such Free trial offer.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  User Accounts
                </p>

                <p className={classes.contentSplit} variant="body2">
                  When You create an account with Us, You must provide Us
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the Terms,
                  which may result in immediate termination of Your account on
                  Our Service. You are responsible for safeguarding the password
                  that You use to access the Service and for any activities or
                  actions under Your password, whether Your password is with Our
                  Service or a Third-Party Social Media Service. You agree not
                  to disclose Your password to any third party. You must notify
                  Us immediately upon becoming aware of any breach of security
                  or unauthorized use of Your account. You may not use as a
                  username the name of another person or entity or that is not
                  lawfully available for use, a name or trademark that is
                  subject to any rights of another person or entity other than
                  You without appropriate authorization, or a name that is
                  otherwise offensive, vulgar or obscene.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Content
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Your Right to Post Content
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Our Service allows You to post Content. You are responsible
                  for the Content that You post to the Service, including its
                  legality, reliability, and appropriateness. By posting Content
                  to the Service, You grant Us the right and license to use,
                  modify, publicly perform, publicly display, reproduce, and
                  distribute such Content on and through the Service. You retain
                  any and all of Your rights to any Content You submit, post or
                  display on or through the Service and You are responsible for
                  protecting those rights. You agree that this license includes
                  the right for Us to make Your Content available to other users
                  of the Service, who may also use Your Content subject to these
                  Terms. You represent and warrant that: (i) the Content is
                  Yours (You own it) or You have the right to use it and grant
                  Us the rights and license as provided in these Terms, and (ii)
                  the posting of Your Content on or through the Service does not
                  violate the privacy rights, publicity rights, copyrights,
                  contract rights or any other rights of any person.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Content Restrictions
                </p>
                <p className={classes.contentSplit} variant="body2">
                  The Company is not responsible for the content of the
                  Service&apos;s users. You expressly understand and agree that
                  You are solely responsible for the Content and for all
                  activity that occurs under your account, whether done so by
                  You or any third person using Your account. You may not
                  transmit any Content that is unlawful, offensive, upsetting,
                  intended to disgust, threatening, libelous, defamatory,
                  obscene or otherwise objectionable. Examples of such
                  objectionable Content include, but are not limited to, the
                  following: Providing legal or financial advice according to
                  the FAIS Act and any other applicable law if not qualified to
                  do so and without following all the required steps for giving
                  advice in accordance with applicable laws.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Unlawful or promoting unlawful activity.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Defamatory, discriminatory, or mean-spirited content,
                  including references or commentary about religion, race,
                  sexual orientation, gender, national/ethnic origin, or other
                  targeted groups. Spam, machine – or randomly – generated,
                  constituting unauthorized or unsolicited advertising, chain
                  letters, any other form of unauthorized solicitation, or any
                  form of lottery, pyramid scheme, chain letter scheme,
                  multi-level marketing or gambling. Containing or installing
                  any viruses, worms, malware, trojan horses, or other content
                  that is designed or intended to disrupt, damage, or limit the
                  functioning of any software, hardware or telecommunications
                  equipment or to damage or obtain unauthorized access to any
                  data or other information of a third person. Infringing on any
                  proprietary rights of any party, including patent, trademark,
                  trade secret, copyright, right of publicity or other rights.
                  Impersonating any person or entity including the Company and
                  its employees or representatives.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Violating the privacy of any third person.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  False information and features.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Company reserves the right, but not the obligation, to, in
                  its sole discretion, determine whether or not any Content is
                  appropriate and complies with these Terms, refuse or remove
                  this Content. The Company further reserves the right to make
                  formatting and edits and change the manner of any Content. The
                  Company can also limit or revoke the use of the Service if You
                  post such objectionable Content. As the Company cannot control
                  all content posted by users and/or third parties on the
                  Service, you agree to use the Service at your own risk. You
                  understand that by using the Service You may be exposed to
                  content that You may find offensive, indecent, incorrect or
                  objectionable, and You agree that under no circumstances will
                  the Company be liable in any way for any content, including
                  any errors or omissions in any content, or any loss or damage
                  of any kind incurred as a result of your use of any content.
                  This includes but is not limited to any ramifications arising
                  as a result of users or non-users enacting any legal or
                  financial advice, recommendations or examples shared by any
                  party on the Service.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Content Backups
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Although regular backups of Content are performed, the Company
                  does not guarantee there will be no loss or corruption of
                  data. Corrupt or invalid backup points may be caused by,
                  without limitation, Content that is corrupted prior to being
                  backed up or that changes during the time a backup is
                  performed. The Company will provide support and attempt to
                  troubleshoot any known or discovered issues that may affect
                  the backups of Content. But You acknowledge that the Company
                  has no liability related to the integrity of Content or the
                  failure to successfully restore Content to a usable state. You
                  agree to maintain a complete and accurate copy of any Content
                  in a location independent of the Service.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Copyright Policy
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Intellectual Property Infringement
                </p>
                <p className={classes.contentSplit} variant="body2">
                  We respect the intellectual property rights of others. It is
                  Our policy to respond to any claim that Content posted on the
                  Service infringes a copyright or other intellectual property
                  infringement of any person. If You are a copyright owner, or
                  authorized on behalf of one, and You believe that the
                  copyrighted work has been copied in a way that constitutes
                  copyright infringement that is taking place through the
                  Service, You must submit Your notice in writing to the
                  attention of our copyright agent via email at
                  feedback@zonotho.com and include in Your notice a detailed
                  description of the alleged infringement. You may be held
                  accountable for damages (including costs and attorneys&apos;
                  fees) for misrepresenting that any Content is infringing Your
                  copyright.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  DMCA Notice and DMCA Procedure for Copyright Infringement
                  Claims
                </p>
                <p className={classes.contentSplit} variant="body2">
                  You may submit a notification pursuant to the Digital
                  Millennium Copyright Act (DMCA) by providing our Copyright
                  Agent with the following information in writing (see 17 U.S.C
                  512(c)(3) for further detail): An electronic or physical
                  signature of the person authorized to act on behalf of the
                  owner of the copyright&apos;s interest. A description of the
                  copyrighted work that You claim has been infringed, including
                  the URL (i.e., web page address) of the location where the
                  copyrighted work exists or a copy of the copyrighted work.
                  Identification of the URL or other specific location on the
                  Service where the material that You claim is infringing is
                  located.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Your address, telephone number, and email address.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  A statement by You that You have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, or the law. A statement by You, made under penalty of
                  perjury, that the above information in Your notice is accurate
                  and that You are the copyright owner or authorized to act on
                  the copyright owner&apos;s behalf. You can contact our
                  copyright agent via email at feedback@zonotho.com. Upon
                  receipt of a notification, the Company will take whatever
                  action, in its sole discretion, it deems appropriate,
                  including removal of the challenged content from the Service.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Intellectual Property
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Service and its original content (excluding Content
                  provided by You or other users), features and functionality
                  are and will remain the exclusive property of the Company and
                  its licensors. The Service is protected by copyright,
                  trademark, and other laws of both the Country and foreign
                  countries. Our trademarks and trade dress may not be used in
                  connection with any product or service without the prior
                  written consent of the Company.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Your Feedback to Us
                </p>

                <p className={classes.contentSplit} variant="body2">
                  You assign all rights, title and interest in any Feedback You
                  provide the Company through any means or channel including but
                  not limited to e-mail, social media, dedicated feedback
                  mechanisms or any other channel. If for any reason such
                  assignment is ineffective, You agree to grant the Company a
                  non-exclusive, perpetual, irrevocable, royalty free, worldwide
                  right and license to use, reproduce, disclose, sub-license,
                  distribute, modify and exploit such Feedback without
                  restriction.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Links to Other Websites
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by the Company. The
                  Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any third
                  party web sites or services. You further acknowledge and agree
                  that the Company shall not be responsible or liable, directly
                  or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  such content, goods or services available on or through any
                  such web sites or services. We strongly advise You to read the
                  terms and conditions and privacy policies of any third-party
                  web sites or services that You visit.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Termination
                </p>
                <p className={classes.contentSplit} variant="body2">
                  We may terminate or suspend Your Account immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if You breach these Terms and
                  Conditions. Upon termination, Your right to use the Service
                  will cease immediately. If You wish to terminate Your Account,
                  You may simply discontinue using the Service.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Limitation of Liability
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Notwithstanding any damages that You might incur, the entire
                  liability of the Company and any of its suppliers under any
                  provision of this Terms and Your exclusive remedy for all of
                  the foregoing shall be limited to the amount actually paid by
                  You through the Service or 1,000ZAR if You haven&apos;t
                  purchased anything through the Service. To the maximum extent
                  permitted by applicable law, in no event shall the Company or
                  its suppliers be liable for any special, incidental, indirect,
                  or consequential damages whatsoever (including, but not
                  limited to, damages for loss of profits, loss of income, loss
                  of capital, loss of data or other information, for business
                  interruption, for personal injury, loss of privacy arising out
                  of or in any way related to the use of or inability to use the
                  Service, third-party software and/or third-party hardware used
                  with the Service, or otherwise in connection with any
                  provision of this Terms), even if the Company or any supplier
                  has been advised of the possibility of such damages and even
                  if the remedy fails of its essential purpose. Some states,
                  countries or jurisdictions do not allow the exclusion of
                  implied warranties or limitation of liability for incidental
                  or consequential damages, which means that some of the above
                  limitations may not apply. In these states, each party&apos;s
                  liability will be limited to the greatest extent permitted by
                  law.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The Service is provided to You &quot;AS IS&quot; and &quot;AS
                  AVAILABLE&quot; and with all faults and defects without
                  warranty of any kind. To the maximum extent permitted under
                  applicable law, the Company, on its own behalf and on behalf
                  of its Affiliates and its and their respective licensors and
                  service providers, expressly disclaims all warranties, whether
                  express, implied, statutory or otherwise, with respect to the
                  Service, including all implied warranties of merchantability,
                  fitness for a particular purpose, title and non-infringement,
                  and warranties that may arise out of course of dealing, course
                  of performance, usage or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet Your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected. Without limiting the foregoing, neither the Company
                  nor any of the company&apos;s provider makes any
                  representation or warranty of any kind, express or implied:
                  (i) as to the operation or availability of the Service, or the
                  information, content, and materials or products included
                  thereon; (ii) that the Service will be uninterrupted or
                  error-free; (iii) as to the accuracy, reliability, or currency
                  of any information or content provided through the Service; or
                  (iv) that the Service, its servers, the content, or e-mails
                  sent from or on behalf of the Company are free of viruses,
                  scripts, trojan horses, worms, malware, timebombs or other
                  harmful components. Some jurisdictions do not allow the
                  exclusion of certain types of warranties or limitations on
                  applicable statutory rights of a consumer, so some or all of
                  the above exclusions and limitations may not apply to You. But
                  in such a case the exclusions and limitations set forth in
                  this section shall be applied to the greatest extent
                  enforceable under applicable law.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Governing Law
                </p>

                <p className={classes.contentSplit} variant="body2">
                  The laws of the Country, excluding its conflicts of law rules,
                  shall govern this Terms and Your use of the Service. Your use
                  of the Application may also be subject to other local, state,
                  national, or international laws.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Disputes Resolution
                </p>

                <p className={classes.contentSplit} variant="body2">
                  If You have any concern or dispute about the Service, You
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  United States Federal Government End Use Provisions
                </p>

                <p className={classes.contentSplit} variant="body2">
                  If You are a U.S. federal government end user, our Service is
                  a &quot;Commercial Item&quot; as that term is defined at 48
                  C.F.R. 2.101.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  United States Legal Compliance
                </p>
                <p className={classes.contentSplit} variant="body2">
                  You represent and warrant that (i) You are not located in a
                  country that is subject to the United States government
                  embargo, or that has been designated by the United States
                  government as a &quot;terrorist supporting&quot; country, and
                  (ii) You are not listed on any United States government list
                  of prohibited or restricted parties.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Severability and Waiver
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Severability
                </p>

                <p className={classes.contentSplit} variant="body2">
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be changed and interpreted to
                  accomplish the objectives of such provision to the greatest
                  extent possible under applicable law and the remaining
                  provisions will continue in full force and effect.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Waiver
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Except as provided herein, the failure to exercise a right or
                  to require performance of an obligation under these Terms
                  shall not effect a party&apos;s ability to exercise such right
                  or require such performance at any time thereafter nor shall
                  be the waiver of a breach constitute a waiver of any
                  subsequent breach.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Translation Interpretation
                </p>

                <p className={classes.contentSplit} variant="body2">
                  These Terms and Conditions may have been translated if We have
                  made them available to You on our Service. You agree that the
                  original English text shall prevail in the case of a dispute.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Changes to These Terms and Conditions
                </p>

                <p className={classes.contentSplit} variant="body2">
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material We
                  will make reasonable efforts to provide at least 30 days&apos;
                  notice prior to any new terms taking effect. What constitutes
                  a material change will be determined at Our sole discretion.
                  By continuing to access or use Our Service after those
                  revisions become effective, You agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or in part, please stop using the website and the Service.
                </p>

                <p className={classes.content} variant="body1">
                  Privacy Policy
                </p>

                <p className={classes.content} variant="body2">
                  This section informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data. Our Privacy Policy for Zonotho is created with the help
                  of the Free Privacy Policy website.
                </p>
                <p className={classes.content} variant="body2">
                  {' '}
                  We use your data to provide and improve the Service. By using
                  the Service, you agree to the collection and use of
                  information in accordance with this policy. Unless otherwise
                  defined in this Privacy Policy, terms used in this Privacy
                  Policy have the same meanings as in our Terms and Conditions,
                  accessible from{' '}
                  <a href="https://zonotho.com">https://zonotho.com</a>.
                </p>
                <p className={classes.content} variant="body2">
                  Information Collection And Use We collect several different
                  types of information for various purposes to provide and
                  improve our Service to you.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Types of Data Collected
                </p>
                <p className={classes.content} variant="body2">
                  Personal Data
                </p>
                <p className={classes.contentSplit} variant="body2">
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“Personal Data”). Personally
                  identifiable information may include, but is not limited to:
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Email address
                </p>
                <p className={classes.contentSplit} variant="body2">
                  First name and last name
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Cookies and Usage Data
                </p>
                <p className={classes.content} variant="body2">
                  Usage Data
                </p>
                <p className={classes.content} variant="body2">
                  We may also collect information how the Service is accessed
                  and used (“Usage Data”). This Usage Data may include
                  information such as your computer’s Internet Protocol address
                  (e.g. IP address), browser type, browser version, the pages of
                  our Service that you visit, the time and date of your visit,
                  the time spent on those pages, unique device identifiers and
                  other diagnostic data.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Tracking & Cookies Data
                </p>
                <p className={classes.content} variant="body2">
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and hold certain information.
                </p>
                <p className={classes.content} variant="body2">
                  Cookies are files with small amount of data which may include
                  an anonymous unique identifier. Cookies are sent to your
                  browser from a website and stored on your device. Tracking
                  technologies also used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze our
                  Service.
                </p>
                <p className={classes.content} variant="body2">
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Examples of Cookies we use:
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Session Cookies. We use Session Cookies to operate our
                  Service.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Preference Cookies. We use Preference Cookies to remember your
                  preferences and various settings.
                </p>
                <p className={classes.content} variant="body2">
                  Security Cookies. We use Security Cookies for security
                  purposes.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Use of Data
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Zonotho uses the collected data for various purposes:
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To provide and maintain the Service
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To notify you about changes to our Service
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To provide customer care and support
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To provide analysis or valuable information so that we can
                  improve the Service
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To monitor the usage of the Service
                </p>
                <p className={classes.content} variant="body2">
                  To detect, prevent and address technical issues
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Transfer Of Data
                </p>
                <p className={classes.content} variant="body2">
                  Your information, including Personal Data, may be transferred
                  to — and maintained on — computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction.
                </p>
                <p className={classes.content} variant="body2">
                  If you are located outside South Africa and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to South Africa and process it there.
                </p>
                <p className={classes.content} variant="body2">
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p className={classes.content} variant="body2">
                  Zonotho will take all steps reasonably necessary to ensure
                  that your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of your data
                  and other personal information.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Disclosure Of Data
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Legal Requirements
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Zonotho may disclose your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To comply with a legal obligation
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To protect and defend the rights or property of Zonotho
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                </p>
                <p className={classes.contentSplit} variant="body2">
                  To protect the personal safety of users of the Service or the
                  public
                </p>
                <p className={classes.content} variant="body2">
                  To protect against legal liability
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Security Of Data
                </p>
                <p className={classes.content} variant="body2">
                  The security of your data is important to us, but remember
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Service Providers
                </p>
                <p className={classes.content} variant="body2">
                  We may employ third party companies and individuals to
                  facilitate our Service (“Service Providers”), to provide the
                  Service on our behalf, to perform Service-related services or
                  to assist us in analyzing how our Service is used.
                </p>
                <p className={classes.content} variant="body2">
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Links To Other Sites
                </p>
                <p className={classes.content} variant="body2">
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party’s site. We strongly advise you
                  to review the Privacy Policy of every site you visit.
                </p>
                <p className={classes.content} variant="body2">
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Children’s Privacy
                </p>
                <p className={classes.content} variant="body2">
                  Our Service does not address anyone under the age of 18
                  (“Children”).
                </p>
                <p className={classes.content} variant="body2">
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 18. If you are a
                  parent or guardian and you are aware that your Children has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>
                <p className={classes.contentSplit} variant="body2">
                  Changes To This Privacy Policy
                </p>
                <p className={classes.content} variant="body2">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p className={classes.content} variant="body2">
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the “effective date” at the top of this Privacy Policy.
                </p>
                <p className={classes.content} variant="body2">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>

                <p className={classes.contentSplit} variant="body2">
                  Contact Us
                </p>
                <p className={classes.contentSplit} variant="body2">
                  If you have any questions about these Terms and Conditions
                  and/or this Privacy Policy, please contact us: By email:{' '}
                  <a
                    target="_blank"
                    href="mailto:feedback@zonotho.com"
                    rel="noopener noreferrer">
                    feedback@zonotho.com
                  </a>
                </p>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const ProfileDetails = compose(withRouter)(
  withStyles(styles)(ProfileDetailsBase)
);

ProfileDetailsBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default ProfilePage;

export { ProfileDetails };
