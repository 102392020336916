import React, { Component } from 'react';
import { getBase64Strings } from 'exif-rotate-js/lib';

import { Box, Grid, Button, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {
  GreetingStep,
  ProfileImageStep,
  BenefitsStep,
  InterestsStep,
  LevelStep,
  FinishStep
} from './components';

import { ARTICLES } from '../../constants/routes';
const MIN_INTERESTS_AMOUNT = 0;

class OnboardingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      loading: true,
      progress: 1,
      error: null,
      isOpenAlert: false,
      isInitialed: false
    };
  }

  componentDidMount() {
    const { authUser } = this.props;

    if (authUser) {
      this.loadingInitialData(authUser);
    }
  }

  componentDidUpdate() {
    const { isInitialed } = this.state;
    const { authUser } = this.props;

    if (!isInitialed && authUser) {
      this.loadingInitialData(authUser);
    }
  }

  loadingInitialData = async user => {
    let tags = null;

    try {
      tags = await this.getTags();
    } catch (err) {
      console.error(err);
    }

    this.setState({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      avatarUrl: user.avatar || '',
      selectedInterests: [],
      skillLevel: 1,
      tags,
      loading: false,
      isInitialed: true
    });
  };

  async getTags() {
    const args = {
      filters: [
        {
          field: 'showInterest',
          condition: '==',
          value: true
        }
      ]
    };

    const tags = await this.props.firebase.getTagsWithArgs(args);

    if (tags.length < 1) {
      return;
    }

    return tags;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeSlider = (event, value) => {
    this.setState({
      skillLevel: value
    });
  };

  handleAvatarUpload = e => {
    const { files } = e.target;
    if (!files || files.length < 1) {
      return;
    }

    const image = files[0];
    if (
      !image.type.includes('jpeg') &&
      !image.type.includes('jpg') &&
      !image.type.includes('png') &&
      !image.type.includes('gif')
    ) {
      const error = { message: 'Only images are allowed' };
      this.setState({ error, isOpenAlert: true });
      return;
    }

    this.setState(() => ({ loading: true }));
    const { authUser, firebase } = this.props;
    const imageName = `${authUser.uid}`;

    getBase64Strings(files, { maxSize: 720 })
      .then(result => {
        const uploadTask = firebase.storage
          .ref(`avatars/${imageName}`)
          .putString(result[0], 'data_url', { contentType: image.type });

        uploadTask.on(
          'state_changed',
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            firebase.storage
              .ref('avatars')
              .child(imageName)
              .getDownloadURL()
              .then(url => {
                this.setState({
                  avatarUrl: url,
                  loading: false,
                  progress: 1
                });
              });
          }
        );
      })
      .catch(error => {
        this.setState({ error, isOpenAlert: true });
      });
  };

  prepareUpdatedUser = () => {
    const { authUser } = this.props;
    const {
      firstName,
      lastName,
      avatarUrl,
      selectedInterests,
      skillLevel
    } = this.state;

    return {
      ...authUser,
      firstName,
      lastName,
      avatar: avatarUrl,
      interests: [...selectedInterests],
      skillLevel,
      onboardingCompleted: true
    };
  };

  sendBasicUserInfo = () => {
    const { firebase, history, onSetAuthUser } = this.props;
    const updatedUser = this.prepareUpdatedUser();
    const {
      uid,
      firstName,
      lastName,
      avatar,
      interests,
      skillLevel,
      onboardingCompleted
    } = updatedUser;

    const body = {
      data: {
        firstName,
        lastName,
        avatar,
        interests,
        skillLevel,
        onboardingCompleted
      },
      merge: true
    };

    firebase
      .editUser(uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
        history.push(ARTICLES);
      })
      .catch(err => {
        console.error(err);
        history.push(ARTICLES);
      });
  };

  onNextClick = stepsAmount => {
    const { activeStep } = this.state;
    const nextStep = activeStep + 1;
    const isFinishStep = stepsAmount === nextStep + 1;
    if (isFinishStep) {
      setTimeout(() => this.sendBasicUserInfo(), 3000);
    }

    this.setState({ activeStep: nextStep });
  };

  onPrevClick = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  onCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      error: null,
      isOpenAlert: false
    });
  };

  onTagClick = tag => {
    const { selectedInterests } = this.state;
    let newSelected;

    const isSelected = selectedInterests.find(s => s.uid === tag.uid);

    if (isSelected) {
      newSelected = selectedInterests.filter(s => s.uid !== tag.uid);
    } else {
      newSelected = [...selectedInterests, tag];
    }

    this.setState({
      selectedInterests: newSelected
    });
  };

  render() {
    const { classes } = this.props;
    const {
      activeStep,
      firstName,
      lastName,
      avatarUrl,
      isOpenAlert,
      error,
      loading,
      progress,
      tags,
      selectedInterests,
      skillLevel
    } = this.state;

    const steps = [
      {
        component: (
          <GreetingStep
            key="greetingStep"
            firstName={firstName}
            lastName={lastName}
            isLoading={loading}
            onChange={this.handleChange}
          />
        ),
        isNextAllowed: firstName && lastName
      },
      {
        component: (
          <ProfileImageStep
            key="profileImageStep"
            avatar={avatarUrl}
            progress={progress}
            isLoading={loading}
            onChange={this.handleAvatarUpload}
          />
        ),
        isNextAllowed: true
      },
      {
        component: <BenefitsStep key="benefitsStep" name={firstName} />,
        isNextAllowed: true
      },
      {
        component: (
          <InterestsStep
            key="interestsStep"
            tags={tags}
            selectedInterests={selectedInterests}
            onClick={this.onTagClick}
          />
        ),
        isNextAllowed:
          selectedInterests && selectedInterests.length >= MIN_INTERESTS_AMOUNT
      },
      {
        component: (
          <LevelStep
            key="levelStep"
            value={skillLevel}
            onChange={this.handleChangeSlider}
          />
        ),
        isNextAllowed: skillLevel
      },
      {
        component: <FinishStep key="finishStep" />
      }
    ];

    return (
      <div className={classes.root}>
        <Grid className={classes.header} container>
          <Grid className={classes.logo} item>
            <img
              alt="Logo"
              src="/images/logos/zonotho-logo-green-320.png"
              sizes="320px"
              srcSet="/images/logos/zonotho-logo-green-320.png 320w,
              /images/logos/zonotho-logo-green-640.png 640w"
            />
          </Grid>
        </Grid>
        <Box className={classes.main}>
          <div className={classes.mainContainer}>
            {steps && steps[activeStep].component}
          </div>
        </Box>
        <Grid className={classes.footer} container>
          {activeStep !== 0 && (
            <Grid className={classes.prevBtnContainer} item>
              <Button
                className={classes.stepBtn}
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={this.onPrevClick}>
                Prev
              </Button>
            </Grid>
          )}
          {activeStep !== steps.length - 1 && (
            <Grid className={classes.nextBtnContainer} item>
              <Button
                className={classes.stepBtn}
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => this.onNextClick(steps.length)}
                disabled={!steps[activeStep].isNextAllowed}>
                Next
              </Button>
            </Grid>
          )}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isOpenAlert}
          onClose={this.onCloseAlert}
          autoHideDuration={5000}>
          <Alert variant="filled" severity="error" onClose={this.onCloseAlert}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default OnboardingBase;
