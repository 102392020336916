import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { authUser } = props;

  const classes = useStyles();

  const user = {
    name: '',
    avatar: authUser.avatar ? authUser.avatar : '/images/default-avatar.png',
    role: `${authUser.role}`
  };

  if (
    authUser.firstName &&
    authUser.firstName !== undefined &&
    authUser.lastName &&
    authUser.lastName !== undefined
  ) {
    user.name = `${authUser.firstName} ${authUser.lastName}`;
  } else {
    user.name = `${authUser.username}`;
  }

  return (
    <div className={classes.root}>
      {/* <Avatar
        alt="Avatar"
        className={classes.avatar}
        src={user.avatar}
      /> */}
      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.role}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default compose(connect(mapStateToProps))(Profile);
