import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, authUser } = props;

  return (
    <Route
      render={matchProps => (
        <Layout>
          {authUser && authUser.role === 'ADMIN' ? (
            <Component {...matchProps} />
          ) : (
            <Redirect to="/articles" />
          )}
        </Layout>
      )}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default compose(connect(mapStateToProps))(PrivateRouteWithLayout);
