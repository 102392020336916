export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%',
      padding: theme.spacing(3, 0)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%',
      padding: theme.spacing(3, 0)
    }
  },
  progress: {
    display: 'flex',
    justifyContent: 'center'
  },
  headerGrid: {
    marginBottom: theme.spacing(2.5)
  },
  usersGrid: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  },
  header: {
    padding: theme.spacing(0, 2)
  },
  pageTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,
    letterSpacing: 0
  },
  pageSubtitle: {
    fontSize: 14,
    lineHeight: 1.4286
  }
});

export default styles;
