import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import { authUserSelector } from '../Session/selectors';
import {
  dataSelector,
  selectedTagsSelector,
  exploreResultFilterSelector
} from '../Search/selectors';

import ExploreBase from './Explore';
import { styles } from './Explore.style';

const mapStateToProps = state => ({
  authUser: authUserSelector(state),
  searchData: dataSelector(state),
  selectedTags: selectedTagsSelector(state),
  filter: exploreResultFilterSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  setDataArticles: articles =>
    dispatch({ type: 'ARTICLES_DATA_SET', articles }),
  setFilter: filter =>
    dispatch({ type: 'EXPLORE_SELECTED_FILTER_SET', filter }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

const ExplorePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(withStyles(styles)(ExploreBase));

export default ExplorePage;
