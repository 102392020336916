export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2, 0),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%',
      padding: theme.spacing(3, 0, 0)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%',
      padding: theme.spacing(3, 0, 0)
    }
  },
  progress: {
    position: 'absolute',
    width: '100%'
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pageTitle: {
    fontWeight: 400,
    letterSpacing: 0
  },
  clearAll: {
    padding: '0 6px 8px 0'
  },
  noContentTitle: {
    marginTop: theme.spacing(2)
  },
  notificationList: {
    listStyle: 'none'
  },
  notificationItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5)
    }
  }
});

export default styles;
