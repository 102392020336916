import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, CircularProgress, Box } from '@material-ui/core';

import { styles } from './Finish.style';

const Finish = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Awesome, you’re&nbsp;all&nbsp;set
      </Typography>
      <Typography className={classes.message}>
        One second while we prepare the feed…
      </Typography>
      <Box className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} />
      </Box>
    </div>
  );
};

export default withStyles(styles)(Finish);
