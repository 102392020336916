import React, { useEffect, useState } from 'react';
import { LinearProgress, Grid, Typography, Button } from '@material-ui/core';

import { Notification } from './notification';
import { ARTICLES } from '../../constants/routes';

const NotificationBase = ({
  classes,
  authUser,
  notifications,
  firebase,
  onSetAuthUser,
  history
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const readNotification = uid => {
    const isNewNotification = notifications.find(n => n.uid === uid);

    if (isNewNotification) {
      const updatedNotifocations = {
        ...authUser.notifications
      };
      updatedNotifocations[uid].hasRead = true;

      const body = {
        data: {
          notifications: updatedNotifocations
        },
        merge: true
      };

      firebase
        .editUser(authUser.uid, body)
        .then(() => {
          onSetAuthUser({
            ...authUser,
            notifications: updatedNotifocations
          });
          return;
        })
        .catch(err => {
          console.error('error: ', err);
        });
    }
  };

  const readNotifications = uids => {
    const updatedNotifocations = {
      ...authUser.notifications
    };

    for (const uid of uids) {
      updatedNotifocations[uid].hasRead = true;
    }

    const body = {
      data: {
        notifications: updatedNotifocations
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser({
          ...authUser,
          notifications: updatedNotifocations
        });
        return;
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  const navigateToPost = (postId, uid) => {
    const uids = [];
    for (const [key, value] of Object.entries(authUser.notifications)) {
      if (value.post && value.post.uid === postId) {
        uids.push(key);
      }
    }

    if (uids && uids.length > 0) {
      if (uids.indexOf(uid) === -1) {
        uids.push(uid);
      }

      readNotifications(uids);
    } else {
      readNotification(uid);
    }

    history.push({
      pathname: `${ARTICLES}/${postId}`,
      state: { fromNotifications: true }
    });
  };

  const handleDeleteAllNotifications = () => {
    setLoading(true);

    const body = {
      data: {
        notifications: []
      }
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser({
          ...authUser,
          notifications: []
        });

        setLoading(false);
        return;
      })
      .catch(err => {
        console.error('error: ', err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      )}

      <div className={classes.root}>
        <Grid container direction="column" spacing={1}>
          <Grid item className={classes.wrap}>
            <Typography className={classes.pageTitle} variant="h1"></Typography>
            <Button
              className={classes.clearAll}
              onClick={handleDeleteAllNotifications}
              type="button"
              disabled={notifications.length === 0}>
              CLEAR ALL
            </Button>
          </Grid>
          {notifications.length === 0 && (
            <Grid item>
              <Typography
                className={classes.noContentTitle}
                align="center"
                variant="body1">
                There are no new notifications
              </Typography>
            </Grid>
          )}
          {notifications.length > 0 && (
            <Grid
              className={classes.notificationList}
              item
              container
              component="ul"
              direction="column">
              {notifications.map(
                ({ uid, type, user, post, created, hasRead, anonimus }) => {
                  return (
                    <Grid
                      className={classes.notificationItem}
                      key={uid}
                      item
                      component="li">
                      <Notification
                        uid={uid}
                        type={type}
                        user={user}
                        post={post}
                        date={created}
                        hasRead={hasRead}
                        anonimus={anonimus}
                        readNotification={readNotification}
                        navigateToPost={navigateToPost}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default NotificationBase;
