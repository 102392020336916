import React, { Component } from 'react';
import {
  Grid,
  Typography,
  Paper,
  InputBase,
  Button,
  IconButton,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { changeUserCoins } from '../../common/Helpers';

import { ARTICLES, AUTHOR } from '../../constants/routes';
import { Result, ExploreCard } from './components';
const SHOW_MORE_LIMIT = 20;

class SearchBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      showResult: false,
      isLoaded: false
    };
  }

  componentDidMount() {
    const {
      searchData: { articles, users, tags },
      result
    } = this.props;

    const isDataLoaded = !!articles && !!users && !!tags;
    const isResult = Boolean(result.request);

    if (!isDataLoaded) {
      this.loadData();
    }

    this.setState({
      showResult: isResult,
      isLoaded: isDataLoaded
    });
  }

  /* eslint-disable no-unused-vars */
  componentDidUpdate(prevProps, prevState) {
    const { isLoaded: prevIsLoaded } = prevState;
    const { waiting, isLoaded } = this.state;

    if (!prevIsLoaded && isLoaded && waiting) {
      this.makeSearch();
    }
  }

  loadData = () => {
    this.loadTags()
      // .then(() => this.loadUsers())
      // .then(() => this.loadArticles())
      // .then(articles => this.mapUserToArticle(articles))
      .then(() => {
        this.setState({
          isLoaded: true
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  loadTags = () => {
    const { firebase, setDataTags } = this.props;

    const args = {
      filters: [
        {
          field: 'showExplore',
          condition: '==',
          value: true
        }
      ]
    };

    return firebase.getTagsWithArgs(args).then(tags => {
      setDataTags(tags);
      return;
    });
  };

  loadUsers = () => {
    const { firebase, setDataUsers } = this.props;

    return firebase.getUsers().then(users => {
      if (!users || users.length < 1) {
        return;
      }

      setDataUsers(users);
      return;
    });
  };

  loadArticles = () => {
    const { firebase } = this.props;

    const args = {
      filters: [
        {
          field: 'status',
          condition: '==',
          value: 'published'
        }
      ],
      orders: [
        {
          field: 'created',
          value: 'desc'
        }
      ]
    };

    return firebase.getPostsWithArgs(args).then(articles => {
      if (!articles || articles.length < 1) {
        return;
      }

      return articles;
    });
  };

  mapUserToArticle = articles => {
    const {
      searchData: { users },
      setDataArticles
    } = this.props;
    const userMap = {};
    users.forEach(user => {
      userMap[user.uid] = {
        ...user
      };
    });

    const mappedArticles = [];
    articles.forEach(article => {
      mappedArticles.push({
        ...article,
        contributor: {
          ...userMap[article.contributorRef]
        }
      });
    });

    setDataArticles(mappedArticles);
    return;
  };

  searchOnChange = e => {
    const { setValue, resetResult } = this.props;
    const { value } = e.target;

    if (value === '') {
      resetResult();
      this.setState({
        showResult: false
      });
    }

    setValue(value);
  };

  switchList = (e, value) => {
    this.props.setList(value);
  };

  switchFilter = e => {
    this.props.setFilter(e.target.value);
  };

  onShowMoreClick = () => {
    const { limit, setLimit } = this.props;
    setLimit(limit + SHOW_MORE_LIMIT);
  };

  handleSearchSubmit = e => {
    e.preventDefault();
    const { value, result } = this.props;

    if (value === result.request || value === '' || !value) {
      return;
    }

    this.props.resetResult();
    this.setState({
      showResult: true,
      waiting: true
    });

    this.makeSearch();
  };

  makeSearch = async () => {
    const { authUser, value, setResult, firebase } = this.props;
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return;
    }

    const posts = await firebase.searchPosts(value);
    const people = [];

    const users = await firebase.searchUsers(value);
    users.forEach(u => {
      const { uid } = u;

      if (authUser && authUser.uid === uid) {
        return;
      }

      people.push(u);
    });

    setResult({
      request: value,
      posts,
      people
    });
    this.setState({
      waiting: false
    });
  };

  onTopicTagClick = tag => {
    const { selectedTags, setSelectedTags } = this.props;

    const isSelected = selectedTags.find(t => t.uid === tag.uid);

    if (isSelected) {
      setSelectedTags(selectedTags.filter(t => t.uid !== tag.uid));
      return;
    }

    const newSelectedTags = [...selectedTags];
    newSelectedTags.push(tag);
    setSelectedTags(newSelectedTags);
  };

  onLikeClick = articleId => {
    const {
      authUser,
      searchData: { articles }
    } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const {
      firebase,
      searchData: { articles },
      setDataArticles,
      authUser
    } = this.props;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(articleId, body, authUser.uid)
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        setDataArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  onFollowClick = (e, userId) => {
    e.preventDefault();

    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog({ pathname: `${AUTHOR}/${userId}` }, 'follow the user');
      return;
    }

    const isFollowed = authUser.follows && authUser.follows.includes(userId);

    if (isFollowed) {
      this.doUnfollow(userId);
      return;
    }

    this.doFollow(userId);
  };

  doUnfollow = userId => {
    const {
      authUser,
      firebase,
      searchData: { users },
      onSetAuthUser,
      setDataUsers
    } = this.props;

    const newAuthUserFollows = authUser.follows.filter(id => id !== userId);
    const user = users.find(u => u.uid === userId);
    const newUserFollowers = user.followers.filter(id => id !== authUser.uid);

    const body = {
      data: {
        follows: newAuthUserFollows,
        followers: newUserFollowers
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const userIndex = users.findIndex(u => u.uid === userId);
        const updatedUsers = [...users];
        updatedUsers[userIndex] = {
          ...user,
          followers: newUserFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newAuthUserFollows
        });
        setDataUsers(updatedUsers);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  doFollow = userId => {
    const {
      authUser,
      firebase,
      searchData: { users },
      onSetAuthUser,
      setDataUsers
    } = this.props;

    const newAuthUserFollows = authUser.follows
      ? [...authUser.follows, userId]
      : [userId];

    const user = users.find(u => u.uid === userId);
    const newUserFollowers = user.followers
      ? [...user.followers, authUser.uid]
      : [authUser.uid];

    const body = {
      data: {
        follows: newAuthUserFollows,
        followers: newUserFollowers
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const userIndex = users.findIndex(u => u.uid === userId);
        const updatedUsers = [...users];
        updatedUsers[userIndex] = {
          ...user,
          followers: newUserFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newAuthUserFollows
        });
        setDataUsers(updatedUsers);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  render() {
    const {
      classes,
      searchData: { tags },
      authUser,
      selectedTags,
      value,
      list,
      filter,
      limit,
      result
    } = this.props;
    const { showResult, waiting, isLoaded } = this.state;

    let resultList = [...result[list]];
    if (list === 'posts' && filter !== 'all') {
      resultList = resultList.filter(post => post.type === filter);
    }
    const limitedResultList = resultList.slice(0, limit);
    const userSavedArticlesIds = (authUser && authUser.savedArticles) || [];

    return (
      <div className={classes.root}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Paper
                className={classes.form}
                component="form"
                onSubmit={this.handleSearchSubmit}>
                <InputBase
                  className={classes.input}
                  value={value}
                  placeholder="Search by topic or name"
                  disabled={waiting}
                  aria-describedby="component-helper-text"
                  onChange={this.searchOnChange}
                />
                <IconButton
                  className={classes.searchButton}
                  type="submit"
                  disabled={!value || waiting}>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item>
              <FormHelperText
                className={classes.inputHelper}
                id="component-helper-text">
                You can search for people or content
              </FormHelperText>
            </Grid>
          </Grid>
          {showResult && (
            <Grid item xs={12}>
              <Grid item>
                <Result
                  authUser={authUser}
                  resultList={limitedResultList}
                  list={list}
                  filter={filter}
                  savedArticlesIds={userSavedArticlesIds}
                  loading={waiting}
                  switchList={this.switchList}
                  switchFilter={this.switchFilter}
                  onLikeClick={this.onLikeClick}
                  onSaveClick={this.onSaveClick}
                  onFollowClick={this.onFollowClick}
                />
              </Grid>
              {!waiting && limitedResultList.length === 0 && (
                <Grid item>
                  <Typography align="center" variant="body1">
                    {`There aren't any ${list} in result`}
                  </Typography>
                </Grid>
              )}
              {!waiting && resultList.length > limit && (
                <Grid item className={classes.showMoreGrid}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.onShowMoreClick}>
                    Show more
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          {(waiting || !isLoaded) && (
            <Grid className={classes.waiting} item>
              <CircularProgress />
            </Grid>
          )}
          {isLoaded && !showResult && tags && tags.length > 0 && (
            <Grid item>
              <ExploreCard
                tags={tags}
                selectedTags={selectedTags}
                onTagClick={this.onTopicTagClick}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default SearchBase;
