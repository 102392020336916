/* eslint-disable  array-callback-return */
import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import Moment from 'react-moment';
import { Link as RouterLink } from 'react-router-dom';

import {
  Grid,
  ListItem,
  Avatar,
  Typography,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { Icon } from '@iconify/react';
import thumbsUp from '@iconify-icons/carbon/thumbs-up';

import { AUTHOR, PROFILE } from '../../../../../constants/routes';
import { MoreOptions } from '../../moreOptions';
import { styles } from './Comment.style';

const Comment = ({
  feedSlice,
  classes,
  id,
  userId,
  avatar,
  fullName,
  htmlContent,
  // date,
  likeValue,
  // isEdited,
  isEditable,
  isLikeble,
  isLiked,
  onDeleteClick,
  onLikeClick,
  scrollTo,
  onEditResponse,
  valueForEdit,
  isAdmin
}) => {
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [sliceText, setSliceText] = useState(false);
  const editorAnchorRef = useRef();

  const handleOptionsClick = e => {
    setOptionAnchorEl(e.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionAnchorEl(null);
  };

  const editHTMLwithDIVs = id => {
    const text = [];
    const elem = document.getElementById(`comment${id}`);

    const divyArray = Array.from(elem.children);

    divyArray.map((t, i) => {
      const childsNodes = Array.from(t.childNodes);

      if (i !== 0) {
        text.push('\n');
      }

      childsNodes.map(childElem => {
        if (childElem.innerHTML === '' || childElem.innerHTML === ' ') {
          return;
        }
        if (childElem.localName === 'a') {
          if (childElem.href) {
            const location = childElem.href.split('/');
            text.push(
              '@' +
                location[4].substr(0, 3) +
                '_' +
                childElem.innerHTML.replace(' ', '')
            );
          } else {
            text.push(childElem.innerHTML);
          }
        } else {
          text.push(childElem.innerHTML);
        }
      });
    });
    onEditResponse(text.join(' '), id);
  };

  const handleEditClick = () => {
    if (scrollTo) {
      window.scrollTo(0, scrollTo);
    } else {
      const rootArticle = document.getElementById('rootArticle');
      const y =
        rootArticle.offsetHeight +
        rootArticle.offsetHeight +
        rootArticle.offsetTop;
      window.scrollTo(0, y);
    }

    if (valueForEdit) {
      onEditResponse(valueForEdit, id);
      handleOptionsClose();
      return;
    }

    if (
      !valueForEdit &&
      htmlContent &&
      htmlContent.match(/<\s*div[^>]*>(.*?)[^<]\s*\s*div>/g)
    ) {
      editHTMLwithDIVs(id);
      handleOptionsClose();
      return;
    }

    if (
      !valueForEdit &&
      htmlContent &&
      !htmlContent.match(/<\s*div[^>]*>(.*?)[^<]\s*\s*div>/g)
    ) {
      const elem = document.getElementById(`comment${id}`);
      onEditResponse(elem.innerText, id);
      handleOptionsClose();
      return;
    }
  };

  const handleDeleteClick = () => {
    onDeleteClick(id);
    handleOptionsClose();
  };

  const showDescription = !showFullDescription ? 'Read more' : 'Show less';

  const elem = document.getElementById(`comment${id}`);
  // eslint-disable-next-line no-unused-vars
  const isSliceText =
    feedSlice && elem && elem.offsetHeight > 228
      ? !sliceText && setSliceText(true)
      : '';

  const routerLink = useMemo(
    () => (userId ? `${AUTHOR}/${userId}` : `${PROFILE}`),
    [userId]
  );

  return (
    <ListItem className={classes.root}>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar
            src={avatar}
            className={classes.avatar}
            alt=""
            component={RouterLink}
            to={routerLink}
          />
        </Grid>
        <Grid
          ref={editorAnchorRef}
          container
          item
          xs
          direction="column"
          className={classes.commentBody}
          justify="space-between">
          <Grid container item alignItems="baseline" justify="space-between">
            <Grid item>
              <Typography className={classes.name} component="h5">
                <RouterLink to={routerLink}>{fullName}</RouterLink>
              </Typography>
            </Grid>
            {/* {date && (
              <Grid item className={classes.period}>
                <Moment fromNow>{date}</Moment>
                {isEdited && <span> | Edited</span>}
              </Grid>
            )} */}
            {(isEditable || isAdmin) && (
              <Grid item>
                <MoreOptions
                  size="small"
                  anchorEl={optionAnchorEl}
                  onOptionsClick={handleOptionsClick}
                  onOptionClose={handleOptionsClose}
                  onEditClick={handleEditClick}
                  onDeleteClick={handleDeleteClick}
                  isEditable={isEditable || isAdmin}
                  onPost={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <div
              id={'comment' + id}
              className={clsx({
                [classes.text]: true,
                [classes.styleSliceText]: sliceText && !showFullDescription
              })}
              dangerouslySetInnerHTML={{
                __html: htmlContent
              }}></div>
            {sliceText && (
              <Typography
                onClick={() => setShowFullDescription(!showFullDescription)}
                className={classes.textColor}>
                {showDescription}
              </Typography>
            )}
            {/* {comment} */}
          </Grid>
        </Grid>
        {/* <Grid container className={classes.likeContainer}>
          <Grid item>
            <IconButton
              className={classes.likeButton}
              onClick={() => onLikeClick(id)}
              disabled={!isLikeble}>
              <Icon
                icon={thumbsUp}
                className={clsx({
                  [classes.likeIcon]: true,
                  liked: isLiked
                })}
              />
              <Typography className={classes.likeValue} component="span">
                {likeValue}
              </Typography>
            </IconButton>
          </Grid>
        </Grid> */}

        <Grid
          container
          className={classes.likeContainer}
          justify="space-between">
          <Grid item className={classes.likeValue}>
            <Typography
              onClick={() => onLikeClick(id)}
              className={clsx({
                [classes.liked]: true,
                liked: isLiked
              })}>
              {isLiked ? 'Liked' : 'Like'}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.likeButton}
              onClick={() => onLikeClick(id)}
              disabled={!isLikeble}>
              <Icon
                icon={thumbsUp}
                className={clsx({
                  [classes.likeIcon]: true,
                  liked: isLiked
                })}
              />
              <Typography className={classes.likeValue} component="span">
                {likeValue}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  htmlContent: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  likeValue: PropTypes.number.isRequired,
  isEdited: PropTypes.bool,
  isEditable: PropTypes.bool,
  isLiked: PropTypes.bool,
  onUpdateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onLikeClick: PropTypes.func
};

export default withStyles(styles)(Comment);
