const INITIAL_STATE = {
  data: {
    articles: null,
    users: null,
    tags: null
  },
  selectedTags: [],
  value: '',
  list: 'posts',
  filter: 'all',
  limit: 20,
  result: {
    request: '',
    posts: [],
    people: []
  },
  exploreResult: {
    filter: 'all'
  }
};

const applyDataArticles = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    articles: [...action.articles]
  }
});

const applyDataUsers = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    users: [...action.users]
  }
});

const applyDataTags = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    tags: [...action.tags]
  }
});

const applySelectedTags = (state, action) => ({
  ...state,
  selectedTags: [...action.tags]
});

const applySearchValue = (state, action) => ({
  ...state,
  value: action.value
});

const applySelectedList = (state, action) => {
  return {
    ...state,
    list: action.list
  };
};

const applySelectedFilter = (state, action) => ({
  ...state,
  filter: action.filter
});

const applyLimitValue = (state, action) => ({
  ...state,
  limit: action.limit
});

const applySetResult = (state, action) => ({
  ...state,
  result: {
    ...action.result
  }
});

const applyInitialResult = state => ({
  ...state,
  limit: INITIAL_STATE.limit,
  result: {
    ...INITIAL_STATE.result
  }
});

const applyExploreSelectedFilter = (state, action) => ({
  ...state,
  exploreResult: {
    ...state.exploreResult,
    filter: action.filter
  }
});

function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ARTICLES_DATA_SET': {
      return applyDataArticles(state, action);
    }
    case 'USERS_DATA_SET': {
      return applyDataUsers(state, action);
    }
    case 'TAGS_DATA_SET': {
      return applyDataTags(state, action);
    }
    case 'SELECTED_TAGS_SET': {
      return applySelectedTags(state, action);
    }
    case 'SEARCH_VALUE_SET': {
      return applySearchValue(state, action);
    }
    case 'SELECTED_LIST_SET': {
      return applySelectedList(state, action);
    }
    case 'SELECTED_FILTER_SET': {
      return applySelectedFilter(state, action);
    }
    case 'EXPLORE_SELECTED_FILTER_SET': {
      return applyExploreSelectedFilter(state, action);
    }
    case 'SET_LIMIT': {
      return applyLimitValue(state, action);
    }
    case 'RESULT_SET': {
      return applySetResult(state, action);
    }
    case 'RESET_RESULT': {
      return applyInitialResult(state);
    }
    default:
      return state;
  }
}

export default searchReducer;
