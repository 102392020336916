/* eslint-disable no-loop-func */
/* eslint-disable no-empty-function */
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';

import { ARTICLES, SIGN_IN, SIGN_UP } from '../../constants/routes';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(5, 3, 7),
    // backgroundColor: theme.palette.primary.main
    backgroundImage: 'url(/images/landing.png)',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%'
  },
  header: {
    textAlign: 'center',
    justifyContent: 'center'
  },
  welcomeContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '120px'
  },
  welcome: {
    color: '#FFF',
    fontSize: '30pt',
    fontWeight: 'bold',
    lineHeight: '50px',
    margin: theme.spacing(0, 4, 0, 4)
  },
  bodyContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '40px'
  },
  body: {
    color: '#FFF',
    fontSize: '15pt',
    lineHeight: '25px'
  },
  body2: {
    color: '#FFF',
    fontSize: '15pt',
    lineHeight: '15px'
  },
  logo: {
    width: '120px',
    paddingTop: theme.spacing(4)
  },
  socialButtons: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    justifyContent: 'center'
  },
  signUp: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
      marginRight: '10px'
    },
    color: '#FFF'
  },
  signIn: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '200px'
    },
    color: theme.palette.primary.main
  }
});

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      followers: null,
      loading: true,
      fromAuthor: false,
      authorName: ''
    };
  }
  render() {
    const { classes, authUser, history } = this.props;
    const state = this.props.location && this.props.location.state;

    const signOut = (state && state.signOut) || false;
    console.log('signOut :>> ', signOut);

    if (authUser && !signOut) {
      history.push(ARTICLES);
    }

    const signIn = () => {
      history.push(SIGN_IN);
    };

    const signUp = () => {
      history.push(SIGN_UP);
    };

    return (
      <div className={classes.root}>
        {!authUser && (
          <div>
            <Grid className={classes.header} container>
              <Grid item>
                <img
                  className={classes.logo}
                  alt="Logo"
                  src="/images/logos/Zonotho_logo_white_alt.png"
                />
              </Grid>
            </Grid>
            <Grid className={classes.welcomeContainer} container>
              <Grid item>
                <Typography className={classes.welcome} align="center">
                  Hi, Welcome <br /> to Zonotho
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.bodyContainer} container>
              <Grid item>
                <Typography className={classes.body} align="center">
                  A place to share what you know... <br /> ..and what you don’t{' '}
                </Typography>
                <Typography className={classes.body2} align="center">
                  <br /> about money!
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.socialButtons} container spacing={2}>
              <Grid item className={classes.signUp}>
                <Button
                  size="large"
                  variant="outlined"
                  color="inherit"
                  onClick={signUp}
                  className={classes.signUp}>
                  Sign up
                </Button>
              </Grid>
              <Grid item className={classes.signIn}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={signIn}
                  className={classes.signIn}>
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(withStyles(styles)(Landing));
