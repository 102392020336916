import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import { Option, Options } from './moreOptions.style';

const mentionStyle = {
  fontSize: '25px',
  color: '#D8D8D8',
  padding: 0
};

const mentionStylePostComment = {
  fontSize: '25px',
  color: '#7F7F7F',
  padding: 0
};

export const MoreOptions = ({
  size,
  anchorEl,
  onOptionsClick,
  onOptionClose,
  onEditClick,
  onDeleteClick,
  onSaveClick,
  isSaved,
  isEditable,
  canSave,
  onPost
}) => {
  return (
    <React.Fragment>
      <IconButton
        size={size}
        aria-haspopup="true"
        onClick={onOptionsClick}
        style={onPost ? mentionStylePostComment : mentionStyle}>
        <MoreHoriz />
      </IconButton>
      <Options
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onOptionClose}>
        {isEditable && (
          <div>
            <Option onClick={onEditClick}>Edit</Option>
            <Option onClick={onDeleteClick}>Delete</Option>
          </div>
        )}
        {canSave && (
          <Option onClick={onSaveClick}>{isSaved ? 'Saved' : 'Save'}</Option>
        )}
      </Options>
    </React.Fragment>
  );
};

MoreOptions.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  anchorEl: PropTypes.object,
  onOptionsClick: PropTypes.func,
  onOptionClose: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

MoreOptions.defaultProps = {
  size: 'medium'
};

export default MoreOptions;
