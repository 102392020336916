export const styles = () => ({
  avatar: {
    width: 20,
    height: 20,
    marginRight: '6px'
  },
  option: {
    display: 'flex',
    alignItems: 'center'
  },
  textArea: {
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    '& .rc-textarea': {
      border: '1px solid #000000',
      borderRadius: '4px',
      padding: '10px',
      minHeight: '150px',
      fontSize: '14px',
      fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`
    },
    '&:focus': {
      border: 'none',
      borderColor: 'none',
      borderRightWidth: '0px !important',
      outline: 0,
      webkitBoxShadow: 'none',
      boxShadow: 'none'
    },
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      borderRightWidth: '0px !important',
      outline: 0,
      webkitBoxShadow: 'none'
    },
    '& .ant-mentions-measure': {
      // left: '-50px'
      fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`
    }
  },
  postStyle: {
    '& .rc-textarea': {
      padding: '10px',
      minHeight: '250px',
      fontSize: '14px',
      fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
      '&::placeholder': {
        color: '#666666',
        fontWeight: '500',
        lineHeight: '1.3334',
        letterSpacing: '1.88px',
        opacity: 1,
        fontSize: '12px'
      }
    },
    '&:focus': {
      border: 'none',
      borderColor: 'none',
      borderRightWidth: '0px !important',
      outline: 0,
      webkitBoxShadow: 'none',
      boxShadow: 'none'
    },
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      borderRightWidth: '0px !important',
      outline: 0,
      webkitBoxShadow: 'none'
    },
    '& .ant-mentions-measure': {
      zIndex: '9000 !important',
      fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif" !important`
    }
  },
  optionTag: {
    fontSize: '10px',
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif" !important`
  },
  close: {
    '& .ant-mentions-measure': {
      display: 'none !important'
    }
  }
});

export default styles;
