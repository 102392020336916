const INITIAL_STATE = {
  alert: null,
  signInDialog: {
    message: '',
    locationState: null,
    isShown: false
  },
  messageDialog: {
    header: '',
    message: '',
    locationState: null,
    isShown: false
  }
};

const applySetAlert = (state, action) => ({
  ...state,
  alert: { ...action.alert }
});

const applySetAlertReset = state => ({
  ...state,
  alert: null
});

const applySetSignInDialog = (state, action) => ({
  ...state,
  signInDialog: {
    message: action.message,
    locationState: { ...action.locationState },
    isShown: true
  }
});

const applySetSignInDialogReset = state => ({
  ...state,
  signInDialog: {
    message: '',
    locationState: null,
    isShown: false
  }
});

const applySetMessageDialog = (state, action) => ({
  ...state,
  messageDialog: {
    header: action.header,
    message: action.message,
    locationState: { ...action.locationState },
    isShown: true
  }
});

const applySetMessageDialogReset = state => ({
  ...state,
  messageDialog: {
    header: '',
    message: '',
    locationState: null,
    isShown: false
  }
});

function messagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ALERT_SET': {
      return applySetAlert(state, action);
    }
    case 'ALERT_RESET': {
      return applySetAlertReset(state);
    }
    case 'SIGN_IN_DIALOG_VISIBLE': {
      return applySetSignInDialog(state, action);
    }
    case 'SIGN_IN_DIALOG_RESET': {
      return applySetSignInDialogReset(state);
    }
    case 'MESSAGE_DIALOG_VISIBLE': {
      return applySetMessageDialog(state, action);
    }
    case 'MESSAGE_DIALOG_RESET': {
      return applySetMessageDialogReset(state);
    }
    default:
      return state;
  }
}

export default messagesReducer;
