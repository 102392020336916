/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import {
  Grid,
  Button,
  TextField,
  Link,
  // Checkbox,
  Typography
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../Firebase';
import { VERIFY_EMAIL, SIGN_IN, SIGN_UP } from '../../constants/routes';
import { MEMBER, CONTRIBUTOR } from '../../constants/roles';

import { SignInGoogle, SignInFacebook } from '../SignIn';

const styles = theme => ({
  root: {
    height: '100%'
  },
  grid: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4)
    }
  },
  contentContainer: {},
  content: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  contributor: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  contributorCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  socialButtons: {
    marginTop: theme.spacing(1)
  },
  socialButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1)
  }
});

const SignUpPage = () => <SignUpForm />;

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  role: MEMBER,
  isContributor: false,
  error: null,
  openAlert: false
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { firstName, lastName, email, passwordOne, role } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase.user(authUser.user.uid).set(
          {
            firstName,
            lastName,
            email,
            role,
            joined: this.props.firebase.fieldValue.serverTimestamp()
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });

        this.props.firebase
          .doSignOut()
          .then(() => {
            this.props.history.push({
              pathname: VERIFY_EMAIL,
              state: { userEmail: email }
            });
          })
          .catch(error => {
            console.log('error :', error);
          });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
          this.setState({ error, openAlert: true });
        }

        this.props.showMessageDialog(
          { pathname: `${SIGN_UP}` },
          'SIGN UP FAILED',
          'A problem occurred during sign up, please try again.'
        );
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });

    if (event.target.checked) {
      this.setState({ role: CONTRIBUTOR });
    } else {
      this.setState({ role: MEMBER });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openAlert: false });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      // isContributor,
      error,
      openAlert
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstName === '' ||
      lastName === '';

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={this.onSubmit}>
                <Typography
                  color="textSecondary"
                  className={classes.title}
                  gutterBottom>
                  Sign up with social media
                </Typography>
                <Grid className={classes.socialButtons} container spacing={2}>
                  <Grid item className={classes.socialButton}>
                    <SignInGoogle />
                  </Grid>
                  <Grid item className={classes.socialButton}>
                    <SignInFacebook />
                  </Grid>
                </Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1">
                  or sign up with your details below
                </Typography>
                {/* <Typography variant="h2">Create new account</Typography>
                <Typography color="textSecondary" gutterBottom>
                  Use your email to create new account
                </Typography> */}
                <TextField
                  className={classes.textField}
                  required
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={this.onChange}
                  fullWidth
                  type="text"
                />
                <TextField
                  className={classes.textField}
                  required
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={this.onChange}
                  fullWidth
                  type="text"
                />
                <TextField
                  className={classes.textField}
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  value={email}
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  required
                  id="passwordOne"
                  label="Password"
                  name="passwordOne"
                  variant="outlined"
                  value={passwordOne}
                  type="password"
                  onChange={this.onChange}
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  required
                  id="passwordTwo"
                  label="Confirm Password"
                  name="passwordTwo"
                  variant="outlined"
                  value={passwordTwo}
                  type="password"
                  onChange={this.onChange}
                />
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={isInvalid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Sign up
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Have an account?{' '}
                  <Link component={RouterLink} to={SIGN_IN} variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            </div>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          onClose={this.handleClose}
          autoHideDuration={5000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const SignUpLink = () => (
  <p>
    <Link to={SIGN_UP}>Don't have an account? Sign Up</Link>
  </p>
);

const mapDispatchToProps = dispatch => ({
  showMessageDialog: (locationState, header, message) =>
    dispatch({ type: 'MESSAGE_DIALOG_VISIBLE', locationState, header, message })
});

const SignUpForm = compose(
  withRouter,
  withFirebase,
  connect(mapDispatchToProps)
)(withStyles(styles)(SignUpFormBase));

SignUpFormBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default SignUpPage;

export { SignUpForm, SignUpLink };
