export const styles = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
    '&.colorCard': {
      backgroundColor: '#ABCFD833',
      border: `1px solid #70707033`
    }
  },
  period: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: 8,
    lineHeight: 1.25,
    letterSpacing: 0.27
  },
  avatar: {
    width: 49,
    height: 49
  },
  message: {
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 0,
    '& a': {
      color: theme.palette.text.brand
    }
  },
  wrapHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default styles;
