import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.cyan[900],
    main: '#64A6B8',
    light: colors.cyan[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#212121',
    secondary: '#666666',
    link: colors.blue[600],
    brand: '#64A6B8'
  },
  background: {
    default: '#EEEEEE',
    topBar: white,
    paper: white,
    brand: '#64A6B8'
  },
  border: '#e0e0e0',
  icon: '#7F7F7F',
  divider: colors.grey[200]
};
