async function increment(userId, value = 1) {
  if (!userId) {
    return;
  }

  const { firebase } = this.props;

  const userData = await firebase.getUser(userId);
  const coins = (userData.coins || 0) + value;

  const body = {
    data: {
      coins
    },
    merge: true
  };

  await firebase.editUser(userId, body);
}

async function decrement(userId, value = 1) {
  if (!userId) {
    return;
  }

  const { firebase } = this.props;

  const userData = await firebase.getUser(userId);
  const coins = userData.coins || 0;
  let newCoins = coins !== 0 ? coins - value : coins;

  if (newCoins < 0) {
    newCoins = 0;
  }

  const body = {
    data: {
      coins: newCoins
    },
    merge: true
  };

  await firebase.editUser(userId, body);
}

export default {
  increment,
  decrement
};
