import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Button,
  Typography
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import { VERIFY_EMAIL } from '../../constants/routes';

const styles = theme => ({
  root: {},
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  actionButon: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.setState({ isSent: true });

      this.props.firebase
        .doSignOut()
        .then(() => {
          this.props.history.push({
            pathname: VERIFY_EMAIL,
            state: { userEmail: this.props.authUser.email }
          });
        })
        .catch(error => {
          console.log('error :', error);
        });
    };

    render() {
      const { classes } = this.props;

      return needsEmailVerification(this.props.authUser) ? (
        <div className={classes.root}>
          <Grid className={classes.content} item lg={12} xs={12}>
            <Card>
              <CardHeader title="E-Mail Confirmation Pending" />
              <Divider />
              <CardContent>
                <Grid>
                  <Typography>
                    Check you E-Mails (Spam folder included) for a confirmation
                    E-Mail. Refresh this page once you confirmed your E-Mail or
                    send another confirmation E-Mail.
                  </Typography>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container spacing={2}>
                  <Grid item className={classes.actionButon}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.onSendEmailVerification}
                      disabled={this.state.isSent}
                      className={classes.actionButon}>
                      Resend confirmation E-Mail
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </div>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  WithEmailVerification.propTypes = {
    classes: PropTypes.object.isRequired
  };

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser
  });

  return compose(
    withFirebase,
    connect(mapStateToProps),
    withRouter
  )(withStyles(styles)(WithEmailVerification));
};

export default withEmailVerification;
