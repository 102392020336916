export const styles = theme => ({
  root: {
    padding: theme.spacing(1.5)
  },
  title: {
    margin: theme.spacing(0.5, 0, 1),
    fontSize: 12,
    lineHeight: 1.3334,
    fontWeight: 500,
    letterSpacing: 1.88,
    color: '#00000099'
  },
  tagList: {
    justifyContent: 'center',
    listStyle: 'none'
  },
  option: {
    padding: '10px 3px'
  },
  tag: {
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 0.25,
    '&.selected': {
      color: '#6100ED',
      backgroundColor: '#D6C4F9'
    }
  },
  description: {
    marginBottom: theme.spacing(1.5),
    fontSize: 12,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  btn: {
    fontSize: 12,
    lineHeight: 1.6667,
    letterSpacing: 1.07
  }
});

export default styles;
