export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%'
  },
  question: {
    marginLeft: theme.spacing(3),
    fontSize: 20,
    lineHeight: '30px',
    opacity: 0.57
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 3),
    listStyle: 'none'
  },
  option: {
    padding: '10px 3px'
  },
  tag: {
    fontSize: 14,
    lineHeight: '19px',
    backgroundColor: '#DFDFDF',
    '&.selected': {
      color: '#6100ED',
      backgroundColor: '#D6C4F9'
    }
  },
  description: {
    maxWidth: 280,
    margin: '0 auto',
    fontSize: 10,
    lineHeight: '12px',
    opacity: 0.4,
    textAlign: 'center'
  }
});

export default styles;
