import { createSelector } from 'reselect';

const getAuthorState = state => state.authorState;

export const allAuthorDataSelector = createSelector(
  getAuthorState,
  state => state.author
);

export const authorIsFollowedSelector = createSelector(
  getAuthorState,
  state => state.isFollowed
);

export const authorArticlesSelector = createSelector(
  getAuthorState,
  state => state.articles
);

export default {
  allAuthorDataSelector,
  authorIsFollowedSelector
};
