/* eslint-disable import/prefer-default-export */
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import { allNotificationSelector } from './selectors';
import { authUserSelector } from '../Session/selectors';

import { styles } from './Notifications.style';
import NotificationBase from './Notifications';

const mapStateToProps = state => ({
  authUser: authUserSelector(state),
  notifications: allNotificationSelector(state)
});
const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

const NotificationPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(withStyles(styles)(NotificationBase));

export default NotificationPage;
