import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/styles';
import { Grid, CardActions, Typography, IconButton } from '@material-ui/core';

import { Bookmark } from '@material-ui/icons';

import { Icon } from '@iconify/react';
import speechTyping from '@iconify-icons/system-uicons/speech-typing';
import thumbsUp from '@iconify-icons/carbon/thumbs-up';

import { styles } from './Actions.style';
import { Share } from '../share';

const Actions = ({
  classes,
  likeValue,
  commentValue,
  isLiked,
  isSaved,
  isAuthor,
  onLikeClick,
  onSaveClick,
  article
}) => {
  return (
    <CardActions className={classes.actionsContainer}>
      <Grid container wrap="nowrap" spacing={2} justify="space-between">
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <IconButton
                className={classes.likeButton}
                disabled={isAuthor}
                onClick={onLikeClick}>
                <Icon
                  icon={thumbsUp}
                  className={clsx({
                    [classes.likeIcon]: true,
                    liked: isLiked
                  })}
                />
                <Typography className={classes.likeValue} component="span">
                  {likeValue}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton className={classes.commentButton} disableRipple>
                <Icon icon={speechTyping} className={classes.commentIcon} />
                <Typography className={classes.commentValue} component="span">
                  {commentValue}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item>
              <Share article={article} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {!isAuthor && (
            <span>
              <IconButton className={classes.favoriteBtn} onClick={onSaveClick}>
                {isSaved ? 'Saved' : 'Save'}
                <Bookmark
                  className={clsx({
                    [classes.bookmarkIcon]: true,
                    saved: isSaved
                  })}
                />
              </IconButton>
            </span>
          )}
        </Grid>
      </Grid>
    </CardActions>
  );
};

Actions.propTypes = {
  classes: PropTypes.object.isRequired,
  likeValue: PropTypes.number,
  commentValue: PropTypes.number,
  isLiked: PropTypes.bool,
  isSaved: PropTypes.bool,
  onLikeClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired
};

Actions.defaultProps = {
  likeValue: 0,
  commentValue: 0,
  isLiked: false,
  isSaved: false
};

export default withStyles(styles)(Actions);
