const INITIAL_STATE = {
  categories: null
};

const applySetCategories = (state, action) => ({
  ...state,
  categories: action.categories
});

const applySetCategory = (state, action) => ({
  ...state,
  categories: {
    ...state.categories,
    [action.uid]: action.category
  }
});

function categoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CATEGORIES_SET': {
      return applySetCategories(state, action);
    }
    case 'CATEGORY_SET': {
      return applySetCategory(state, action);
    }
    default:
      return state;
  }
}

export default categoryReducer;
