import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Typography, Grid, Chip, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { EXPLORE } from '../../../../constants/routes';

import { styles } from './ExploreCard.style';

const ExploreCardBase = ({ classes, tags, selectedTags, onTagClick }) => {
  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} component="h2">
        OR EXPLORE TOPICS
      </Typography>
      <Grid className={classes.tagList} container component="ul">
        {tags.map(tag => {
          const capitalizeName = tag.name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          const isSelected = selectedTags.find(t => t.uid === tag.uid);

          return (
            <Grid className={classes.option} key={tag.uid} item component="li">
              <Chip
                className={clsx({
                  [classes.tag]: true,
                  selected: isSelected
                })}
                label={capitalizeName}
                onClick={() => onTagClick(tag)}
              />
            </Grid>
          );
        })}
      </Grid>
      <Typography className={classes.description}>
        Select one or more topics to view related content
      </Typography>
      <Button
        className={classes.btn}
        component={RouterLink}
        to={EXPLORE}
        variant="contained"
        disabled={selectedTags.length < 1}
        color="primary"
        fullWidth>
        Explore
      </Button>
    </Paper>
  );
};

ExploreCardBase.propTypes = {
  classes: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
};

export default withStyles(styles)(ExploreCardBase);
