import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

const withNotifications = Component => {
  class WithNotifications extends React.Component {
    listener() {
      const { firebase, authUser, onSetAuthUser } = this.props;

      return firebase.user(authUser.uid).onSnapshot(snapshot => {
        const db = snapshot.data();
        if (db && db.notifications) {
          const isSameNotifications = _.isEqual(
            db.notifications,
            authUser.notifications
          );
          if (!isSameNotifications) {
            onSetAuthUser({
              ...authUser,
              notifications: db.notifications
            });
          }
        }
      });
    }

    componentDidMount() {
      const { authUser } = this.props;

      if (authUser) {
        try {
          this.notificationsListener = this.listener();
        } catch (err) {
          console.error('error: ', err);
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.authUser) {
        this.notificationsListener = this.listener();
      }
    }

    componentWillUnmount() {
      this.notificationsListener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser
  });

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
  });

  return compose(
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps)
  )(WithNotifications);
};

export default withNotifications;
