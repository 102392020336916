import { withStyles } from '@material-ui/styles';
import { Slider } from '@material-ui/core';

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%'
  },
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
    fontSize: 30,
    lineHeight: '30px',
    opacity: 0.57
  },
  question: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(7),
    fontSize: 20,
    lineHeight: '30px',
    opacity: 0.57
  },
  sliderContainer: {
    padding: theme.spacing(0, 5)
  }
});

export const StyledSlider = withStyles({
  root: {
    color: '#008265'
  },
  markLabel: {
    maxWidth: 72,
    fontSize: 12,
    lineHeight: '1.25em',
    textAlign: 'center',
    whiteSpace: 'normal',
    opacity: 0.57
  }
})(Slider);

export default {
  styles,
  StyledSlider
};
