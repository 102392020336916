export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(5, 3, 7),
    backgroundColor: '#ffffff'
  },
  main: {
    height: 'calc(100% - 51px - 36px)'
  },
  mainContainer: {
    height: '100%',
    overflowY: 'auto'
  },
  stepBtn: {
    fontWeight: 700,
    color: '#82637C'
  },
  prevBtnContainer: {},
  nextBtnContainer: {
    marginLeft: 'auto'
  }
});

export default {
  styles
};
