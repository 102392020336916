const INITIAL_STATE = {
  articles: null,
  limit: 20,
  category: null
};

const applySetArticles = (state, action) => ({
  ...state,
  articles: action.articles
});

const applySetArticlesLimit = (state, action) => ({
  ...state,
  limit: action.limit
});

const applySetArticlesCategory = (state, action) => ({
  ...state,
  category: action.category
});

const applySetArticle = (state, action) => ({
  ...state,
  articles: {
    ...state.articles,
    [action.uid]: action.article
  }
});

function articleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ARTICLES_SET': {
      return applySetArticles(state, action);
    }
    case 'ARTICLES_LIMIT_SET': {
      return applySetArticlesLimit(state, action);
    }
    case 'ARTICLES_CATEGORY_SET': {
      return applySetArticlesCategory(state, action);
    }
    case 'ARTICLE_SET': {
      return applySetArticle(state, action);
    }
    default:
      return state;
  }
}

export default articleReducer;
