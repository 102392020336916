import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';

import {
  AccountCircleOutlined,
  ArrowBack,
  Search,
  LibraryBooks,
  Notifications,
  Add,
  People,
  DoneAll
} from '@material-ui/icons';
import { Icon } from '@iconify/react';
import fireIcon from '@iconify/icons-mdi/fire';

import {
  SIGN_IN,
  PROFILE,
  ARTICLES,
  SEARCH,
  TRENDS,
  NEW_POST,
  POPULAR_USERS,
  NOTIFICATIONS,
  ADMIN_PANEL
} from '../../constants/routes';

import {
  StyledTabs,
  StyledTab,
  StyledBadge,
  RoundIconButton
} from './DesktopTopBar.style';

const TAB_VALUES = [
  ARTICLES,
  NOTIFICATIONS,
  NEW_POST,
  TRENDS,
  POPULAR_USERS,
  SEARCH,
  ADMIN_PANEL
];

const DesktopTopBarBase = ({
  classes,
  authUser,
  location,
  history,
  newNotificationsAmount
}) => {
  const handleBack = () => {
    // eslint-disable-next-line no-invalid-this
    history.goBack();
  };

  const selectedTab = TAB_VALUES.includes(location.pathname)
    ? location.pathname
    : false;

  const isArticlesPage = location.pathname === ARTICLES; // || location.pathname === LANDING;

  const admin = authUser && authUser.role === 'ADMIN';

  return (
    <AppBar className={classes.root} elevation={1}>
      <Toolbar className={classes.navBar} component="nav">
        <Grid container justify="space-between">
          <Grid item container xs={3} alignItems="center">
            <Grid item>
              {isArticlesPage && (
                <IconButton
                  className={classes.button}
                  component={RouterLink}
                  to={!authUser ? SIGN_IN : PROFILE}>
                  <AccountCircleOutlined />
                </IconButton>
              )}
              {!isArticlesPage && (
                <IconButton className={classes.button} onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              )}
            </Grid>
            <Grid item className={classes.logoContainer}>
              <RouterLink className={classes.logo} to={ARTICLES}>
                <img
                  srcSet="/images/logos/zonotho-logo-green-320.png 111w,
                          /images/logos/zonotho-logo-green-640.png 222w"
                  sizes="(max-width: 1279px) 111px,
                         (min-width: 1280px) 222px"
                  alt="Logo"
                  src="/images/logos/zonotho-logo-green-320.png"
                />
              </RouterLink>
            </Grid>
          </Grid>
          <Grid item>
            <StyledTabs
              className={classes.tabs}
              value={selectedTab}
              centered
              variant="standard"
              aria-label="page tabs">
              <StyledTab
                className={clsx({
                  [classes.tab]: true,
                  newPostTab: true
                })}
                icon={
                  <RoundIconButton disableRipple={true}>
                    <Add />
                  </RoundIconButton>
                }
                component={RouterLink}
                to={!authUser ? SIGN_IN : NEW_POST}
                value={!authUser ? SIGN_IN : NEW_POST}
                label="New post"
              />
              <StyledTab
                className={classes.tab}
                icon={<LibraryBooks />}
                component={RouterLink}
                to={ARTICLES}
                value={ARTICLES}
                label="Feed"
              />
              <StyledTab
                className={classes.tab}
                icon={<Icon icon={fireIcon} />}
                component={RouterLink}
                to={TRENDS}
                value={TRENDS}
                label="Trending"
              />
              <StyledTab
                className={classes.tab}
                icon={<People />}
                component={RouterLink}
                to={POPULAR_USERS}
                value={POPULAR_USERS}
                label="People"
              />
              <StyledTab
                className={classes.tab}
                icon={
                  <StyledBadge badgeContent={newNotificationsAmount}>
                    <Notifications />
                  </StyledBadge>
                }
                component={RouterLink}
                to={!authUser ? SIGN_IN : NOTIFICATIONS}
                value={!authUser ? SIGN_IN : NOTIFICATIONS}
                label="Notifications"
              />
              {admin && (
                <StyledTab
                  className={classes.tab}
                  icon={<DoneAll />}
                  component={RouterLink}
                  to={admin ? ADMIN_PANEL : ARTICLES}
                  value={admin ? ADMIN_PANEL : ARTICLES}
                  label="Review Posts"
                />
              )}
              <StyledTab
                className={clsx({
                  [classes.tab]: true,
                  searchTab: true
                })}
                icon={<Search />}
                component={RouterLink}
                to={SEARCH}
                value={SEARCH}
                label="Search"
              />
            </StyledTabs>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopTopBarBase;
