import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Menu, MenuItem } from '@material-ui/core';

export const Options = withStyles(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
  },
  list: {
    padding: 0
  }
}))(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    {...props}
  />
));

export const Option = withStyles(theme => ({
  root: {
    minHeight: 'auto',
    padding: theme.spacing(1),
    fontSize: 12,
    lineHeight: 1.3334,
    color: 'inherit'
  }
}))(MenuItem);

export default {
  Option,
  Options
};
