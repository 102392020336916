/* eslint-disable no-loop-func */
/* eslint-disable no-empty-function */
import React, { Component } from 'react';
import { LinearProgress, Typography, Grid } from '@material-ui/core';
import { nanoid } from 'nanoid';
import FollowerCard from './FollowerCard';

class FollowersBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      followers: null,
      loading: true,
      fromAuthor: false,
      authorName: ''
    };
  }

  componentDidMount() {
    const { authUser, location } = this.props;

    if (authUser && location.state && location.state.fromAuthor) {
      const { followers, name } = location.state;
      this.setState({
        authorName: name
      });
      this.getFollowsAuthor(followers);
    }
    if (
      authUser &&
      !location.state &&
      authUser.followers &&
      authUser.followers.length > 0
    ) {
      this.getFollowers();

      return;
    }

    this.setState({
      loading: false
    });
  }

  async getFollowsAuthor(followers) {
    const { firebase } = this.props;
    let followersData = [];

    if (followers.length > 0) {
      for (let i = 0; i < followers.length; i += 10) {
        const requests = [];

        const args = {
          filters: [
            {
              field: 'documentId',
              condition: 'in',
              value: followers.slice(i, i + 10)
            }
          ]
        };

        const data = await firebase.getUsersWithArgs(args);
        requests.push(data);

        await Promise.all(requests)
          .then(a => {
            followersData = [...followersData, ...a[0]];
            this.setState({
              followers: followersData,
              loading: false,
              fromAuthor: true
            });
          })
          .catch(e => console.log(e));
      }
    }
  }

  getFollowers = async () => {
    try {
      const { authUser, firebase } = this.props;
      const followers = {};

      let followersData = [];

      if (authUser.followers.length > 0) {
        for (let i = 0; i < authUser.followers.length; i += 10) {
          const requests = [];

          const args = {
            filters: [
              {
                field: 'documentId',
                condition: 'in',
                value: authUser.followers.slice(i, i + 10)
              }
            ]
          };

          const data = await firebase.getUsersWithArgs(args);
          requests.push(data);

          await Promise.all(requests)
            .then(a => {
              followersData = [...followersData, ...a[0]];

              followersData.forEach(s => {
                followers[s.uid] = s;
              });

              this.setState({
                followers,
                loading: false
              });
            })
            .catch(e => console.log(e));
        }
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  onFollowClick = (e, followerId) => {
    e.preventDefault();

    const { authUser, showSighInDialog } = this.props;
    const { fromAuthor } = this.state;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${window.location.pathname}` },
        'follow the user'
      );
      return;
    }

    const isFollowed = authUser.follows.includes(followerId);

    if (fromAuthor) {
      if (isFollowed) {
        this.doUnFollowOnAuthorPage(followerId);
        return;
      }
      this.doFollowOnAuthorPage(followerId);
    } else {
      if (isFollowed) {
        this.doUnfollow(followerId);
        return;
      }

      this.doFollow(followerId);
    }
  };

  doUnfollow = followerId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { followers } = this.state;

    const newUserFollows = authUser.follows.filter(id => id !== followerId);
    const newFollowerFollowers = followers[followerId].followers.filter(
      id => id !== authUser.uid
    );

    const body = {
      data: {
        follows: newUserFollows
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const updatedFollower = {
          ...followers[followerId],
          followers: newFollowerFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          followers: {
            ...followers,
            [followerId]: {
              ...updatedFollower
            }
          }
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    const newBody = {
      data: {
        followers: newFollowerFollowers
      },
      merge: true
    };

    firebase
      .editUser(followerId, newBody)
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  doFollow = followerId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { followers } = this.state;

    const newUserFollows = authUser.follows
      ? [...authUser.follows, followerId]
      : [followerId];

    const newFollowerFollowers = followers[followerId].followers
      ? [...followers[followerId].followers, authUser.uid]
      : [authUser.uid];

    const body = {
      data: {
        follows: newUserFollows
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const updatedFollower = {
          ...followers[followerId],
          followers: newFollowerFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          followers: {
            ...followers,
            [followerId]: {
              ...updatedFollower
            }
          }
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    const newBody = {
      data: {
        followers: newFollowerFollowers
      },
      merge: true
    };

    firebase
      .editUser(followerId, newBody)
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  updateFollows = (updatedUser, updatedAuthor, followerId, isFollows) => {
    const { firebase, onSetAuthUser } = this.props;

    const body = {
      data: {
        follows: updatedUser.follows
      },
      merge: true
    };

    firebase
      .editUser(updatedUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });

    const newBody = {
      data: {
        followers: updatedAuthor.followers
      },
      merge: true
    };

    firebase
      .editUser(followerId, newBody)
      .then(() => {})
      .catch(error => {
        console.log('error: ', error);
      });

    if (isFollows) {
      const type = 'newFollower';

      this.makeNotification(type, followerId);
    }
  };

  doUnFollowOnAuthorPage = async followerId => {
    const { authUser, firebase } = this.props;
    const authorData = await firebase.getUser(followerId);

    const newUserFollows = authUser.follows.filter(id => id !== followerId);
    const newAuthorFollowers = authorData.followers.filter(
      id => id !== authUser.uid
    );

    const updatedUser = { ...authUser, follows: [...newUserFollows] };
    const updatedAuthor = { ...authorData, followers: [...newAuthorFollowers] };

    this.updateFollows(updatedUser, updatedAuthor, followerId);
  };

  doFollowOnAuthorPage = async followerId => {
    const { authUser, firebase } = this.props;

    const authorData = await firebase.getUser(followerId);

    const updatedUser = authUser.follows
      ? { ...authUser, follows: [...authUser.follows, followerId] }
      : { ...authUser, follows: [followerId] };

    let updatedAuthor;

    if (authorData) {
      updatedAuthor = authorData.followers
        ? { ...authorData, followers: [...authorData.followers, authUser.uid] }
        : { ...authorData, followers: [authUser.uid] };
    }
    const isFollows = true;
    this.updateFollows(updatedUser, updatedAuthor, followerId, isFollows);
  };

  makeNotification = (type, followerId) => {
    const { authUser, firebase } = this.props;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: authUser.avatar ? authUser : '',
        uid: authUser.uid,
        fullName:
          authUser.firstName && authUser.lastName
            ? `${authUser.firstName} ${authUser.lastName}`
            : authUser.username
      },
      hasRead: false
    };

    this.sendNotification(notification, followerId);
  };

  sendNotification = async (notification, followerId) => {
    const { authUser, firebase } = this.props;
    const followers = [followerId];

    const body = {
      followers,
      notification
    };

    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  render() {
    const { classes, authUser, location } = this.props;
    const { loading, followers, authorName } = this.state;
    let sortedFollowers = [];

    if (followers && !location.state) {
      const userFollowers = [...authUser.followers];
      userFollowers.reverse().forEach(followerId => {
        sortedFollowers.push({
          uid: followerId,
          ...followers[followerId]
        });
      });
    } else {
      sortedFollowers = !!followers && [...followers];
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            {!authorName && (
              <Typography className={classes.pageTitle} variant="h1">
                People following you
              </Typography>
            )}

            {authorName && (
              <Typography className={classes.pageTitle} variant="h1">
                People who follow {authorName}
              </Typography>
            )}
            {sortedFollowers.length === 0 && (
              <Typography
                className={classes.noFollowTitle}
                align="center"
                variant="body1">
                You don&apos;t have any followers yet
              </Typography>
            )}
            {sortedFollowers.length > 0 && (
              <Grid className={classes.followList} container>
                {sortedFollowers.map(
                  ({
                    uid,
                    avatar,
                    firstName,
                    lastName,
                    username,
                    memberStatus,
                    occupation,
                    description
                  }) => {
                    const fullName =
                      firstName && lastName
                        ? `${firstName} ${lastName}`
                        : username;

                    let status = memberStatus || '';
                    if (occupation) {
                      status =
                        status !== ''
                          ? `${status} | ${occupation}`
                          : `${occupation}`;
                    }

                    const isFollowed =
                      authUser.follows && authUser.follows.includes(uid);
                    const disabled = authUser.uid === uid;
                    return (
                      <Grid key={uid} item md={12} xs={12}>
                        <FollowerCard
                          userId={uid}
                          avatar={avatar}
                          fullName={fullName || ''}
                          status={status}
                          description={description}
                          isFollowed={isFollowed}
                          disabled={disabled}
                          onFollowClick={e => this.onFollowClick(e, uid)}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FollowersBase;
