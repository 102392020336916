import { withStyles } from '@material-ui/styles';
import { TabPanel, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';

export const StyledTabList = withStyles(theme => ({
  root: {
    minHeight: 32,
    color: theme.palette.text.secondary,
    opacity: 1
  },
  indicator: {
    backgroundColor: theme.palette.background.brand
  }
}))(TabList);

export const StyledTabPanel = withStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 0)
  }
}))(TabPanel);

export const StyledTab = withStyles(theme => ({
  root: {
    minHeight: 32,
    fontSize: 12,
    lineHeight: 1.3334,
    letterSpacing: 1.07,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.secondary
  },
  selected: {
    color: theme.palette.text.brand,
    backgroundColor: `${theme.palette.background.brand}1f` // #64A6B81f
  }
}))(Tab);
