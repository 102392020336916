import React, { useState } from 'react';
import {
  FormGroup,
  FormControl,
  Switch,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { styles } from './Switch.style';

const SwitchRoot = ({
  label,
  description,
  classes,
  child,
  handleChange,
  showDescription,
  type,
  disabled
}) => {
  const [state, setState] = useState(false);
  const onChange = () => {
    if (!disabled) {
      setState(!state);
      handleChange(type, !state);
    }
    return;
  };

  return (
    <FormControl
      component="fieldset"
      className={`${classes.fieldset} ${child && classes.child}`}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          disabled={disabled}
          value={type}
          className={`${classes.switchStyle} ${child && classes.mr}`}
          control={
            <Switch checked={state} onChange={onChange} color="primary" />
          }
          label={label}
          labelPlacement="start"
          onClick={onChange}
        />
      </FormGroup>
      <Typography className={classes.switchAdditional}>
        {showDescription && description}
      </Typography>
    </FormControl>
  );
};

export default withStyles(styles)(SwitchRoot);
