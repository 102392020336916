import { createSelector } from 'reselect';

const getSearch = state => state.searchState;

export const dataSelector = createSelector(getSearch, search => search.data);
export const valueSelector = createSelector(getSearch, search => search.value);
export const listSelector = createSelector(getSearch, search => search.list);
export const limitSelector = createSelector(getSearch, search => search.limit);

export const filterSelector = createSelector(
  getSearch,
  search => search.filter
);

export const resultSelector = createSelector(
  getSearch,
  search => search.result
);

export const selectedTagsSelector = createSelector(
  getSearch,
  search => search.selectedTags
);

export const exploreResultSelector = createSelector(
  getSearch,
  search => search.exploreResult
);

export const exploreResultFilterSelector = createSelector(
  exploreResultSelector,
  result => result.filter
);
