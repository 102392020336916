import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Avatar
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';

import { withFirebase } from '../../components/Firebase';
import SignOutPage from '../../components/SignOut';
import {
  LANDING,
  ARTICLES,
  PROFILE,
  SIGN_IN,
  SEARCH
} from '../../constants/routes';

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.topBar,
    zIndex: 1000,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  navBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0,
    fontSize: 22,
    color: theme.palette.text.brand
  },
  logo: {
    width: 111,
    height: 46
  },
  linkSpace: {
    marginRight: theme.spacing(2)
  },
  flexGrow: {
    flexGrow: 1
  },
  link: {
    color: '#fff',
    textDecoration: 'none'
  },
  articleLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  search: {
    display: 'none'
  },
  content: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  avatar: {
    width: 30,
    height: 30
  }
});

const INITIAL_STATE = {
  loading: false,
  categories: [],
  anchorEl: null,
  setAnchorEl: null
};
class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.onListenForCategories();
  }

  onListenForCategories = () => {
    this.unsubscribe = async function unsubscribe() {
      this.props.onSetCategories([]);
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onForCategory = category => {
    this.setState({ anchorEl: null });
    this.props.onSetArticlesCategory(category);
  };

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleBack = () => {
    // eslint-disable-next-line no-invalid-this
    this.props.history.goBack();
  };

  render() {
    const { categories, authUser, classes, location } = this.props;
    const { anchorEl } = this.state;

    const avatar =
      (authUser && authUser.avatar) || '/images/default-avatar.png';

    const isArticlesPage = location.pathname === ARTICLES; // || location.pathname === LANDING;

    const showNav =
      location.pathname !== '/signup' && location.pathname !== '/signin';

    return (
      <div>
        <AppBar className={classes.root} elevation={0}>
          <Toolbar className={classes.navBar}>
            {isArticlesPage && (
              <IconButton
                className={classes.button}
                component={RouterLink}
                to={!authUser ? SIGN_IN : PROFILE}>
                <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
              </IconButton>
            )}
            {!isArticlesPage && (
              <IconButton className={classes.button} onClick={this.handleBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <RouterLink
              className={classes.logo}
              to={LANDING}
              onClick={() => this.onForCategory(null)}>
              <img
                alt="Logo"
                src="/images/logos/zonotho-logo-green-320.png"
                sizes="320px"
                srcSet="/images/logos/zonotho-logo-green-320.png 320w,
                /images/logos/zonotho-logo-green-640.png 640w"
              />
            </RouterLink>

            {showNav && (
              <IconButton
                className={classes.button}
                component={RouterLink}
                to={SEARCH}>
                <SearchIcon />
              </IconButton>
            )}

            {!showNav && <IconButton className={classes.button}></IconButton>}

            <Hidden mdDown>
              <Menu
                id="categories"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}>
                <MenuItem onClick={this.handleClose} key="latest">
                  <RouterLink
                    className={classes.articleLink}
                    to={ARTICLES}
                    onClick={() => this.onForCategory(null)}>
                    LATEST
                  </RouterLink>
                </MenuItem>
                {categories.map(category => (
                  <MenuItem onClick={this.handleClose} key={category.id}>
                    <RouterLink
                      className={classes.articleLink}
                      to={ARTICLES}
                      onClick={() => this.onForCategory(category.name)}>
                      {category.name}
                    </RouterLink>
                  </MenuItem>
                ))}
              </Menu>
            </Hidden>
            <Hidden mdDown>
              {!!authUser && (
                <Button className={classes.linkSpace} color="inherit">
                  <RouterLink className={classes.link} to={PROFILE}>
                    Account
                  </RouterLink>
                </Button>
              )}
              {!!authUser && <SignOutPage />}
              {!authUser && (
                <Button color="inherit">
                  <RouterLink className={classes.link} to={SIGN_IN}>
                    Sign In
                  </RouterLink>
                </Button>
              )}
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  categories: Object.keys(state.categoryState.categories || {}).map(key => ({
    ...state.categoryState.categories[key]
  }))
});

const mapDispatchToProps = dispatch => ({
  onSetArticlesCategory: category =>
    dispatch({ type: 'ARTICLES_CATEGORY_SET', category }),
  onSetCategories: categories =>
    dispatch({ type: 'CATEGORIES_SET', categories })
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(Topbar));
