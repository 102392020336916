export const styles = theme => ({
  switchStyle: {
    marginLeft: '0px',
    '& .MuiTypography-body1': {
      fontSize: 12,
      color: '#00000099',
      letterSpacing: '1.88px',
      fontWeight: 'bold'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#008265'
    }
  },
  fieldset: {
    padding: theme.spacing(0, 2)
  },
  switchAdditional: {
    fontSize: 9,
    lineHeight: 2,
    color: theme.palette.text.secondary,
    opacity: 0.8,
    '& a': {
      color: theme.palette.text.brand
    },
    bottom: '10px',
    position: 'relative'
  },
  child: {
    position: 'relative',
    top: '-12px'
  },
  mr: {
    '& .MuiTypography-body1': {
      minWidth: '144px'
    }
  }
});

export default styles;
