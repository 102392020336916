import { createSelector } from 'reselect';

const getAuthUser = state => state.sessionState.authUser;

export const authUserSelector = createSelector(
  getAuthUser,
  authUser => authUser
);

export default {
  authUserSelector
};
