import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const styles = theme => ({
  root: {
    padding: theme.spacing(10, 4)
  },
  invite: {
    maxWidth: 204,
    marginBottom: theme.spacing(4),
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 300,
    color: '#ADAEAF'
  },
  presentation: {
    marginBottom: theme.spacing(5),
    fontSize: 30,
    lineHeight: '40px'
  },
  question: {
    fontSize: 30,
    lineHeight: '40px'
  },
  fieldsContainer: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 1)
  },
  field: {}
});

export const StyledTextField = withStyles(theme => ({
  root: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    '& .MuiFormLabel-root': {
      left: theme.spacing(2),
      textTransform: 'uppercase',
      opacity: 0.5
    },
    '& .MuiFormLabel-root.Mui-focused': {
      opacity: 1
    },
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    '& .MuiInput-underline:before': {
      borderWidth: 2,
      borderColor: theme.palette.primary.main
    },
    '& .MuiInput-underline:after': {
      borderColor: theme.palette.primary.main
    }
  }
}))(TextField);

export default {
  styles,
  StyledTextField
};
