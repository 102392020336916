import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import OnboardingBase from './OnboardingBase';
import { styles } from './OnboardingBase.style';

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

const OnboardingPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(withStyles(styles)(OnboardingBase));

export default OnboardingPage;
