/* eslint-disable */
import React, { useState } from 'react';
import { Grid, Typography, List, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Comment } from './comment';
import { styles, ActionButton } from './Comments.style';
import 'antd/dist/antd.css';
import { formatDate } from '../../../../common/Helpers';
// eslint-disable-next-line
import { uniqBy } from 'lodash';
import MentionsComponent from '../Mentions/Mentions';

const Comments = ({
  classes,
  comments,
  currentUserId,
  isAuthUser,
  isAdmin,
  makeNewComment,
  editComment,
  deleteComment,
  onLikeClick,
  showSighInDialog,
  allMention,
  allTags,
  scrollTo,
  autoFocusTextArea
}) => {
  const [value, setNewValue] = useState('');
  const [select, setSelect] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [editResponse, setEditResponse] = useState('');
  const [commentId, setСommentID] = useState(null);

  const commentsAmount = comments.length;

  comments.sort((a, b) => {
    return formatDate(a.date) - formatDate(b.date);
  });

  const onCancelClick = () => {
    setSelect([]);
    setNewValue('');
    setEditResponse('');
    setClearAll(true);
  };

  const onPostClick = comment => {
    let userForNotification = [];

    select.map(el => {
      if (el.dataType && el.dataType === 'mentions') {
        userForNotification.push(el.id);
      }
    });
    userForNotification = uniqBy(userForNotification);
    let allData;
    if (!editResponse) {
      allData = {
        comment,
        userForNotification,
        valueForEdit: value
      };
      makeNewComment(allData);
    } else {
      allData = {
        comment,
        userForNotification,
        valueForEdit: value,
        commentId
      };
      editComment(allData);
    }

    setSelect([]);
    setNewValue('');
    setEditResponse('');
    setClearAll(true);
  };

  const onUpdateClick = (commentId, updatedComment) => {
    editComment(commentId, updatedComment);
  };

  const onDeleteClick = commentId => {
    deleteComment(commentId);
  };

  const prePostClick = () => {
    let arr = [];
    const qq = value;
    let newDIV = qq.split('\n');

    newDIV.map((e, i) => {
      let dom = document.createElement('div');
      dom.className = 'text-wrap';

      let newchildElem = e.split(' ');

      newchildElem.map(el => {
        let selectedElem = false;
        let mentionWithSymbol = false;

        if (el.match(/([@][\w_-]+)/g)) {
          el.slice(5).match(/\w/g)
            ? (mentionWithSymbol = el.slice(5).replace(/\w/g, ''))
            : '';
          mentionWithSymbol
            ? (selectedElem = allMention.find(
                sel =>
                  '@' + sel.value.replace(/\s/g, '') + mentionWithSymbol === el
              ))
            : (selectedElem = allMention.find(sel => '@' + sel.value === el));
        }

        if (selectedElem && !mentionWithSymbol) {
          let domA = document.createElement('a');
          domA.innerHTML = selectedElem.name + ' ';
          domA.setAttribute('href', `/author/${selectedElem.id}`);
          domA.className = 'link-style';
          dom.appendChild(domA);
        } else if (selectedElem && mentionWithSymbol) {
          let domA = document.createElement('a');
          domA.innerHTML = selectedElem.name;
          domA.setAttribute('href', `/author/${selectedElem.id}`);
          dom.appendChild(domA);

          let domP = document.createElement('p');
          domP.innerHTML = mentionWithSymbol;
          domP.className = 'text-p-style';
          dom.appendChild(domP);
        } else if (el.match(/([#][\w_-]+)/g)) {
          let domA = document.createElement('a');
          domA.innerHTML = el + ' ';
          domA.className = 'link-style';
          dom.appendChild(domA);
        } else if (
          el.match(
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
          )
        ) {
          let domA = document.createElement('a');

          if (el.substr(0, 1) === 'h') {
            domA.setAttribute('href', `${el}`);
          } else if (el.substr(0, 3) === 'www') {
            domA.setAttribute('href', `http://${el}`);
          } else {
            domA.setAttribute('href', `http://www.${el}`);
          }
          domA.setAttribute('target', `_blank`);
          domA.innerHTML = el + ' ';

          domA.className = 'link-style';
          dom.appendChild(domA);
        } else {
          let domP = document.createElement('p');
          domP.innerHTML = el + ' ';
          domP.className = 'text-p-style';
          dom.appendChild(domP);
        }
      });

      arr.push(dom.outerHTML);
    });

    onPostClick(arr.join(''));
  };

  const onSelect = e => {
    setSelect(oldArray => [
      ...oldArray,
      {
        value: e.value,
        id: e.id,
        name: e.dataName,
        dataType: e.dataType
      }
    ]);
  };

  const onClickMention = () => {
    if (!isAuthUser) {
      showSighInDialog('You need to signed in to add response');
      return;
    }
  };

  const onEditResponse = (value, id) => {
    setEditResponse(value);
    setСommentID(id);
  };

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item>
        <Typography component="h3" className={classes.title}>
          Responses
        </Typography>
        <Typography component="h4" className={classes.subtitle}>
          {`${commentsAmount} responses`}
        </Typography>
      </Grid>

      <Grid item>
        {commentsAmount === 0 && (
          <Typography className={classes.emptyCommentMessage} align="center">
            There are no responses yet. Be the first!
          </Typography>
        )}
        {commentsAmount > 0 && (
          <List>
            {comments.map(comment => {
              const {
                uid,
                user,
                content,
                likes,
                date,
                isEdited,
                valueForEdit
              } = comment;
              const avatar = user.avatar;
              const fullName =
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.username;
              const likeValue = likes ? likes.length : 0;
              const userId = user.uid !== currentUserId ? user.uid : null;
              const isCommentOwner = user.uid === currentUserId;
              const isLiked = likes.includes(currentUserId);

              const htmlContent = content;

              return (
                <Comment
                  key={uid}
                  id={uid}
                  userId={userId}
                  avatar={avatar}
                  fullName={fullName}
                  comment={content}
                  htmlContent={htmlContent}
                  date={formatDate(date)}
                  likeValue={likeValue}
                  isEdited={isEdited || false}
                  isEditable={isCommentOwner}
                  isLikeble={!isCommentOwner}
                  isLiked={isLiked}
                  scrollTo={scrollTo}
                  valueForEdit={valueForEdit}
                  isAdmin={isAdmin}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                  onLikeClick={onLikeClick}
                  onEditResponse={onEditResponse}
                />
              );
            })}
          </List>
        )}
      </Grid>

      <Grid container item spacing={1}>
        <Grid onClick={onClickMention} id="comment-text-area" item xs={12}>
          <MentionsComponent
            autoFocusTextArea={autoFocusTextArea}
            allMention={allMention}
            allTags={allTags}
            isAuthUser
            onSelect={onSelect}
            setNewValue={setNewValue}
            setClearAll={setClearAll}
            clearAll={clearAll}
            editResponse={editResponse}
          />
        </Grid>

        <Grid container item spacing={1} justify="flex-end">
          <Grid item>
            <ActionButton
              className={classes.cancelBtn}
              variant="contained"
              disabled={!value && !editResponse}
              onClick={onCancelClick}>
              Cancel
            </ActionButton>
          </Grid>
          <Grid item>
            <ActionButton
              className={classes.postBtn}
              variant="contained"
              disabled={!value}
              onClick={prePostClick}>
              {(!editResponse && 'Post') || 'Edit'}
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Comments);
