/* eslint-disable import/no-namespace */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import PeopleAltSharpIcon from '@material-ui/icons/PeopleAltSharp';
import SettingsBooksSharpIcon from '@material-ui/icons/SettingsSharp';

import { Profile, SidebarNav } from './components';

import { withFirebase } from '../../../../components/Firebase';
import { ADMIN } from '../../../../constants/roles';
import * as ROUTES from '../../../../constants/routes';

const styles = theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
});

const INITIAL_STATE = {
  loading: false,
  categories: []
};

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.onListenForCategories();
  }

  onListenForCategories = () => {
    this.unsubscribe = async function unsubscribe() {
      this.props.onSetCategories([]);

      // const categories = await this.props.firebase.getCategories();

      // if (categories.length > 0) {
      //   this.props.onSetCategories(categories);
      // } else {
      //   this.props.onSetCategories([]);
      // }
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onForCategory = category => {
    this.props.onSetArticlesCategory(category);
    this.props.onClose();
  };

  render() {
    const {
      categories,
      open,
      variant,
      onClose,
      authUser,
      classes
    } = this.props;

    const pages = [];

    if (authUser) {
      pages.push({
        title: 'Account',
        href: ROUTES.ACCOUNT,
        icon: <PersonSharpIcon />
      });
    }

    if (authUser && !!authUser.activeSignInMethods.includes('password')) {
      pages.push({
        title: 'Settings',
        href: ROUTES.SETTINGS,
        icon: <SettingsBooksSharpIcon />
      });
    }

    if (authUser && !!authUser.role === ADMIN) {
      pages.push({
        title: 'Admin',
        href: ROUTES.ADMIN,
        icon: <PeopleAltSharpIcon />
      });
    }

    const mobilePages = [];

    return (
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}>
        <div className={classes.root}>
          {!!authUser && (
            <div>
              <Profile />
              <Divider className={classes.divider} />
            </div>
          )}

          <SidebarNav
            className={classes.nav}
            pages={pages}
            mobilePages={mobilePages}
            categories={categories}
            authUser={authUser}
            onClose={onClose}
            onCategoryClose={this.onForCategory}
          />
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  categories: Object.keys(state.categoryState.categories || {}).map(key => ({
    ...state.categoryState.categories[key]
  }))
});

const mapDispatchToProps = dispatch => ({
  onSetArticlesCategory: category =>
    dispatch({ type: 'ARTICLES_CATEGORY_SET', category }),
  onSetCategories: categories =>
    dispatch({ type: 'CATEGORIES_SET', categories })
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(Sidebar));
