export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  pageTitle: {
    fontWeight: 400,
    letterSpacing: 0
  },
  tagsContainer: {
    padding: theme.spacing(0, 0.5)
  },
  tag: {
    margin: theme.spacing(0.5),
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 0.25,
    color: '#6100ED',
    backgroundColor: '#D6C4F9'
  },
  waiting: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default styles;
