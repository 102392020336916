import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import { ArticleTypeFilterCard } from '../../../common';
import ArticleList from '../../../Articles/ArticleList';
import FollowerCard from '../../../Follow/FollowerCard';

import { StyledTabList, StyledTab, StyledTabPanel } from './Result.style';

const Result = ({
  authUser,
  resultList,
  list,
  filter,
  savedArticlesIds,
  loading,
  switchList,
  switchFilter,
  onLikeClick,
  onSaveClick,
  onFollowClick
}) => {
  return (
    <TabContext value={list}>
      <StyledTabList
        variant="fullWidth"
        aria-label="switch current result list"
        onChange={switchList}>
        <StyledTab label="Posts" value="posts" />
        <StyledTab label="People" value="people" />
      </StyledTabList>
      <StyledTabPanel value="posts">
        <Grid container direction="column">
          <Grid item>
            <ArticleTypeFilterCard
              value={filter}
              isDisabled={loading}
              onChange={switchFilter}
            />
          </Grid>
          {!loading && list === 'posts' && resultList.length > 0 && (
            <Grid item>
              <ArticleList
                userId={authUser ? authUser.uid : null}
                articles={resultList}
                savedArticlesIds={savedArticlesIds}
                onLikeClick={onLikeClick}
                onSaveClick={onSaveClick}
              />
            </Grid>
          )}
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value="people">
        {!loading && list === 'people' && resultList.length > 0 && (
          <Grid container direction="column">
            {resultList.map(
              ({
                uid,
                avatar,
                firstName,
                lastName,
                username,
                memberStatus,
                occupation,
                description
              }) => {
                const fullName =
                  firstName && lastName ? `${firstName} ${lastName}` : username;

                let status = memberStatus || '';
                if (occupation) {
                  status =
                    status !== ''
                      ? `${status} | ${occupation}`
                      : `${occupation}`;
                }

                const isFollowed = authUser
                  ? authUser.follows && authUser.follows.includes(uid)
                  : false;

                return (
                  <Grid key={uid} item lg={4} md={12} xs={12}>
                    <FollowerCard
                      userId={uid}
                      avatar={avatar}
                      fullName={fullName}
                      status={status}
                      description={description}
                      isFollowed={isFollowed}
                      onFollowClick={e => onFollowClick(e, uid)}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        )}
      </StyledTabPanel>
    </TabContext>
  );
};

Result.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  resultList: PropTypes.arrayOf(PropTypes.object),
  list: PropTypes.string,
  filter: PropTypes.string,
  savedArticlesIds: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  switchList: PropTypes.func,
  switchFilter: PropTypes.func,
  onLikeClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onFollowClick: PropTypes.func
};

export default Result;
