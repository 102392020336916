import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Grid, IconButton, InputBase, Avatar } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { styles } from './NewPostFieldLink.style';
import { withStyles } from '@material-ui/styles';
import { NEW_POST, SIGN_IN } from '../../../../constants/routes';

const INPUT_ROWS = 2;

const NewPostFieldLink = ({ classes, authUser }) => {
  const [content, setContent] = useState('');

  const onChange = e => {
    setContent(e.target.value);
  };

  const avatar = (authUser && authUser.avatar) || '/images/default-avatar.png';

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
        </Grid>
        <Grid
          component={RouterLink}
          to={
            authUser
              ? {
                  pathname: NEW_POST,
                  state: {
                    contentFromArticles: true,
                    contentType: 'thought',
                    content
                  }
                }
              : SIGN_IN
          }
          item
          xs>
          <InputBase
            className={classes.input}
            value={content}
            fullWidth={true}
            multiline={true}
            rows={INPUT_ROWS}
            placeholder="Add a thought, ask a question, share a pic or video"
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <IconButton
            className={classes.addBtn}
            component={RouterLink}
            to={
              authUser
                ? {
                    pathname: NEW_POST,
                    state: {
                      contentFromArticles: true,
                      contentType: 'thought',
                      content
                    }
                  }
                : SIGN_IN
            }>
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(NewPostFieldLink);
