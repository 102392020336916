export const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0)
  },
  avatar: {
    width: 40,
    height: 40
  },
  status: {
    fontSize: 14,
    lineHeight: 1.3572
  },
  followBtn: {
    minWidth: 78,
    padding: '3px 3px 2px',
    fontSize: 11,
    lineHeight: '15px',
    letterSpacing: 0.98,
    color: theme.palette.text.brand,
    borderColor: theme.palette.text.brand,
    '&.followed': {
      color: '#ffffff',
      backgroundColor: '#8E6C88',
      borderColor: '#8E6C88'
    }
  },
  description: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px'
  }
});

export default styles;
