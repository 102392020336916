import React from 'react';
import { ReactComponent as Icon } from './open-text-page.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

const svgStyle = {
  fontSize: 20,
  marginBottom: '4px'
};

const OpenTextPage = () => (
  <SvgIcon style={svgStyle}>
    <Icon />
  </SvgIcon>
);

export default OpenTextPage;
