import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { Grid, Box, CardMedia, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { styles } from './Media.style';

const Media = ({ classes, imgSrc, readingTime, tags }) => {
  return (
    <div className={classes.mediaContainer}>
      <CardMedia>
        <img
          alt="Product"
          className={classes.image}
          src={imgSrc}
          width="288"
          height="180"
        />
      </CardMedia>
      <Box className={classes.mediaTopContainer}>
        <Grid container spacing={1} direction="row-reverse">
          {readingTime && (
            <Grid item>
              <Typography className={classes.tagItem} component="span">
                <AccessTimeIcon className={classes.readingTimeIcon} />
                <Typography
                  className={classes.readingTimeValue}
                  component="span">
                  {readingTime}
                </Typography>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className={classes.mediaBottomContainer}>
        <Grid
          container
          spacing={1}
          wrap="nowrap"
          alignItems="flex-end"
          justify="space-between">
          {tags.length > 0 && (
            <Grid item>
              <Grid
                className={classes.tagList}
                container
                component="ul"
                spacing={1}>
                {tags.map(tag => (
                  <Grid item component="li" key={tag}>
                    <Typography className={classes.tagItem} component="span">
                      {tag}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

Media.propTypes = {
  classes: PropTypes.object.isRequired,
  imgSrc: PropTypes.string.isRequired,
  readingTime: PropTypes.string,
  tags: PropTypes.array
};

Media.defaultProps = {
  tags: []
};

export default withStyles(styles)(Media);
