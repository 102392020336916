export const DEFAULT = { name: 'DEFAULT', begin: '65a6b8', end: 'b0ecfd' };
export const COLOURS = [
  {
    name: 'BLUE',
    begin: 'B2E3EF',
    end: 'C8E6ED'
  },
  {
    name: 'GREEN',
    begin: 'A9DEBC',
    end: 'C6FFDB'
  },
  {
    name: 'RED',
    begin: 'EA6464',
    end: 'E6B5C3'
  },
  {
    name: 'PURPLE',
    begin: '94A5EA',
    end: 'C2C9E8'
  },
  {
    name: 'ORANGE',
    begin: 'F7AA87',
    end: 'ECD6BA'
  }
];
