import { withStyles } from '@material-ui/styles';
import { Radio } from '@material-ui/core';

export const styles = theme => ({
  filter: {
    padding: theme.spacing(1.75)
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  filterLabel: {
    maxWidth: 55,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.6667,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    letterSpacing: 1.88,
    '&.Mui-focused': {
      color: theme.palette.text.secondary
    }
  },
  filterList: {
    display: 'flex',
    flexDirection: 'row'
  },
  filterItem: {
    margin: 0,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2.5)
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
      lineHeight: 1.3334,
      color: theme.palette.text.secondary,
      letterSpacing: 0.4
    }
  }
});

export const StyledRadio = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: 20,
    '&.MuiIconButton-colorSecondary.Mui-checked': {
      color: '#008265'
    }
  }
}))(Radio);

export default styles;
