import React from 'react';
import Moment from 'react-moment';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Grid,
  Avatar,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { styles } from './Notification.style';
import { AUTHOR, ARTICLES } from '../../../constants/routes';

import { formatDate } from '../../../common/Helpers';

const NOTIFICATION_TYPE_MESSAGE = {
  response: 'a new response to the post',
  post: 'a new post',
  mention: 'mentioned you in a response to the post',
  postMention: 'mentioned you in a '
};

const Notification = ({
  classes,
  type,
  user,
  post,
  date,
  hasRead,
  anonimus,
  uid,
  readNotification,
  navigateToPost
}) => {
  return (
    <Card
      className={clsx({
        [classes.root]: true,
        colorCard: !hasRead
      })}>
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.wrapHead}>
          <Moment className={classes.period} fromNow>
            {formatDate(date)}
          </Moment>
        </Grid>
        <Grid item container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            {user && (
              <Avatar
                className={classes.avatar}
                src={user.avatar}
                alt={user.fullName}
              />
            )}
          </Grid>
          <Grid item>
            {!anonimus &&
              type !== 'mention' &&
              type !== 'postApproved' &&
              type !== 'newFollower' &&
              type !== 'postMention' && (
                <Typography className={classes.message}>
                  <RouterLink to={`${AUTHOR}/${user.uid}`}>
                    {user.fullName}
                  </RouterLink>
                  {` added ${NOTIFICATION_TYPE_MESSAGE[type]}: `}
                  <RouterLink onClick={() => navigateToPost(post.uid, uid)}>
                    {post.title}
                  </RouterLink>
                </Typography>
              )}
            {type === 'mention' && (
              <Typography className={classes.message}>
                <RouterLink to={`${AUTHOR}/${user.uid}`}>
                  {user.fullName}
                </RouterLink>
                {`  ${NOTIFICATION_TYPE_MESSAGE[type]} `}
                <RouterLink onClick={() => navigateToPost(post.uid, uid)}>
                  {post.title}
                </RouterLink>
              </Typography>
            )}
            {type === 'postApproved' && (
              <Typography className={classes.message}>
                {`Your post `}

                <RouterLink onClick={() => navigateToPost(post.uid, uid)}>
                  {post.title}
                </RouterLink>

                {`  has been ${post.postStatus}`}
              </Typography>
            )}
            {type === 'postMention' && (
              <Typography className={classes.message}>
                <RouterLink to={`${AUTHOR}/${user.uid}`}>
                  {user.fullName}
                </RouterLink>
                {`  ${NOTIFICATION_TYPE_MESSAGE[type]} `}
                <RouterLink onClick={() => navigateToPost(post.uid, uid)}>
                  {post.title}
                </RouterLink>
              </Typography>
            )}
            {anonimus && (
              <Typography className={classes.message}>
                {'User  '}
                <RouterLink to={`${AUTHOR}/${user.uid}`}>
                  {user.fullName}
                </RouterLink>
                {'  has submitted an anonymous post. '}
                <RouterLink to={`${ARTICLES}/${post.uid}`}>
                  {'Click here'}
                </RouterLink>
                {'  to review it.'}
              </Typography>
            )}
            {type === 'newFollower' && (
              <Typography className={classes.message}>
                {'User  '}
                <RouterLink to={`${AUTHOR}/${user.uid}`}>
                  {user.fullName}
                </RouterLink>
                {'  is now following you '}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Tooltip placement="top" title="Mark as read" arrow>
              <IconButton
                onClick={() => readNotification(uid)}
                aria-label="delete">
                <CheckCircleOutlineIcon fontSize={'small'} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styles)(Notification);
