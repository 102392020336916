import { createSelector } from 'reselect';

const getArticlesState = state => state.articleState;

export const allArticlesSelector = createSelector(
  getArticlesState,
  state => state.articles || []
);

export const limitSelector = createSelector(
  getArticlesState,
  state => state.limit
);

export const categorySelector = createSelector(
  getArticlesState,
  state => state.category
);

export default {
  allArticlesSelector,
  limitSelector,
  categorySelector
};
