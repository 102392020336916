import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import { StyledRadio } from './ArticleTypeFilterCard.style';

const FilterCardBase = ({ classes, value, isDisabled, onChange }) => {
  return (
    <Card className={classes.filter}>
      <FormControl className={classes.filterContainer}>
        <FormLabel className={classes.filterLabel}>Filter by</FormLabel>
        <RadioGroup
          className={classes.filterList}
          aria-label="Filter by"
          name="type"
          value={value}
          onChange={onChange}>
          <FormControlLabel
            className={classes.filterItem}
            disabled={isDisabled}
            value="all"
            control={<StyledRadio disableRipple />}
            label="ALL"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className={classes.filterItem}
            disabled={isDisabled}
            value="question"
            control={<StyledRadio disableRipple />}
            label="Q&amp;A"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className={classes.filterItem}
            disabled={isDisabled}
            value="article"
            control={<StyledRadio disableRipple />}
            label="Article"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className={classes.filterItem}
            disabled={isDisabled}
            value="video"
            control={<StyledRadio disableRipple />}
            label="Video"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className={classes.filterItem}
            disabled={isDisabled}
            value="thought"
            control={<StyledRadio disableRipple />}
            label="Thought"
            labelPlacement="bottom"
          />
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

FilterCardBase.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOf(['all', 'question', 'article', 'video', 'thought']),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

FilterCardBase.defaulfProps = {
  value: 'all',
  isDisabled: false
};

export default FilterCardBase;
