/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import { LANDING } from '../../constants/routes';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  iconContainer: {
    color: theme.palette.primary.main,
    width: '100%',
    height: 60,
    justifyContent: 'center'
  },
  icon: {
    width: 60,
    height: 60
  },
  innerText: {
    textAlign: 'center',
    justifyContent: 'center'
  },
  emailLink: {
    color: theme.palette.primary.main
  }
});

const VerifyEmailPage = () => <VerifyEmail />;

const INITIAL_STATE = {
  userEmail: ''
};

class VerifyEmailBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  render() {
    const { classes } = this.props;
    const state = this.props.location.state;

    let userEmail = '';
    if (state && state.userEmail && state.userEmail !== '') {
      userEmail = state.userEmail;
    } else {
      this.props.history.push(LANDING);
    }

    return (
      <div className={classes.root}>
        {!!userEmail && (
          <div>
            <Grid container spacing={3} className={classes.innerText}>
              <Grid item lg={12} md={12} sx={12}>
                <div className={classes.iconContainer}>
                  <AlternateEmailIcon className={classes.icon} />
                </div>
              </Grid>

              <Grid item lg={12} md={12} sx={12}>
                <Typography variant="h2">
                  Verify your email to access your account on Zonotho
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sx={12}>
                <Typography color="textSecondary" gutterBottom>
                  We've sent a link to{' '}
                  <span className={classes.emailLink}>{userEmail}</span> for you
                  to use to verify your email address. If you don't see the
                  email, be sure to check your spam folder. If all else fails,
                  please get in contact with us.
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const VerifyEmail = compose(withRouter)(withStyles(styles)(VerifyEmailBase));

export default VerifyEmailPage;

export { VerifyEmail };
