/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Mentions } from 'antd';

import { styles } from './Mentions.style';
import 'antd/dist/antd.css';

const mentionStyle = {
  width: '100%',
  outline: 'none',
  boxShadow: 'none',
  border: 'none'
};
const MentionsComponent = ({
  classes,
  isAuthUser,
  allMention,
  allTags,
  onSelect,
  setNewValue,
  setClearAll,
  clearAll,
  editResponse,
  autoFocusTextArea,
  postStyle,
  placeholder
}) => {
  const [value, setValue] = useState('');
  const [prefix, setPrefix] = useState('@');
  const [closePopup, setClosePopup] = useState(false);
  const [autoFocus, setAutoFocus] = useState(true);

  const { Option } = Mentions;

  useEffect(() => {
    if (autoFocusTextArea && autoFocus) {
      let textarea = document.getElementsByClassName('rc-textarea');
      textarea[0].focus();
      setAutoFocus(false);
    }
    if (!value && editResponse) {
      setValue(editResponse);
      let textarea = document.getElementsByClassName('rc-textarea');
      textarea[0].focus();
    }
    if (clearAll) {
      setClearAll(false);
      setValue('');
    }
  }, [clearAll, editResponse]);

  const onSearch = (text, prefix) => {
    let arrFound = [];
    allMention.map(el => {
      if (el.name.toUpperCase().indexOf(text.toUpperCase()) === -1) {
        arrFound.push(1);
      } else {
      }
    });

    arrFound.length === allMention.length && arrFound.every(el => el === 1)
      ? setClosePopup(true)
      : closePopup
      ? setClosePopup(false)
      : '';

    setPrefix(prefix);
  };

  const validateSearch = text => {
    return text.length <= 10;
  };

  const setPlaceholder = useMemo(
    () => (placeholder ? placeholder : 'Add response'),
    [placeholder]
  );
  return (
    <Mentions
      disabled={!isAuthUser}
      placeholder={setPlaceholder}
      placement="top"
      className={`${!postStyle && classes.textArea} ${postStyle &&
        classes.postStyle} ${closePopup && classes.close}`}
      prefix={['@', '#']}
      onSearch={onSearch}
      value={value}
      notFoundContent={false}
      validateSearch={validateSearch}
      onSelect={e => onSelect(e)}
      onChange={e => {
        setValue(e), setNewValue(e);
      }}
      autoSize
      style={mentionStyle}>
      {prefix === '@' &&
        allMention.map(el => (
          <Option
            className={classes.option}
            key={el.id}
            value={el.value.replace(/\s/g, '')}
            dataName={el.name}
            dataType="mentions"
            id={el.id}>
            {<Avatar src={el.avatar} className={classes.avatar} alt="avatar" />}
            {el.name}
          </Option>
        ))}
      {prefix === '#' &&
        allTags.map(el => (
          <Option
            className={classes.optionTag}
            key={el.id}
            value={el.name}
            dataName={el.name}
            id={el.id}>
            {el.name}
          </Option>
        ))}
    </Mentions>
  );
};

export default withStyles(styles)(MentionsComponent);
