import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    width: '100%',
    textAlign: 'center'
  }
}));

const Footer = () => {
  const classes = useStyles();
  return <div className={classes.root}></div>;
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
