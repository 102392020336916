import React from 'react';
import { ReactComponent as Icon } from './flame.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

const svgStyle = {
  fontSize: 22,
  marginBottom: '3px'
};

const Flame = () => (
  <SvgIcon style={svgStyle}>
    <Icon />
  </SvgIcon>
);

export default Flame;
