import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import { authUserSelector } from '../Session/selectors';
import {
  dataSelector,
  selectedTagsSelector,
  valueSelector,
  listSelector,
  filterSelector,
  limitSelector,
  resultSelector
} from './selectors';

import SearchBase from './Search';
import { styles } from './Search.style';

const mapStateToProps = state => ({
  authUser: authUserSelector(state),
  searchData: dataSelector(state),
  selectedTags: selectedTagsSelector(state),
  value: valueSelector(state),
  list: listSelector(state),
  filter: filterSelector(state),
  limit: limitSelector(state),
  result: resultSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message }),
  setDataUsers: users => dispatch({ type: 'USERS_DATA_SET', users }),
  setDataTags: tags => dispatch({ type: 'TAGS_DATA_SET', tags }),
  setDataArticles: articles =>
    dispatch({ type: 'ARTICLES_DATA_SET', articles }),
  setSelectedTags: tags => dispatch({ type: 'SELECTED_TAGS_SET', tags }),
  setValue: value => dispatch({ type: 'SEARCH_VALUE_SET', value }),
  setList: list => dispatch({ type: 'SELECTED_LIST_SET', list }),
  setFilter: filter => dispatch({ type: 'SELECTED_FILTER_SET', filter }),
  setLimit: limit => dispatch({ type: 'SET_LIMIT', limit }),
  setResult: result => dispatch({ type: 'RESULT_SET', result }),
  resetResult: () => dispatch({ type: 'RESET_RESULT' })
});

const SearchPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(SearchBase));

export default SearchPage;
