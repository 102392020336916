import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { styles, ActionButton } from './PopoverMessages.style';
import { withStyles } from '@material-ui/styles';

const DeleteMessageBase = ({
  classes,
  message,
  onDeleteClick,
  onCancelClick
}) => {
  return (
    <React.Fragment>
      <Typography className={classes.message}>{message}</Typography>
      <Box className={classes.actions}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <ActionButton
              className={classes.cancelBtn}
              variant="contained"
              onClick={onCancelClick}>
              Cancel
            </ActionButton>
          </Grid>
          <Grid item>
            <ActionButton
              className={classes.deleteBtn}
              variant="contained"
              onClick={onDeleteClick}>
              Delete
            </ActionButton>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default {
  delete: withStyles(styles)(DeleteMessageBase)
};
