export const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: '10px'
  },
  addBtn: {
    padding: 6,
    fontSize: 21,
    color: theme.palette.text.brand
  },
  input: {
    padding: theme.spacing(1, 1.5),
    overflow: 'hidden',
    fontSize: '13px',
    '& .MuiInputBase-input::placeholder': {
      color: '#666666',
      opacity: 1
    }
  },
  avatar: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1)
  }
});

export default styles;
