import React, { Component } from 'react';
import { nanoid } from 'nanoid';
import { Link as RouterLink } from 'react-router-dom';
import { getBase64Strings } from 'exif-rotate-js/lib';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  Button,
  LinearProgress,
  CardContent,
  CircularProgress,
  CardMedia,
  InputBase
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import { getStrippedString } from '../../common/Helpers';
import ReactPlayer from 'react-player/youtube';
import { ActionButton } from './NewPost.style';
import { SwitchRoot } from './components';
// eslint-disable-next-line
import { uniqBy } from 'lodash';
import { ARTICLES } from '../../constants/routes';
import { COLOURS } from '../../constants/articleColours';
import MentionsComponent from '../Article/components/Mentions/Mentions';

const DEFAULT_AVATAR = '/images/default-avatar.png';

const INITIAL_STATE = {
  loading: true,
  imageLoading: false,
  progress: 1,
  type: 'question',
  isValidate: false,
  isPublished: false,
  anonimus: false,
  publishedPostID: '',
  description: '',
  content: '',
  banner: '',
  url: '',
  loadURL: false,
  anonUser: null,
  allAdminList: null,
  allMention: null,
  allTags: null,
  select: [],
  valueTextArea: '',
  setClearAll: false
};
/* eslint-disable */
class NewPostBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { location } = this.props;
    // this.setState({
    //   loading: true
    // });
    this.getFollowers();
    this.getAnonUser();
    this.getTags();

    if (location.state && location.state.contentFromArticles) {
      const { contentType, content } = location.state;

      this.setState({
        // type: contentType,
        content: content
      });
    }
  }

  componentDidUpdate() {
    if (
      this.state.allMention !== null &&
      this.state.allTags !== null &&
      this.state.loading
    ) {
      this.setState({
        loading: false
      });
    }
  }

  getFollowers = async () => {
    const { authUser } = this.props;

    let allUserForMentions = [];

    if (authUser && authUser.followers && authUser.followers.length > 0) {
      allUserForMentions.push(...authUser.followers);
    }
    if (authUser && authUser.follows && authUser.follows.length > 0) {
      allUserForMentions.push(...authUser.follows);
    }

    allUserForMentions = uniqBy(allUserForMentions);

    const usersLength = allUserForMentions.length;
    let allData = [];
    for (let i = 0; i < usersLength; i += 10) {
      const requests = [];

      requests.push(
        this.getFollowers10(allUserForMentions.slice(i, i + 10)).catch(e =>
          console.log(e)
        )
      );

      await Promise.all(requests)
        .then(a => {
          allData = [...allData, ...a[0]];
        })
        .catch(e => console.log(e));
    }

    this.setState({ allMention: allData });
  };

  getFollowers10 = async arr => {
    const { firebase } = this.props;
    try {
      const following = {};
      const mention = [];
      const args = {
        filters: [
          {
            field: 'documentId',
            condition: 'in',
            value: arr
          }
        ]
      };
      const followersRefs = await firebase.getUsersWithArgs(args);

      followersRefs.forEach(s => {
        if (!s.firstName && !s.lastName && !s.username) {
          return;
        }
        following[s.uid] = s;
        mention.push({
          id: s.uid,
          link: s.uid,
          avatar: s.avatar ? s.avatar : DEFAULT_AVATAR,
          name:
            s.firstName && s.lastName
              ? `${s.firstName} ${s.lastName}`
              : s.username,
          value:
            s.firstName && s.lastName
              ? `${s.uid.substr(0, 3) +
                  '_' +
                  s.firstName.replace(' ', '') +
                  s.lastName.replace(' ', '')}`
              : s.uid.substr(0, 3) + '_' + s.username.replace(' ', '')
        });
      });

      return mention;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  getTags = async () => {
    const { firebase } = this.props;
    const args2 = {
      filters: [
        {
          field: 'showInterest',
          condition: '==',
          value: true
        }
      ]
    };
    const tagsCurrent = [];
    const tags = await firebase.getTagsWithArgs(args2);
    tags.forEach(s => {
      tagsCurrent.push({
        id: s.uid,
        name: s.name.replace(' ', '_')
      });
    });

    this.setState({ allTags: tagsCurrent });
  };

  async getAnonUser() {
    const { firebase } = this.props;
    let anonUserData = null;
    anonUserData = await firebase.getUser('anon');

    this.setState({
      anonUser: anonUserData
    });
  }

  async getAdmins() {
    const { firebase } = this.props;
    let followers = null;

    const args = {
      filters: [
        {
          field: 'role',
          condition: '==',
          value: 'ADMIN'
        }
      ]
    };

    followers = await firebase.getUsersWithArgs(args);
    const followersUID = [];
    followers.map(el => followersUID.push(el.uid));
    this.setState({ allAdminList: followersUID });
  }

  toValidate = date => {
    const keys = Object.keys(date);
    return keys.every(key => {
      if (key === 'banner') {
        return true;
      }

      return date[key];
    });
  };

  handleTypeChange = event => {
    const type = event.target.value;
    const isValidate = this.toValidate(this.state[type]);

    this.setState({
      type,
      isValidate
    });
  };

  handleInputURL = event => {
    this.setState({
      url: event.target.value
    });
  };

  handleImageChange = e => {
    const { files } = e.target;

    if (!files || files.length < 1) {
      return;
    }

    const image = files[0];
    if (
      !image.type.includes('jpeg') &&
      !image.type.includes('jpg') &&
      !image.type.includes('png') &&
      !image.type.includes('gif')
    ) {
      const error = { type: 'error', message: 'Only images are allowed' };
      this.props.showAlert(error);
      return;
    }

    this.setState(() => ({ imageLoading: true }));
    const { firebase } = this.props;
    const imageName = nanoid();

    getBase64Strings(files, { maxSize: 2048 })
      .then(result => {
        const uploadTask = firebase.storage
          .ref(`articles/${imageName}`)
          .putString(result[0], 'data_url', { contentType: image.type });

        uploadTask.on(
          'state_changed',
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            firebase.storage
              .ref('articles')
              .child(imageName)
              .getDownloadURL()
              .then(url => {
                this.setState({
                  banner: url,
                  imageLoading: false,
                  progress: 1
                });
              });
          }
        );
      })
      .catch(err => {
        const error = { type: 'error', message: err };
        this.props.showAlert(error);
        this.setState({
          imageLoading: false,
          progress: 1
        });
      });
  };

  handleCancelOnClick = () => {
    const { firebase } = this.props;
    const { banner } = this.state;
    if (banner) {
      firebase.storage
        .refFromURL(banner)
        .delete()
        .then()
        .catch(err => {
          console.error('error: ', err);
        });
    }

    this.setState({
      select: [],
      valueTextArea: '',
      content: '',
      url: '',
      loadURL: false,
      anonUser: null,
      setClearAll: true
    });
  };

  onSetClearAll = value => {
    this.setState({
      setClearAll: value
    });
  };

  handleRemoveOnClick = () => {
    const { firebase } = this.props;
    const { banner } = this.state;
    if (banner) {
      firebase.storage
        .refFromURL(banner)
        .delete()
        .then(() => {
          console.log('delete');
        })
        .catch(err => {
          console.error('error: ', err);
        });
    }

    this.setState({
      banner: ''
    });
  };

  handlePostOnClick = () => {
    this.setState({
      loading: true
    });
    this.prePostClick();
  };

  getColour = () => {
    const index = Math.floor(Math.random() * Math.floor(COLOURS.length));
    return COLOURS[index];
  };

  preparePost = (type, postContent) => {
    const { authUser, firebase } = this.props;
    const { anonimus, anonUser, select } = this.state;
    let post = null;

    let userMentionsForNotification = [];

    if (select) {
      select.map(el => {
        if (el.dataType && el.dataType === 'mentions') {
          userMentionsForNotification.push(el.id);
        }
      });
      userMentionsForNotification = uniqBy(userMentionsForNotification);
    }

    const contributor = {
      avatar: authUser.avatar || '',
      role: authUser.role || '',
      uid: authUser.uid
    };

    if (authUser.username) {
      contributor.username = authUser.username;
    }
    if (authUser.firstName && authUser.lastName) {
      contributor.firstName = authUser.firstName;
      contributor.lastName = authUser.lastName;
    }
    if (authUser.occupation) {
      contributor.occupation = authUser.occupation;
    }
    if (authUser.memberStatus) {
      contributor.memberStatus = authUser.memberStatus;
    }

    if (!anonimus) {
      post = {
        contributor,
        contributorRef: authUser.uid,
        created: firebase.timeStamp.fromDate(new Date()),
        status: 'published',
        likesCount: 0,
        type,
        valueForEdit: this.state.valueTextArea
      };
    } else {
      post = {
        contributor: anonUser,
        contributorRef: 'anon',
        created: firebase.timeStamp.fromDate(new Date()),
        status: 'in review',
        likesCount: 0,
        type,
        anonymous: anonimus,
        originalContributor: authUser.uid,
        valueForEdit: this.state.valueTextArea
      };
    }

    if (type === 'question' || type === 'thought') {
      post.content = postContent;
      if (this.state.banner) {
        post.banner = this.state.banner;
      }
    }

    if (type === 'video') {
      post.description = postContent;
      post.content = this.state.url;
    }

    post.colours = this.getColour();

    this.toPublishPost(post, userMentionsForNotification);
  };

  toPublishPost = async (post, userMentionsForNotification) => {
    const { authUser } = this.props;
    const { allAdminList, anonimus } = this.state;

    try {
      const result = await this.props.firebase.addPost(post);

      if (
        (authUser.followers && authUser.followers.length) > 0 ||
        (allAdminList && allAdminList.length > 0)
      ) {
        const followers = !anonimus ? [...authUser.followers] : allAdminList;

        const uniqList = followers.filter(
          follower => !userMentionsForNotification.includes(follower)
        );

        this.makeNotification(result.id, [], uniqList);
      }
      if (userMentionsForNotification) {
        this.makeNotification(result.id, userMentionsForNotification);
      }
      this.setState({
        loading: false,
        isPublished: true,
        publishedPostID: result.id,
        select: [],
        valueTextArea: '',
        content: ''
      });
    } catch (error) {
      console.log('toPublishPost error :>> ', error);
    }
  };

  makeNotification = (postId, userMentionsForNotification, mentionList) => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;
    const { description, anonimus } = this.state;
    const { avatar, firstName, lastName, username } = authUser;
    const fullName =
      firstName && lastName ? `${firstName} ${lastName}` : username;
    const title = description
      ? description
      : mentionList
      ? 'See here'
      : 'New Post';
    const type = mentionList ? 'post' : 'postMention';
    const notification = {
      type: type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: fullName
      },
      post: {
        uid: postId,
        title: !anonimus ? getStrippedString(title, MAX_CHARACTERS) : ''
      },
      hasRead: false,
      anonimus: anonimus
    };

    this.sendNotification(
      notification,
      userMentionsForNotification,
      mentionList
    );
  };

  sendNotification = async (
    notification,
    userMentionsForNotification,
    mentionList
  ) => {
    const { authUser, firebase } = this.props;
    const { anonimus, allAdminList } = this.state;

    let followers = [];
    if (mentionList && mentionList.length > 0) {
      followers = mentionList;
    } else if (
      userMentionsForNotification &&
      userMentionsForNotification.length > 0
    ) {
      followers = userMentionsForNotification;
    }

    if (followers && followers.length > 0) {
      const body = {
        followers,
        notification
      };

      firebase
        .saveNotifications(authUser.uid, body)
        .then(() => {
          console.log('Notifications send');
        })
        .catch(err => {
          console.error('sendNotification error :>> ', err);
        });
    }
  };

  handleChangeTextArea = item => {
    this.setState({
      content: item.target.value
    });
  };

  handeSwitchClick = (type, selected) => {
    this.setState({ type: selected ? type : 'question' });
  };

  handeSwitchAnonimusClick = (type, selected) => {
    this.setState({ anonimus: selected });
    if (!this.state.allAdminList) {
      this.getAdmins();
    }
  };

  handleClickTypeVideo = () => {
    this.setState({ type: 'video' });
  };

  onClickCancelVideo = () => {
    this.setState({ type: 'question' });
    this.setState({ url: '' });
  };

  onClickAddVideo = () => {
    this.setState({ loadURL: true });
  };
  onClickRemoveVideo = () => {
    this.setState({ loadURL: false });
    this.setState({ url: '' });
    this.setState({ type: 'question' });
  };

  onSelect = e => {
    this.setState({
      select: [
        ...this.state.select,
        ...[
          { value: e.value, id: e.id, name: e.dataName, dataType: e.dataType }
        ]
      ]
    });
  };

  setNewValue = e => {
    this.setState({ valueTextArea: e });
  };

  prePostClick = () => {
    const { valueTextArea, allMention } = this.state;
    let arr = [];
    const qq = valueTextArea;
    let newDIV = qq.split('\n');

    newDIV.map((e, i) => {
      let dom = document.createElement('div');
      dom.className = 'text-wrap';

      let newchildElem = e.split(' ');

      newchildElem.map(el => {
        let selectedElem = false;
        let mentionWithSymbol = false;

        if (el.match(/([@][\w_-]+)/g)) {
          el.slice(5).match(/\w/g)
            ? (mentionWithSymbol = el.slice(5).replace(/\w/g, ''))
            : '';

          mentionWithSymbol
            ? (selectedElem = allMention.find(
                sel =>
                  '@' + sel.value.replace(/\s/g, '') + mentionWithSymbol === el
              ))
            : (selectedElem = allMention.find(sel => '@' + sel.value === el));
        }

        if (selectedElem && !mentionWithSymbol) {
          let domA = document.createElement('a');
          domA.innerHTML = selectedElem.name + ' ';
          domA.setAttribute('href', `/author/${selectedElem.id}`);
          domA.className = 'link-style';
          dom.appendChild(domA);
        } else if (selectedElem && mentionWithSymbol) {
          let domA = document.createElement('a');
          domA.innerHTML = selectedElem.name;
          domA.setAttribute('href', `/author/${selectedElem.id}`);
          dom.appendChild(domA);

          let domP = document.createElement('p');
          domP.innerHTML = mentionWithSymbol;
          domP.className = 'text-p-style';
          dom.appendChild(domP);
        } else if (el.match(/([#][\w_-]+)/g)) {
          let domA = document.createElement('a');
          domA.innerHTML = el + ' ';
          domA.className = 'link-style';
          dom.appendChild(domA);
        } else if (
          el.match(
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
          )
        ) {
          let domA = document.createElement('a');

          if (el.substr(0, 1) === 'h') {
            domA.setAttribute('href', `${el}`);
          } else if (el.substr(0, 3) === 'www') {
            domA.setAttribute('href', `http://${el}`);
          } else {
            domA.setAttribute('href', `http://www.${el}`);
          }
          domA.setAttribute('target', `_blank`);
          domA.innerHTML = el + ' ';

          domA.className = 'link-style';
          dom.appendChild(domA);
        } else {
          let domP = document.createElement('p');
          domP.innerHTML = el + ' ';
          domP.className = 'text-p-style';
          dom.appendChild(domP);
        }
      });

      arr.push(dom.outerHTML);
    });

    this.preparePost(this.state.type, arr.join(''));
  };

  render() {
    const { classes } = this.props;

    const {
      type,
      loading,
      imageLoading,
      isPublished,
      publishedPostID,
      anonimus,
      progress,
      banner,
      url,
      loadURL,
      allMention,
      allTags,
      valueTextArea,
      setClearAll
    } = this.state;

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}

        {!loading && (
          <div className={classes.root}>
            {isPublished && (
              <Card className={classes.successMessage}>
                <CardContent className={classes.successMessageContent}>
                  {!anonimus && (
                    <Typography className={classes.successMessageTitle}>
                      POST ADDED SUCCESSFULLY
                    </Typography>
                  )}
                  {anonimus && (
                    <Typography className={classes.successMessageTitle}>
                      YOUR POST HAS BEEN SUBMITTED FOR REVIEW. YOU WILL BE
                      NOTIFIED WHEN IT IS POSTED
                    </Typography>
                  )}
                </CardContent>
                <CardActions className={classes.successMessageActions}>
                  {!anonimus && (
                    <Button
                      className={classes.viewPostBtn}
                      variant="contained"
                      component={RouterLink}
                      to={`${ARTICLES}/${publishedPostID}`}>
                      View post
                    </Button>
                  )}
                  {anonimus && (
                    <Button
                      className={classes.viewPostBtn}
                      variant="contained"
                      component={RouterLink}
                      to={`${ARTICLES}`}>
                      GO TO FEED
                    </Button>
                  )}
                </CardActions>
              </Card>
            )}
            {!isPublished && (
              <Grid container direction="column">
                <Grid item>
                  <Card className={classes.form}>
                    <Grid container direction="column">
                      <React.Fragment>
                        <Grid item className={classes.fieldGrid}>
                          <MentionsComponent
                            // autoFocusTextArea={autoFocusTextArea}
                            allMention={allMention}
                            allTags={allTags}
                            isAuthUser
                            onSelect={this.onSelect}
                            setNewValue={this.setNewValue}
                            setClearAll={this.onSetClearAll}
                            clearAll={setClearAll}
                            postStyle
                            placeholder={'What would you like to say?'}
                            // editResponse={editResponse}
                          />
                        </Grid>

                        <Grid
                          item
                          className={`${classes.fieldGrid} ${classes.border}`}>
                          {/* <SwitchRoot
                            label="Mark as a question?"
                            description="We’ll find experts to answer your question"
                            handleChange={this.handeSwitchClick}
                            showDescription={type === 'question'}
                            type="question"
                            disabled={loadURL}
                          /> */}
                          {type === 'question' && (
                            <SwitchRoot
                              type="anonymous"
                              handleChange={this.handeSwitchAnonimusClick}
                              showDescription={anonimus}
                              label="Post Anonymously?"
                              description="Your name and profile will not be added to the post. Your post will be subject to moderation before publishing."
                            />
                          )}

                          <Grid
                            container
                            item
                            className={`${classes.containerBtn} ${(banner ||
                              imageLoading ||
                              type === 'video') &&
                              classes.containerHeight}`}
                            display="flex"
                            wrap="nowrap"
                            direction="row">
                            {type === 'video' && !loadURL && (
                              <React.Fragment>
                                <InputBase
                                  className={classes.commentInput}
                                  placeholder="Paste YouTube URL"
                                  onChange={this.handleInputURL}
                                  value={url}
                                />
                                <Button
                                  className={`${classes.removeBtn} ${classes.padingBtn}`}
                                  fullWidth
                                  variant="text"
                                  startIcon={<VideocamIcon />}
                                  onClick={this.onClickCancelVideo}
                                  component="span">
                                  {'Cancel'}
                                </Button>
                                <Button
                                  className={`${classes.addBtn} ${classes.padingBtn}`}
                                  fullWidth
                                  variant="text"
                                  onClick={this.onClickAddVideo}
                                  component="span">
                                  {'Add'}
                                </Button>
                              </React.Fragment>
                            )}
                            {type === 'video' && loadURL && (
                              <React.Fragment>
                                <CardMedia className={classes.videoContainer}>
                                  <ReactPlayer
                                    className={classes.videoPlayer}
                                    url={url}
                                    controls
                                    width="128"
                                    height="128"
                                  />
                                </CardMedia>
                                <Button
                                  className={classes.removeBtn}
                                  fullWidth
                                  variant="text"
                                  startIcon={<VideocamIcon />}
                                  onClick={this.onClickRemoveVideo}
                                  component="span">
                                  {'Remove Video'}
                                </Button>
                              </React.Fragment>
                            )}

                            {imageLoading && (
                              <div className={classes.photoImage}>
                                <CircularProgress
                                  variant="static"
                                  value={progress}
                                />
                              </div>
                            )}
                            {!imageLoading && banner && (
                              <CardMedia
                                className={classes.photoImage}
                                image={banner}
                                component="img"
                              />
                            )}
                            {!imageLoading && banner && (
                              <Button
                                className={classes.removeBtn}
                                fullWidth
                                variant="text"
                                startIcon={<PhotoCameraIcon />}
                                component="span"
                                onClick={this.handleRemoveOnClick}>
                                {'Remove Picture'}
                              </Button>
                            )}

                            {!imageLoading && !banner && type !== 'video' && (
                              <Button
                                className={`${classes.photoUploadButton} ${classes.borderBtn}`}
                                fullWidth
                                variant="text"
                                startIcon={<PhotoCameraIcon />}
                                component="span">
                                {
                                  <>
                                    <p>Add picture</p>

                                    <CardActionArea
                                      className={classes.photoContainer}
                                      component="label"
                                      disabled={Boolean(
                                        !imageLoading && banner
                                      )}>
                                      <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={this.handleImageChange}
                                      />
                                    </CardActionArea>
                                  </>
                                }
                              </Button>
                            )}

                            {!imageLoading && !banner && type !== 'video' && (
                              <Button
                                className={classes.photoUploadButton}
                                fullWidth
                                variant="text"
                                disabled={anonimus}
                                startIcon={<VideocamIcon />}
                                onClick={this.handleClickTypeVideo}
                                component="span">
                                {'Add video'}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </Grid>
                  </Card>
                </Grid>
                <Grid
                  container
                  item
                  className={classes.wrapBtnGroup}
                  justify="flex-end">
                  <Grid item>
                    <ActionButton
                      className={classes.cancelBtn}
                      variant="contained"
                      disabled={
                        (!valueTextArea && !banner) || (!valueTextArea && !url)
                      }
                      onClick={this.handleCancelOnClick}>
                      Cancel
                    </ActionButton>
                  </Grid>
                  <Grid item>
                    <ActionButton
                      className={classes.postBtn}
                      variant="contained"
                      disabled={
                        !valueTextArea || (type === 'video' && !loadURL)
                      }
                      onClick={this.handlePostOnClick}>
                      Submit
                    </ActionButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default NewPostBase;
