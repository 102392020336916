export const styles = theme => ({
  mediaContainer: {
    position: 'relative'
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    objectFit: 'cover'
  },
  mediaTopContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  mediaBottomContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  readingTimeIcon: {
    fontSize: 16
  },
  readingTimeValue: {
    marginLeft: 3,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit'
  },
  tagList: {
    padding: 0,
    listStyle: 'none'
  },
  tagItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 6px',
    fontSize: 10,
    lineHeight: '1em',
    borderRadius: '16px',
    backgroundColor: '#ffffff'
  }
});

export default styles;
