import React from 'react';
import { compose } from 'recompose';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../themes/theme';

import Routes from '../Routes';
import { withAuthentication } from '../Session';
import withNotifications from '../Notifications/withNotification';

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ThemeProvider>
);

export default compose(withAuthentication, withNotifications)(App);
