/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable no-invalid-this */
/* eslint-disable react/prop-types */
import React, { Component, lazy, Suspense } from 'react';
import ReactPlayer from 'react-player/youtube';
import Moment from 'react-moment';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { nanoid } from 'nanoid';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  Button,
  LinearProgress
} from '@material-ui/core';
import CommentsComponent from './components/comments/Comments';
import { AUTHOR, ARTICLES, NEW_POST } from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { allArticlesSelector } from '../Articles/selectors';
import {
  getIdFromUrl,
  changeUserCoins,
  getStrippedString,
  formatDate
} from '../../common/Helpers';

import { styles } from './Article.style';
import { uniqBy } from 'lodash';

const DEFAULT_AVATAR = '/images/default-avatar.png';

const ContributorComponent = lazy(() =>
  import('./components/contributor/Contributor')
);
const MediaComponent = lazy(() => import('./components/media/Media'));
const ActionsComponent = lazy(() => import('./components/actions/Actions'));

const renderLoader = () => <p>Loading</p>;

class ArticlePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      article: null,
      isDeleted: false,
      isScrolled: false,
      clearValueEditor: false,
      allMention: null,
      allTags: null,
      userForNotification: null,
      scrollTo: null,
      autoFocusTextArea: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const postId = getIdFromUrl(location.pathname, 'articles');

    if (!postId) {
      this.setState({
        article: null
      });

      return;
    }
    this.onListenForArticle(postId);

    this.getTags();
  }

  componentDidUpdate() {
    const { location } = this.props;
    if (
      this.state.allMention !== null &&
      this.state.allTags !== null &&
      this.state.loading
    ) {
      this.setState({
        loading: false
      });
    }
    const fromArticles = location.state && location.state.fromArticles;
    const addResponse = location.state && location.state.addResponse;

    if (addResponse && !this.state.autoFocusTextArea) {
      this.setState({ autoFocusTextArea: true });
    }

    const textArea = document.getElementById('comment-text-area');

    if (textArea && fromArticles && !this.state.isScrolled) {
      const rootArticle = document.getElementById('rootArticle');
      const y =
        rootArticle.offsetHeight +
        rootArticle.offsetHeight +
        rootArticle.offsetTop;
      window.scrollTo(0, y);
      this.setState({ scrollTo: y });
      this.setState({ isScrolled: true });
    }

    // if (this.state.article && this.state.article.uid) {
    //   this.onListenForArticle(this.state.article.uid);
    // }
  }

  onListenForArticle = async postId => {
    const { firebase } = this.props;
    let article = null;
    this.unsubscribe = async function unsubscribe() {
      article = await firebase.getPostLive(postId);

      if (!article.contributor || !article.contributor.firstName) {
        const userData = await firebase.getUser(article.contributorRef);
        article.contributor = userData;
      }

      this.getFollowers(article);
      if (!article) {
        this.setState({
          loading: false
        });

        return;
      }

      this.setState({
        article: { ...article }
      });
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  getFollowers = async article => {
    const { authUser } = this.props;
    const comments = [];

    const data = article.comments ? Object.keys(article.comments) : [];
    data.forEach(key => {
      comments.push({
        uid: key,
        ...article.comments[key]
      });
    });

    let commentsUsers = comments.filter(
      el => el.user.uid !== (authUser && authUser.uid)
    );

    commentsUsers = uniqBy(commentsUsers, 'user.uid');

    const commentsUsersUID = [];
    commentsUsers.forEach(com => {
      commentsUsersUID.push(com.user.uid);
    });

    let allUserForMentions = [...commentsUsersUID, ...[article.contributorRef]];

    if (authUser && authUser.followers && authUser.followers.length > 0) {
      allUserForMentions.push(...authUser.followers);
    }
    if (authUser && authUser.follows && authUser.follows.length > 0) {
      allUserForMentions.push(...authUser.follows);
    }

    allUserForMentions = uniqBy(allUserForMentions);

    const usersLength = allUserForMentions.length;
    let allData = [];
    for (let i = 0; i < usersLength; i += 10) {
      const requests = [];

      requests.push(
        this.getFollowers10(allUserForMentions.slice(i, i + 10)).catch(e =>
          console.log(e)
        )
      );

      await Promise.all(requests)
        .then(a => {
          allData = [...allData, ...a[0]];
        })
        .catch(e => console.log(e));
    }

    this.setState({ allMention: allData });
  };

  getFollowers10 = async arr => {
    const { firebase } = this.props;
    try {
      const following = {};
      const mention = [];
      const args = {
        filters: [
          {
            field: 'documentId',
            condition: 'in',
            value: arr
          }
        ]
      };
      const followersRefs = await firebase.getUsersWithArgs(args);

      followersRefs.forEach(s => {
        if (!s.firstName && !s.lastName && !s.username) {
          return;
        }
        following[s.uid] = s;
        mention.push({
          id: s.uid,
          link: s.uid,
          avatar: s.avatar ? s.avatar : DEFAULT_AVATAR,
          name:
            s.firstName && s.lastName
              ? `${s.firstName} ${s.lastName}`
              : s.username,
          value:
            s.firstName && s.lastName
              ? `${s.uid.substr(0, 3) +
                  '_' +
                  s.firstName.replace(' ', '') +
                  s.lastName.replace(' ', '')}`
              : s.uid.substr(0, 3) + '_' + s.username.replace(' ', '')
        });
      });

      return mention;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  getTags = async () => {
    const { firebase } = this.props;
    const args2 = {
      filters: [
        {
          field: 'showInterest',
          condition: '==',
          value: true
        }
      ]
    };
    const tagsCurrent = [];
    const tags = await firebase.getTagsWithArgs(args2);
    tags.forEach(s => {
      tagsCurrent.push({
        id: s.uid,
        name: s.name.replace(' ', '_')
      });
    });

    this.setState({ allTags: tagsCurrent });
  };

  nonSignInHandler = message => {
    this.props.showSighInDialog(
      { pathname: `${ARTICLES}/${this.state.article.uid}` },
      message
    );
  };

  onArticleLikeClick = () => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('Approve the post');
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        article.uid,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        article.uid,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      article.uid,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(postId, likes, doUpdateUserCoins) {
    const { article } = this.state;
    const { authUser, firebase } = this.props;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(postId, body, authUser.uid)
      .then(() => {
        const newArticle = {
          ...article,
          likes: [...likes]
        };
        doUpdateUserCoins();
        this.setState({
          article: newArticle
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = () => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('save the post');
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([article.uid]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(article.uid);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== article.uid);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, article.uid];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  onFollowClick = e => {
    e.stopPropagation();
    const { authUser } = this.props;
    const { article } = this.state;

    if (!authUser) {
      this.nonSignInHandler('follow the user');
      return;
    }

    const newFollows = authUser.follows
      ? [...authUser.follows, article.contributorRef]
      : [article.contributorRef];

    const newFollowers = article.contributor.followers
      ? [...article.contributor.followers, authUser.uid]
      : [authUser.uid];

    this.doFollow(newFollows, newFollowers);
  };

  doFollow = (follows, followers) => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { article } = this.state;

    const body = {
      data: {
        follows
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const updatedUser = { ...authUser, follows };
        onSetAuthUser(updatedUser);
      })
      .catch(err => {
        console.error('error: ', err);
      });

    const newBody = {
      data: {
        followers
      },
      merge: true
    };

    firebase
      .editUser(article.contributorRef, newBody)
      .then(() => {
        const updatedContributor = {
          ...article.contributor,
          followers
        };
        const updatedArticle = {
          ...article,
          contributor: updatedContributor
        };

        this.setState({
          article: updatedArticle
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  onAuthorClick = () => {
    const { history, onSetSelectedAuthor } = this.props;
    const { article } = this.state;
    const author = {
      ...article.contributor,
      uid: article.contributorRef
    };

    onSetSelectedAuthor(author);
    history.push(`${AUTHOR}/${article.contributorRef}`);
  };

  makeNotification = type => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;
    const { article } = this.state;
    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification);
  };

  collectUsersToNotification = async () => {
    const { authUser, firebase } = this.props;
    const { article } = this.state;
    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    if (article.originalContributor) {
      collectedUsers.push(article.originalContributor);
    }

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: article.uid
        }
      ]
    };

    const savedArticleUsersRefs = await firebase.getUsersWithArgs(args);

    if (savedArticleUsersRefs.length > 0) {
      for (const userRef of savedArticleUsersRefs) {
        if (userRef.uid !== authUser.uid) {
          collectedUsers.push(userRef.uid);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };

  sendNotification = async notification => {
    const { authUser, firebase } = this.props;
    const followersArr = await this.collectUsersToNotification();
    const followers =
      notification.type === 'response'
        ? followersArr
        : this.state.userForNotification;
    const body = {
      followers,
      notification
    };

    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
        if (notification.type === 'mention') {
          this.setState({ userForNotification: null });
        }
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  makeNewComment = async allData => {
    const { comment, userForNotification, valueForEdit } = allData;
    const { authUser, firebase } = this.props;
    const { article } = this.state;

    const freshArticle = await firebase.getPost(article.uid);

    const id = nanoid();
    const isNewComment = true;
    this.setState({ userForNotification: userForNotification });
    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date()),
      valueForEdit
    };
    const comments = freshArticle.comments ? { ...freshArticle.comments } : {};
    comments[id] = { ...newComment };

    this.setState({
      article: { ...freshArticle }
    });

    this.updateComments(comments, isNewComment, userForNotification);
  };

  editComment = async allData => {
    const { comment, userForNotification, valueForEdit, commentId } = allData;
    const { firebase } = this.props;
    this.setState({ userForNotification: userForNotification });
    const { article } = this.state;

    const freshArticle = await firebase.getPost(article.uid);

    const isNewComment = false;
    const comments = { ...freshArticle.comments };
    comments[commentId].content = comment;
    comments[commentId].isEdited = true;
    comments[commentId].valueForEdit = valueForEdit;

    this.setState({
      article: { ...freshArticle }
    });

    this.updateComments(comments, isNewComment, userForNotification);
  };

  deleteComment = async commentId => {
    const { firebase } = this.props;
    const { article } = this.state;

    const freshArticle = await firebase.getPost(article.uid);

    const comments = { ...freshArticle.comments };
    const isDeleted = delete comments[commentId];

    this.setState({
      article: { ...freshArticle }
    });

    if (isDeleted) {
      this.updateComments(comments);
    }
  };

  updateComments = async (comments, isNewComment, userForNotification) => {
    const { firebase, authUser } = this.props;
    const { article } = this.state;

    const body = {
      data: {
        comments: { ...comments }
      }
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response');
        }
        if (userForNotification && userForNotification.length > 0) {
          this.makeNotification('mention');
        }
        this.setState({
          article: {
            ...article,
            comments
          }
        });

        this.props.onSetArticle(article);
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  onCommentLikeClick = commentId => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('Approve the response');
      return;
    }

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid)
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid)
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid)
    );
  };

  updateArticleComments = (commentId, comment, doUpdateUserCoins) => {
    const { firebase, authUser } = this.props;
    const { article } = this.state;
    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };

    const body = {
      data: {
        comments
      },
      merge: true
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        const newArticle = {
          ...article,
          comments
        };
        doUpdateUserCoins();
        this.setState({
          article: newArticle
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  deleteArticle = () => {
    const { firebase, authUser } = this.props;
    const { article } = this.state;

    this.setState({
      loading: true
    });

    firebase
      .deletePost(article.uid, authUser.uid)
      .then(() => {
        this.cleanReferences({ ...article });
        this.setState({
          loading: false,
          article: null,
          isDeleted: true
        });
      })
      .catch(err => {
        console.error('error: ', err);
        this.setState({
          loading: false
        });
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = postId => {
    const { firebase } = this.props;

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: postId
        }
      ]
    };

    firebase
      .getUsersWithArgs(args)
      .then(users => {
        users.forEach(async user => {
          const newSavedArticles = user.savedArticles.filter(
            id => id !== postId
          );

          const body = {
            data: {
              savedArticles: newSavedArticles
            }
          };

          await firebase.editUser(user.uid, body);
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const {
      loading,
      article,
      isDeleted,
      clearValueEditor,
      allMention,
      allTags,
      scrollTo,
      autoFocusTextArea
    } = this.state;

    const tags = [];
    const comments = [];
    let avatar, fullName, likes, status, readingTime;
    let isLiked = false;
    let isSaved = false;
    let isFollowed = false;
    const shouldHideActionsBlock =
      article &&
      article.type &&
      (article.type === 'question' || article.type === 'thought');
    const authUserIsArticleAuthor =
      (authUser && article && authUser.uid === article.contributorRef) ||
      (authUser && article && authUser.uid === article.originalContributor);

    let date = new Date();

    if (article) {
      avatar = article.contributor.avatar
        ? article.contributor.avatar
        : '/images/default-avatar.png';

      fullName = article.contributor.firstName
        ? `${article.contributor.firstName} ${article.contributor.lastName}`
        : article.contributor.username;

      likes = article.likes ? article.likes.length : 0;

      status = article.contributor.memberStatus || '';
      if (article.contributor.occupation) {
        status =
          status !== ''
            ? `${status} | ${article.contributor.occupation}`
            : `${article.contributor.occupation}`;
      }

      if (article.tags && article.tags.length > 0) {
        const maxTags = 5;
        article.tags.slice(0, maxTags).forEach(tag => {
          tags.push(
            tag.charAt(0).toUpperCase() + tag.substring(1).toLowerCase()
          );
        });
      }

      if (authUser) {
        const savedArticles = authUser.savedArticles || [];
        const articleLikes = article.likes || [];
        const follows = authUser.follows || [];

        isSaved = savedArticles.includes(article.uid);
        isLiked = articleLikes.includes(authUser.uid);
        isFollowed = follows.includes(article.contributorRef);
      }

      readingTime = article.readingTime ? `${article.readingTime} min` : null;

      date = formatDate(article.created);

      if (article.comments) {
        const data = Object.keys(article.comments);
        data.forEach(key => {
          comments.push({
            uid: key,
            ...article.comments[key]
          });
        });
      }

      article.content = article.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    const isAdmin = authUser && authUser.role === 'ADMIN';
    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}

        <div id="rootArticle" className={classes.root}>
          {!loading && !article && !isDeleted && (
            <div>
              <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="center" gutterBottom variant="body1">
                  There are no article available
                </Typography>
              </Grid>
            </div>
          )}
          {!loading && !article && isDeleted && (
            <Card className={classes.successMessage}>
              <CardContent className={classes.successMessageContent}>
                <Typography className={classes.successMessageTitle}>
                  Post deleted!
                </Typography>
              </CardContent>
              <CardActions className={classes.successMessageActions}>
                <Button
                  className={classes.addPostBtn}
                  variant="contained"
                  component={RouterLink}
                  to={`${NEW_POST}`}>
                  Add new
                </Button>
              </CardActions>
            </Card>
          )}
          {!loading && article && (
            <div className={classes.pageContent}>
              <Card>
                <CardActionArea
                  className={classes.contributorContainer}
                  component="div"
                  disableRipple={authUserIsArticleAuthor}
                  onClick={authUserIsArticleAuthor ? null : this.onAuthorClick}>
                  {article.contributor && (
                    <Suspense fallback={renderLoader()}>
                      <ContributorComponent
                        avatar={avatar}
                        fullName={fullName}
                        status={status}
                        isAuthor={authUserIsArticleAuthor}
                        isFollowed={isFollowed}
                        onFollowClick={this.onFollowClick}
                        onDeleteClick={this.deleteArticle}
                        anonymous={article.anonymous}
                      />
                    </Suspense>
                  )}
                </CardActionArea>
                {article.type === 'video' && (
                  <CardMedia className={classes.videoContainer}>
                    <ReactPlayer
                      className={classes.videoPlayer}
                      url={article.content}
                      controls
                      width="320"
                      height="240"
                    />
                  </CardMedia>
                )}
                {article.banner && (
                  <Suspense fallback={renderLoader()}>
                    <MediaComponent
                      imgSrc={article.banner}
                      readingTime={readingTime}
                      tags={tags}
                    />
                  </Suspense>
                )}
                {!shouldHideActionsBlock && (
                  <Suspense fallback={renderLoader()}>
                    <ActionsComponent
                      likeValue={likes}
                      commentValue={comments.length}
                      isLiked={isLiked}
                      isSaved={isSaved}
                      isAuthor={authUserIsArticleAuthor}
                      onLikeClick={this.onArticleLikeClick}
                      onSaveClick={this.onSaveClick}
                      article={article}
                    />
                  </Suspense>
                )}
                <CardContent className={classes.articleContentContainer}>
                  <Grid container key={article.uid} direction="column">
                    {date && (
                      <Grid item>
                        <Moment className={classes.postingTime} fromNow>
                          {date}
                        </Moment>
                      </Grid>
                    )}
                    <Grid item className={classes.articleTitle}>
                      <Typography align="left" variant="h1">
                        {article.title}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.articleContent}>
                      {article.type === 'video' && (
                        <Typography className={classes.videoDescription}>
                          {article.description}
                        </Typography>
                      )}
                      {article.type !== 'video' && (
                        <div
                          className={classes.articleBody}
                          dangerouslySetInnerHTML={{
                            __html: article.content
                          }}></div>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Suspense fallback={renderLoader()}>
                <ActionsComponent
                  likeValue={likes}
                  commentValue={comments.length}
                  isLiked={isLiked}
                  isSaved={isSaved}
                  isAuthor={authUserIsArticleAuthor}
                  onLikeClick={this.onArticleLikeClick}
                  onSaveClick={this.onSaveClick}
                  article={article}
                />
              </Suspense>

              <div className={classes.commentsContainer}>
                <CommentsComponent
                  setUserForNotification={this.setUserForNotification}
                  allMention={allMention}
                  allTags={allTags}
                  autoFocusTextArea={autoFocusTextArea}
                  clearValueEditor={clearValueEditor}
                  comments={comments}
                  firebase={this.props.firebase}
                  currentUserId={authUser ? authUser.uid : ''}
                  isAuthUser={!!authUser}
                  scrollTo={scrollTo}
                  // eslint-disable-next-line
                  authUser={authUser ? authUser : ''}
                  isAdmin={isAdmin}
                  showSighInDialog={this.nonSignInHandler}
                  makeNewComment={this.makeNewComment}
                  editComment={this.editComment}
                  deleteComment={this.deleteComment}
                  onLikeClick={this.onCommentLikeClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  articles: allArticlesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  onSetArticle: article => dispatch({ type: 'ARTICLE_SET', article }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message }),
  onSetSelectedAuthor: author => dispatch({ type: 'AUTHOR_SET', author })
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(ArticlePage));
