/* eslint-disable no-loop-func */
/* eslint-disable no-empty-function */
import React, { Component } from 'react';
import { LinearProgress, Typography, Grid } from '@material-ui/core';
import { nanoid } from 'nanoid';
import FollowerCard from './FollowerCard';

class FollowingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      following: null,
      loading: true,
      authorName: ''
    };
  }

  componentDidMount() {
    const { authUser, location } = this.props;

    if (authUser && location.state && location.state.fromAuthor) {
      const { following, name } = location.state;
      this.setState({
        authorName: name
      });
      this.getFollowsAuthor(following);
    }

    if (
      authUser &&
      !location.state &&
      authUser.follows &&
      authUser.follows.length > 0
    ) {
      this.getFollowing();

      return;
    }

    this.setState({
      loading: false
    });
  }

  async getFollowsAuthor(following) {
    const { firebase } = this.props;
    let followersData = [];

    if (following.length > 0) {
      for (let i = 0; i < following.length; i += 10) {
        const requests = [];

        const args = {
          filters: [
            {
              field: 'documentId',
              condition: 'in',
              value: following.slice(i, i + 10)
            }
          ]
        };

        const data = await firebase.getUsersWithArgs(args);
        requests.push(data);

        await Promise.all(requests)
          .then(a => {
            followersData = [...followersData, ...a[0]];
            this.setState({
              following: followersData,
              loading: false
            });
          })
          .catch(e => console.log(e));
      }
    }
  }

  getFollowing = async () => {
    const { authUser, firebase } = this.props;
    const following = {};

    let followingData = [];

    if (authUser.follows.length > 0) {
      for (let i = 0; i < authUser.follows.length; i += 10) {
        const requests = [];

        const args = {
          filters: [
            {
              field: 'documentId',
              condition: 'in',
              value: authUser.follows.slice(i, i + 10)
            }
          ]
        };

        const data = await firebase.getUsersWithArgs(args);
        requests.push(data);

        await Promise.all(requests)
          .then(a => {
            followingData = [...followingData, ...a[0]];

            followingData.forEach(s => {
              following[s.uid] = s;
            });

            this.setState({
              following,
              loading: false
            });
          })
          .catch(e => console.log(e));
      }
    }
  };

  doUnfollow = (e, followUserId, followUserFollowers) => {
    e.preventDefault();
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { following } = this.state;

    const newUserFollows = authUser.follows.filter(id => id !== followUserId);
    const newFollowUserFollowers = followUserFollowers.filter(
      id => id !== authUser.uid
    );

    const body = {
      data: {
        follows: newUserFollows
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        const newFollowing = { ...following };
        delete newFollowing[followUserId];
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          following: newFollowing
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    const newBody = {
      data: {
        follows: newFollowUserFollowers
      },
      merge: true
    };

    firebase
      .editUser(followUserId, newBody)
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  onFollowClick = (e, followerId) => {
    e.preventDefault();
    const { authUser } = this.props;

    const isFollowed = authUser.follows.includes(followerId);

    if (isFollowed) {
      this.doUnFollowOnAuthorPage(followerId);
      return;
    }
    this.doFollowOnAuthorPage(followerId);
    return;
  };

  updateFollows = (updatedUser, updatedAuthor, followerId, isFollows) => {
    const { firebase, onSetAuthUser } = this.props;

    const body = {
      data: {
        follows: updatedUser.follows
      },
      merge: true
    };

    firebase
      .editUser(updatedUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });

    const newBody = {
      data: {
        followers: updatedAuthor.followers
      },
      merge: true
    };

    firebase
      .editUser(followerId, newBody)
      .then(() => {})
      .catch(error => {
        console.log('error: ', error);
      });

    if (isFollows) {
      const type = 'newFollower';
      this.makeNotification(type, followerId);
    }
  };

  doUnFollowOnAuthorPage = async followerId => {
    const { authUser, firebase } = this.props;
    const authorData = await firebase.getUser(followerId);

    const newUserFollows = authUser.follows.filter(id => id !== followerId);
    const newAuthorFollowers = authorData.followers.filter(
      id => id !== authUser.uid
    );

    const updatedUser = { ...authUser, follows: [...newUserFollows] };
    const updatedAuthor = { ...authorData, followers: [...newAuthorFollowers] };

    this.updateFollows(updatedUser, updatedAuthor, followerId);
  };

  doFollowOnAuthorPage = async followerId => {
    const { authUser, firebase } = this.props;

    const authorData = await firebase.getUser(followerId);

    const updatedUser = authUser.follows
      ? { ...authUser, follows: [...authUser.follows, followerId] }
      : { ...authUser, follows: [followerId] };

    let updatedAuthor;

    if (authorData) {
      updatedAuthor = authorData.followers
        ? { ...authorData, followers: [...authorData.followers, authUser.uid] }
        : { ...authorData, followers: [authUser.uid] };
    }
    const isFollows = true;
    this.updateFollows(updatedUser, updatedAuthor, followerId, isFollows);
  };

  makeNotification = (type, followerId) => {
    const { authUser, firebase } = this.props;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: authUser.avatar ? authUser : '',
        uid: authUser.uid,
        fullName:
          authUser.firstName && authUser.lastName
            ? `${authUser.firstName} ${authUser.lastName}`
            : authUser.username
      },
      // post: {
      //   uid: elem.key,
      //   title: getStrippedString(title, MAX_CHARACTERS),
      // },
      hasRead: false
    };

    this.sendNotification(notification, followerId);
  };

  sendNotification = async (notification, followerId) => {
    const { authUser, firebase } = this.props;
    const followers = [followerId];

    const body = {
      followers,
      notification
    };

    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  render() {
    const { classes, authUser, location } = this.props;
    const { loading, following, authorName } = this.state;
    let sortedFollowing = [];

    if (following && !location.state) {
      const follows = [...authUser.follows];
      follows.reverse().forEach(followId => {
        sortedFollowing.push({
          uid: followId,
          ...following[followId]
        });
      });
    } else {
      sortedFollowing = following !== null &&
        following.length > 0 && [...following];
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            {!authorName && (
              <Typography className={classes.pageTitle} variant="h1">
                People you follow
              </Typography>
            )}

            {authorName && (
              <Typography className={classes.pageTitle} variant="h1">
                People followed by {authorName}
              </Typography>
            )}
            {sortedFollowing.length === 0 && (
              <Typography
                className={classes.noFollowTitle}
                align="center"
                variant="body1">
                You aren&apos;t following any people yet
              </Typography>
            )}
            {sortedFollowing.length > 0 && (
              <Grid className={classes.followList} container>
                {sortedFollowing.map(
                  ({
                    uid,
                    avatar,
                    firstName,
                    lastName,
                    username,
                    memberStatus,
                    occupation,
                    description,
                    followers
                  }) => {
                    const fullName =
                      firstName && lastName
                        ? `${firstName} ${lastName}`
                        : username;

                    let status = memberStatus || '';
                    if (occupation) {
                      status =
                        status !== ''
                          ? `${status} | ${occupation}`
                          : `${occupation}`;
                    }
                    const isFollowed = authUser.follows.includes(uid);
                    const disabled = authUser.uid === uid;
                    return (
                      <Grid key={uid} item md={12} xs={12}>
                        <FollowerCard
                          userId={uid}
                          avatar={avatar}
                          fullName={fullName || ''}
                          status={status}
                          description={description}
                          disabled={disabled}
                          isFollowed={
                            location.state && location.state.fromAuthor
                              ? isFollowed
                              : true
                          }
                          onFollowClick={e =>
                            location.state && location.state.fromAuthor
                              ? this.onFollowClick(e, uid)
                              : this.doUnfollow(e, uid, followers)
                          }
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FollowingBase;
