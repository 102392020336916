const INITIAL_STATE = {
  author: null,
  articles: null,
  isFollowed: false
};

const applySetAuthor = (state, action) => ({
  ...state,
  author: action.author ? { ...action.author } : null
});

const applySetIsFollowed = (state, action) => ({
  ...state,
  isFollowed: action.isFollowed
});

const applySetAuthorArticles = (state, action) => ({
  ...state,
  articles: action.articles ? [...action.articles] : null
});

function authorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AUTHOR_SET': {
      return applySetAuthor(state, action);
    }
    case 'AUTHOR_IS_FOLLOWED_SET': {
      return applySetIsFollowed(state, action);
    }
    case 'AUTHOR_ARTICLES_SET': {
      return applySetAuthorArticles(state, action);
    }
    default:
      return state;
  }
}

export default authorReducer;
