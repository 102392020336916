import { TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(2)
  },
  avatar: {
    width: 33,
    height: 33
  },
  commentBody: {
    backgroundColor: 'rgba(166,181,185,0.17)',
    borderRadius: '9px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(3),
    padding: theme.spacing(1),
    width: 'auto',
    display: 'flex'
  },
  name: {
    fontSize: 12,
    lineHeight: 1.3334,
    fontWeight: 700,
    letterSpacing: 0.4,
    color: 'inherit',
    '& a': {
      color: 'inherit'
    },
    padding: theme.spacing(0.5, 0, 0, 1)
  },
  period: {
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    fontSize: 8,
    lineHeight: 1.25,
    letterSpacing: 0.27,
    color: 'inherit'
  },
  text: {
    paddingLeft: theme.spacing(1),
    fontSize: 12,
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    lineHeight: 1.3572,
    letterSpacing: 0.46,
    color: 'inherit',
    '& .text-wrap': {
      display: 'flex',
      flexWrap: 'wrap'
    },
    '& .text-p-style': {
      marginRight: '3px',
      wordBreak: 'break-word',
      minHeight: '19px'
    },
    '& .link-style': {
      marginRight: '3px',
      wordBreak: 'break-word'
    },
    '& a': {
      color: '#64A6B8'
    }
  },
  styleSliceText: {
    maxHeight: '228px',
    overflow: 'hidden'
  },
  textColor: {
    color: '#64A6B8',
    textAlign: 'left',
    fontSize: '12px'
  },
  likeButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0,
    marginRight: theme.spacing(4)
  },
  likeContainer: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0.5)
  },
  likeIcon: {
    fontSize: '12px',
    '&.liked': {
      color: theme.palette.text.brand
    }
  },
  liked: {
    fontSize: '12px',
    '&.liked': {
      color: theme.palette.text.brand
    }
  },
  likeValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: '12px',
    lineHeight: 1.3334,
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  editorContainer: {
    '& .MuiPopover-paper': {
      maxWidth: 240,
      padding: theme.spacing(1),
      color: 'inherit'
    }
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5'
  },
  updateBtn: {
    backgroundColor: theme.palette.background.brand
  }
});

export const EditorTextArea = withStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1.5, 1.75),
      fontSize: 12,
      lineHeight: '1.3333em',
      color: theme.palette.text.secondary
    }
  }
}))(TextField);

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);

export default styles;
