import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player/youtube';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardActionArea,
  CardActions,
  Typography,
  Grid,
  Avatar,
  CardMedia,
  IconButton,
  Popover,
  Box,
  Button,
  Paper
} from '@material-ui/core';

import { Icon } from '@iconify/react';
import speechTyping from '@iconify-icons/system-uicons/speech-typing';
import thumbsUp from '@iconify-icons/carbon/thumbs-up';
import { Add } from '@material-ui/icons';

import he from 'he';

import { ARTICLES, AUTHOR, PROFILE } from '../../constants/routes';
import { DEFAULT } from '../../constants/articleColours';
import { authUserSelector } from '../Session/selectors';
import { MoreOptions, Share } from '../Article/components';
import { Delete } from '../PopoverMessages';
import { styles } from './ArticleItem.style';
import { Comment } from '../Article/components/comments/comment';
import { formatDate } from '../../common/Helpers';

const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'center',
  horizontal: 'center'
};
const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'center',
  horizontal: 'center'
};
const DELETE_MESSAGE = 'Are you sure you want to delete this post?';
const TEMPERORY_EDIT_MESSAGE =
  'Edit functionality is coming soon. Would you like to delete instead?';

const ArticleItem = ({
  authUser,
  article,
  classes,
  history,
  isSaved,
  isLiked = false,
  isEditable,
  onSetSelectedAuthor,
  onLikeClick,
  onSaveClick,
  onDeleteClick,
  onCommentLikeClick
}) => {
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [shouldShowDeleteMessage, showDeleteMessage] = useState(false);
  const [shouldShowEditMessage, showEditMessage] = useState(false);
  const articleItemRef = useRef();

  const { contributor, contributorRef, originalContributor } = article;
  const { firstName, lastName, username } = contributor;

  const handleOptionsClick = e => {
    e.preventDefault();
    setOptionAnchorEl(e.currentTarget);
  };

  const closeOptions = e => {
    e.preventDefault();
    setOptionAnchorEl(null);
  };

  const handleEditOptionClick = e => {
    showEditMessage(true);
    closeOptions(e);
  };

  const handleDeleteOptionClick = e => {
    showDeleteMessage(true);
    closeOptions(e);
  };

  const handleSaveOptionClick = e => {
    onSaveClick(article.uid);
    closeOptions(e);
  };

  const closeEditMessage = () => {
    showEditMessage(false);
  };

  const closeDeleteMessage = () => {
    showDeleteMessage(false);
  };

  const handleDeletePostClick = () => {
    if (showDeleteMessage) closeDeleteMessage();
    if (showEditMessage) closeEditMessage();
    onDeleteClick(article.uid);
  };

  const onAuthorClick = (authorData, authorId) => {
    if (
      (authUser && authUser.uid === contributorRef) ||
      (authUser && authUser.uid === originalContributor)
    ) {
      history.push(`${PROFILE}`);
      return;
    }
    const author = {
      ...authorData,
      uid: authorId
    };

    onSetSelectedAuthor(author);
    history.push(`${AUTHOR}/${authorId}`);
  };

  const getStrippedSummary = content => {
    const stripedHtml = content.replace(/<[^>]+>/g, '');
    const words = stripedHtml.split(' ');

    const maxWords = 80;

    let summary = words.join(' ');
    if (words.length > maxWords) {
      summary = `${words.slice(0, maxWords).join(' ')} ...`;
    }

    return summary;
  };

  const getTopCommentId = data => {
    const comments = [];

    const commentIds = Object.keys(data);
    commentIds.forEach(id => {
      comments.push({
        uid: id,
        ...data[id]
      });
    });

    if (comments.length === 1) {
      return comments[0].uid;
    }
    comments.sort((a, b) => {
      return formatDate(a.date) - formatDate(b.date);
    });

    let id = comments[0].uid;
    let size = comments[0].likes ? comments[0].likes.length : 0;

    comments.forEach(comment => {
      if (!comment.likes) {
        return;
      }
      if (comment.likes.length > size) {
        size = comment.likes.length;
        id = comment.uid;
      }
    });

    return id;
  };

  // Epochs
  const epochs = [
    ['y', 31536000],
    ['mo', 2592000],
    ['w', 604800],
    ['d', 86400],
    ['h', 3600],
    ['m', 60],
    ['s', 1]
  ];

  // Get duration
  const getDuration = timeAgoInSeconds => {
    for (const [name, seconds] of epochs) {
      const interval = Math.floor(timeAgoInSeconds / seconds);

      if (interval >= 1) {
        return {
          interval,
          epoch: name
        };
      }
    }
  };

  // Calculate
  const timeAgo = date => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
    const { interval, epoch } = getDuration(timeAgoInSeconds);
    return `${interval}${epoch}`;
  };

  const avatar = contributor.avatar || '/images/default-avatar.png';
  const fullName = firstName ? `${firstName} ${lastName}` : username;
  const authUserIsArticleAuthor =
    (authUser && authUser.uid === contributorRef) ||
    (authUser && authUser.uid === originalContributor);
  const likes = article.likes ? article.likes.length : 0;
  const currentUserId = authUser ? authUser.uid : '';
  const commentValue = article.comments
    ? Object.keys(article.comments).length
    : 0;
  let comment = null;
  if (article.comments && Object.keys(article.comments).length > 0) {
    const id = getTopCommentId(article.comments);
    comment = { ...article.comments[id], uid: id };
    comment.htmlContent = comment.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  const authAvatar =
    (authUser && authUser.avatar) || '/images/default-avatar.png';

  const readingTime = article.readingTime ? `${article.readingTime}min` : null;
  const tags = [];

  let summary;

  if (article.valueForEdit && article.valueForEdit.length > 240) {
    summary = getStrippedSummary(article.content);
  } else if (!article.valueForEdit && article.content.length > 240) {
    summary = getStrippedSummary(article.content);
  } else {
    summary = article.content;
  }

  if (article.description && article.description !== '') {
    summary = getStrippedSummary(article.description);
  }

  const styleForThought240 =
    (article.valueForEdit &&
      (article.type === 'thought' || article.type === 'question') &&
      article.valueForEdit.length <= 240 &&
      !article.banner) ||
    ((article.type === 'thought' || article.type === 'question') &&
      article.content.length <= 240 &&
      !article.banner);

  const styleForContentPaper = {
    background: `linear-gradient(225.45deg, #${(article.colours &&
      article.colours.end) ||
      DEFAULT.end} 0%, #${(article.colours && article.colours.begin) ||
      DEFAULT.begin} 100%)`
  };

  if (article.tags && article.tags.length > 0) {
    const maxTags = 5;
    article.tags.slice(0, maxTags).forEach(tag => {
      tags.push(tag.charAt(0).toUpperCase() + tag.substring(1).toLowerCase());
    });
  }

  const date = formatDate(article.created);
  const timePast = timeAgo(date);

  return (
    <Card className={classes.cardContainer} ref={articleItemRef}>
      <Box className={classes.contentContainer}>
        <Card className={classes.contributorContainer} component="div">
          {contributor && (
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              onClick={() => onAuthorClick(contributor, contributorRef)}>
              <Grid item>
                <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography className={classes.author}>{fullName}</Typography>
                {(date || readingTime) && (
                  <Typography className={classes.postingTime}>
                    {timePast}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          {(isEditable || !authUserIsArticleAuthor) && (
            <Grid item>
              <MoreOptions
                size="small"
                anchorEl={optionAnchorEl}
                onOptionsClick={handleOptionsClick}
                onOptionClose={closeOptions}
                onEditClick={handleEditOptionClick}
                onDeleteClick={handleDeleteOptionClick}
                onSaveClick={handleSaveOptionClick}
                isSaved={isSaved}
                isEditable={isEditable}
                canSave={!authUserIsArticleAuthor}
              />
            </Grid>
          )}
        </Card>
        {article.title && (
          <Grid
            className={classes.wrapTittle}
            container
            wrap="nowrap"
            alignItems="center">
            <Grid xs item>
              <Typography
                component={RouterLink}
                to={`${ARTICLES}/${article.uid}`}
                align="left"
                variant="h5">
                {he.decode(article.title)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {article.type === 'video' && (
          <CardMedia className={classes.videoContainer}>
            <ReactPlayer
              className={classes.videoPlayer}
              url={article.content}
              controls
              width="320"
              height="240"
            />
          </CardMedia>
        )}
        <CardActionArea
          component={RouterLink}
          to={`${ARTICLES}/${article.uid}`}
          disableRipple={Boolean(optionAnchorEl)}
          disable={optionAnchorEl}>
          {article.banner && (
            <div className={classes.mediaContainer}>
              <CardMedia className={classes.imageContainer}>
                <img
                  alt="Product"
                  className={classes.image}
                  src={article.banner}
                />
              </CardMedia>
            </div>
          )}

          <Grid container>
            <Grid className={classes.newPostLink} item lg={12} md={12} xs={12}>
              <Paper
                className={classes.contentPaper}
                style={styleForContentPaper}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: summary
                  }}
                  className={clsx({
                    [classes.summaryWrap]: true,
                    [classes.forThought240]: styleForThought240
                  })}
                  align={(styleForThought240 && 'center') || 'left'}
                  variant={
                    (styleForThought240 && 'h3') || 'body2'
                  }></Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardActionArea>
      </Box>

      {/* ACTION ICONS */}
      <CardActions className={classes.actionsContainer}>
        <Grid container wrap="nowrap" justify="space-between">
          <Grid item>
            <Grid container wrap="nowrap">
              <Grid item>
                <IconButton
                  className={classes.likeButton}
                  disabled={authUserIsArticleAuthor}
                  onClick={() => onLikeClick(article.uid)}>
                  <Icon
                    icon={thumbsUp}
                    className={clsx({
                      [classes.likeIcon]: true,
                      liked: isLiked
                    })}
                  />
                  <Typography className={classes.likeValue} component="span">
                    {likes}
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  component={RouterLink}
                  to={{
                    pathname: `${ARTICLES}/${article.uid}`,
                    state: { fromArticles: true }
                  }}
                  className={classes.commentButton}
                  disableRipple>
                  <Icon icon={speechTyping} className={classes.commentIcon} />
                  <Typography className={classes.commentValue} component="span">
                    {commentValue}
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item>
                <Share article={article} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
      {comment && (
        <Grid className={classes.wrapComment} item>
          <Comment
            feedSlice
            id={comment.uid}
            userId={
              comment.user.uid !== currentUserId ? comment.user.uid : null
            }
            avatar={comment.user.avatar}
            fullName={
              comment.user.firstName && comment.user.lastName
                ? `${comment.user.firstName} ${comment.user.lastName}`
                : comment.user.username
            }
            comment={comment.content}
            htmlContent={comment.htmlContent}
            likeValue={comment.likes ? comment.likes.length : 0}
            isLikeble={!(comment.user.uid === currentUserId)}
            isLiked={comment.likes.includes(currentUserId)}
            onLikeClick={() => onCommentLikeClick(comment.uid)}
          />
        </Grid>
      )}
      {commentValue > 1 && (
        <Grid className={classes.wrapCommentLink} item>
          <Typography
            component={RouterLink}
            to={{
              pathname: `${ARTICLES}/${article.uid}`,
              state: { fromArticles: true }
            }}
            className={classes.commentLinkText}>
            {`View ${commentValue - 1} more responses…`}
          </Typography>
        </Grid>
      )}

      <Grid className={classes.wrapCommentInput} item>
        <Button
          disableElevation={true}
          disableFocusRipple={true}
          disableRipple={true}
          component={RouterLink}
          to={{
            pathname: `${ARTICLES}/${article.uid}`,
            state: { fromArticles: true, addResponse: true }
          }}
          className={classes.btnAdd}
          fullWidth
          variant="contained">
          <Avatar src={authAvatar} className={classes.addAvatar} />
          Add a response
          <div className={classes.addIconContainer}>
            <Add className={classes.addIcon} />
          </div>
        </Button>
      </Grid>

      <Popover
        anchorEl={articleItemRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowDeleteMessage}
        onClose={closeDeleteMessage}>
        <Delete
          message={DELETE_MESSAGE}
          onCancelClick={closeDeleteMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
      <Popover
        anchorEl={articleItemRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowEditMessage}
        onClose={closeEditMessage}>
        <Delete
          message={TEMPERORY_EDIT_MESSAGE}
          onCancelClick={closeEditMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
    </Card>
  );
};

ArticleItem.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  article: PropTypes.object.isRequired,
  classes: PropTypes.object,
  history: PropTypes.object,
  isSaved: PropTypes.bool,
  isLiked: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSetSelectedAuthor: PropTypes.func,
  onLikeClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onCommentFocus: PropTypes.func
};

ArticleItem.defaultProps = {
  isLiked: false,
  isEditable: false
};

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetArticlesCategory: category =>
    dispatch({ type: 'ARTICLES_CATEGORY_SET', category }),
  onSetSelectedAuthor: author => dispatch({ type: 'AUTHOR_SET', author })
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(ArticleItem));
