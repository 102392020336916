import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  ButtonBase,
  Avatar,
  LinearProgress
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { styles } from './ProfileImage.style';

const ProfileImage = ({ classes, avatar, progress, isLoading, onChange }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.question}>
        Mind adding a profile image?
      </Typography>
      <Box className={classes.uploadContainer}>
        {isLoading && (
          <div className={classes.loader}>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
        <ButtonBase className={classes.uploadBtn} component="label">
          {avatar && (
            <Avatar alt="Avatar" className={classes.userAvatar} src={avatar} />
          )}
          {!avatar && <AccountCircleIcon />}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={onChange}
          />
        </ButtonBase>
        <Typography className={classes.invite}>
          Tap to add your profile picture
        </Typography>
      </Box>
    </div>
  );
};

export default withStyles(styles)(ProfileImage);
