import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(6),
      position: 'absolute',
      left: '240px',
      bottom: theme.spacing(2),
      right: 0
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
      width: '100%',
      textAlign: 'center'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
