import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import {
  Grid,
  Button,
  Snackbar,
  TextField,
  Link,
  Typography,
  Card,
  CardContent,
  CardActions
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../Firebase';
import { PASSWORD_FORGET, SIGN_IN } from '../../constants/routes';

const styles = theme => ({
  root: {
    height: '100%'
  },
  grid: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4)
    }
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    padding: theme.spacing(2, 2, 2, 2),
    flexBasis: 700
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  signUpLink: {
    width: '50%'
  },
  forgotLink: {
    textAlign: 'right',
    width: '50%'
  },
  successMessage: {
    maxWidth: 480,
    margin: '0 16px',
    padding: theme.spacing(6, 2, 3)
  },
  successMessageContent: {
    marginBottom: theme.spacing(3),
    padding: 0
  },
  successMessageTitle: {
    fontSize: 16,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  successMessageActions: {
    justifyContent: 'center',
    padding: 0
  },
  successMessageBtn: {
    minWidth: 80,
    fontSize: 12,
    lineHeight: 1.3334,
    color: '#ffffff',
    backgroundColor: theme.palette.background.brand
  }
});

const PasswordForgetPage = () => <PasswordForgetForm />;

const INITIAL_STATE = {
  email: '',
  error: null,
  openAlert: false,
  isSent: false
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    const { email } = this.state;

    console.log('submit');

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          email: '',
          isSent: true
        });
      })
      .catch(error => {
        this.setState({ error, openAlert: true });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openAlert: false });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    const { email, error, openAlert, isSent } = this.state;

    const isInvalid = email === '';

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.contentBody}>
              {isSent && (
                <Card className={classes.successMessage}>
                  <CardContent className={classes.successMessageContent}>
                    <Typography className={classes.successMessageTitle}>
                      An email would have been sent to you if the address exists
                      in our records, please also check your spam folder
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.successMessageActions}>
                    <Button
                      className={classes.successMessageBtn}
                      variant="contained"
                      component={RouterLink}
                      to={SIGN_IN}>
                      OK
                    </Button>
                  </CardActions>
                </Card>
              )}
              {!isSent && (
                <form className={classes.form} onSubmit={this.onSubmit}>
                  <Typography color="textSecondary" gutterBottom>
                    A reset link will be send to your email address
                  </Typography>
                  <TextField
                    className={classes.textField}
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    variant="outlined"
                    value={email}
                    fullWidth
                    onChange={this.onChange}
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={isInvalid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Reset My Password
                  </Button>
                </form>
              )}
            </div>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p style={{ textAlign: 'left' }}>
    <Link to={PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

PasswordForgetFormBase.propTypes = {
  classes: PropTypes.object.isRequired
};

const PasswordForgetForm = compose(
  withRouter,
  withFirebase
)(withStyles(styles)(PasswordForgetFormBase));

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };
