import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  message: {
    width: 240,
    padding: theme.spacing(2),
    fontSize: 16,
    lineHeight: 1.3125,
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  actions: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.border}`
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5'
  },
  deleteBtn: {
    backgroundColor: '#E20D0D'
  }
});

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);
