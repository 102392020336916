import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import DesktopTopBarBase from './DesktopTopBar';
import { styles } from './DesktopTopBar.style';
import { authUserSelector } from '../../components/Session/selectors';
import { newNotificationsAmount } from '../../components/Notifications/selectors';

const mapStateToProps = state => ({
  authUser: authUserSelector(state),
  newNotificationsAmount: newNotificationsAmount(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(withStyles(styles)(DesktopTopBarBase));
