import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization } from '../../components/Session';

import { Sidebar, Footer } from './components';
import { Topbar } from '../Topbar';
import { BottomBar } from '../BottomBar';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 65
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const isBottom = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant="persistent"
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>

      {isBottom && <BottomBar />}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition)
)(Main);
