import { withStyles } from '@material-ui/styles';
import { TabPanel, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';

export const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
  mainContainer: {
    padding: theme.spacing(1, 2, 1, 2),
    marginBottom: theme.spacing(1)
  },
  userWrap: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  },
  folowBtnWrap: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    marginBottom: theme.spacing(2)
  },
  folowBtn: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    borderRadius: '9px'
  },
  tabsWrapLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%'
    }
  },
  tabsWrapRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '30%'
    }
  },
  userCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '9px'
  },
  userCardHeader: {
    alignItems: 'center'
  },
  userAvatar: {
    width: 71,
    height: 71
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  userDescription: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px',
    marginTop: theme.spacing(1)
  },
  statistic1: {
    marginRight: theme.spacing(1),
    borderRadius: '9px'
  },
  statistic2: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: '9px'
  },
  statistic3: {
    marginLeft: theme.spacing(1),
    borderRadius: '9px'
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 1, 0),
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.text.brand,
    borderRadius: '9px'
  },
  likeValue: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: 'inherit'
  },
  statisticIcon: {
    display: 'block',
    fontSize: 20
  },
  followValue: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: 'inherit',
    textTransform: 'uppercase'
  },
  atricleListTitleGrid: {
    marginTop: theme.spacing(1)
  },
  articleListTitle: {
    marginBottom: 0,
    fontWeight: 400
  },
  descriptionText: {
    wordBreak: 'break-word'
    //textAlign: 'justify'
  },
  textColor: {
    color: '#64A6B8',
    textAlign: 'right'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bigAvatar: {
    width: '320px',
    height: '500px',
    borderRadius: 0
  },
  wrapArticles: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  }
});

export const StyledTabList = withStyles(theme => ({
  root: {
    minHeight: 32,
    color: theme.palette.text.secondary,
    opacity: 1,
    padding: theme.spacing(0, 2, 0, 2)
  },
  indicator: {
    backgroundColor: theme.palette.background.brand
  }
}))(TabList);

export const StyledTabPanel = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  }
}))(TabPanel);

export const StyledTab = withStyles(theme => ({
  root: {
    minHeight: 32,
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 1.25,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.secondary
  },
  selected: {
    color: theme.palette.text.brand,
    backgroundColor: `${theme.palette.background.brand}1f` // #64A6B81f
  }
}))(Tab);

export default styles;
