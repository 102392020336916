import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { Typography, Box, Chip } from '@material-ui/core';

import { styles } from './Interests.style';

const Interests = ({ classes, tags, selectedInterests, onClick }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.question}>
        What interests you the most from these options?
      </Typography>
      <Box className={classes.options} component="ul">
        {tags &&
          tags.map(tag => {
            const name = tag.name
              .toLowerCase()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

            const isSelected = selectedInterests.find(s => s.uid === tag.uid);

            return (
              <li key={tag.uid} className={classes.option}>
                <Chip
                  className={clsx({
                    [classes.tag]: true,
                    selected: isSelected
                  })}
                  label={name}
                  onClick={() => onClick(tag)}
                />
              </li>
            );
          })}
      </Box>
      <Typography className={classes.description}>
        To give you the most out of Zonotho, I’d like to get a sense of what you
        are interested in.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Interests);
