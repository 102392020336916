/* eslint-disable */
export const styles = theme => ({
  content: {
    padding: theme.spacing(2, 2, 0),
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`
  },
  tableRoot: {
    width: '100%'
  },
  contentFullStyle: {
    padding: 0,
    fontSize: 14,
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    lineHeight: 1.3572,
    letterSpacing: 0.46,
    color: 'inherit',
    '& .text-wrap': {
      display: 'flex',
      flexWrap: 'wrap'
    },
    '& .text-p-style': {
      marginRight: '3px',
      wordBreak: 'break-word',
      minHeight: '19px'
    },
    '& .link-style': {
      marginRight: '3px',
      wordBreak: 'break-word'
    }
  }
});
