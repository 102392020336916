import { default as Greeting } from './Greeting';
import { default as ProfileImage } from './ProfileImage';
import { default as Benefits } from './Benefits';
import { default as Interests } from './Interests';
import { default as Level } from './Level';
import { default as Finish } from './Finish';

export const GreetingStep = Greeting;
export const ProfileImageStep = ProfileImage;
export const BenefitsStep = Benefits;
export const InterestsStep = Interests;
export const LevelStep = Level;
export const FinishStep = Finish;

export default {
  GreetingStep,
  ProfileImageStep,
  BenefitsStep,
  InterestsStep,
  LevelStep,
  FinishStep
};
