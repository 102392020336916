import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import ArticleItem from './ArticleItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    [theme.breakpoints.up('lg')]: {
      width: '40%'
    },
    [theme.breakpoints.up('md')]: {
      width: '40%'
    }
  },
  styleList: {
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  container: {
    flexDirection: 'column'
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  firstArticle: {
    marginBottom: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

const ArticleList = ({
  fromProfile,
  userId,
  articles,
  savedArticlesIds,
  isEditable = false,
  onLikeClick,
  onSaveClick,
  onDeleteClick,
  onCommentFocus,
  onCommentLikeClick,
  makeNewComment
}) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const styleOnlyList = useMemo(() => window.location.pathname === '/search', [
    window.location.pathname
  ]);

  return (
    <div className={classes.root}>
      <div
        className={clsx({
          [classes.content]: true,
          [classes.styleList]: styleOnlyList || fromProfile
        })}>
        <Grid container className={classes.container}>
          {articles.map(article => {
            if (!article) {
              return false;
            }

            const isSaved = savedArticlesIds.includes(article.uid);
            let isLiked = false;

            if (userId && article.likes && article.likes.length > 0) {
              isLiked = article.likes.includes(userId);
            }
            return (
              <Grid item key={article.uid} md={12} xs={12}>
                <ArticleItem
                  key={article.uid}
                  article={article}
                  isSaved={isSaved}
                  isLiked={isLiked}
                  isEditable={isEditable}
                  onLikeClick={onLikeClick}
                  onSaveClick={onSaveClick}
                  onDeleteClick={onDeleteClick}
                  onCommentFocus={onCommentFocus}
                  onCommentLikeClick={commentID =>
                    onCommentLikeClick(commentID, article.uid)
                  }
                  makeNewComment={comment =>
                    makeNewComment(comment, article.uid)
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default ArticleList;
