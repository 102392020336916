import React from 'react';
import { withStyles } from '@material-ui/styles';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import ForumIcon from '@material-ui/icons/Forum';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

import { styles } from './Benefits.style';

const Benefits = ({ classes, name }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.greeting}>
        Nicely done!
        <br />
      </Typography>
      <Typography className={classes.welcome}>
        Welcome to Zonotho,
        <br />
        {`${name}`}
        <br />
        <br />
      </Typography>
      <Typography className={classes.listTitle}>
        Here’s what you’ll get from your
        <br />
        Zonotho account…
        <br />
        <br />
      </Typography>
      <List className={classes.list} disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <FontAwesomeIcon icon={faCoins} />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Unlimited Finance Related Content"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <ForumIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Quality conversations with like-minded people"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Quick answers to the most pressing questions"
          />
        </ListItem>
      </List>
    </div>
  );
};

export default withStyles(styles)(Benefits);
