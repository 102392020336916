export default (path, key) => {
  if (!path || !key) {
    return null;
  }

  const splitPath = path.split('/');
  const keyIndex = splitPath.indexOf(key);

  if (!keyIndex || keyIndex === -1) {
    return null;
  }

  return splitPath[keyIndex + 1];
};
