import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  useMediaQuery,
  Hidden,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import DesktopTopBar from '../DesktopTopBar';
import { Footer } from './components';
import { Topbar } from '../Topbar';
import { Heading } from '../Heading';
import { BottomBar } from '../BottomBar';
import { SIGN_IN } from '../../constants/routes';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 72
    }
  },
  content: {
    height: '100%'
  }
}));

const Default = ({
  children,
  messages,
  history,
  onCloseAlert,
  closeSignInDialog,
  closeMessageDialog
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const { alert, signInDialog, messageDialog } = messages;

  const handleSignInDialogClick = () => {
    const location = {
      pathname: SIGN_IN,
      state: signInDialog.locationState
    };

    history.push(location);
    closeSignInDialog();
  };

  const showNav =
    history.location.pathname !== '/signup' &&
    history.location.pathname !== '/signin' &&
    history.location.pathname !== '/pw-forget';

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}>
      <Hidden mdUp>
        <Topbar />
        <Heading />
      </Hidden>
      <Hidden smDown>
        <DesktopTopBar />
      </Hidden>

      <main className={classes.content}>
        {children}
        <Footer />
      </main>

      <Hidden mdUp>{showNav && <BottomBar />}</Hidden>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!alert}
        onClose={onCloseAlert}
        autoHideDuration={3000}>
        {alert && (
          <Alert variant="filled" severity={alert.type} onClose={onCloseAlert}>
            {alert.message}
          </Alert>
        )}
      </Snackbar>

      <Dialog open={signInDialog.isShown} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">SIGN IN NEEDED</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`You need to be signed in to ${signInDialog.message}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeSignInDialog}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSignInDialogClick}>
            Sign In
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={messageDialog.isShown} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{`${messageDialog.header}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{`${messageDialog.message}`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeMessageDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Default.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const mapStateToProps = state => ({
  messages: { ...state.messagesState }
});

const mapDispatchToProps = dispatch => ({
  onCloseAlert: () => dispatch({ type: 'ALERT_RESET' }),
  closeSignInDialog: () =>
    dispatch({
      type: 'SIGN_IN_DIALOG_RESET'
    }),
  closeMessageDialog: () =>
    dispatch({
      type: 'MESSAGE_DIALOG_RESET'
    })
});

// export default Default;
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Default);
