import { Tabs, Tab, Badge, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff00',
    zIndex: 9999,
    backgroundImage: 'url(/images/bottom.png)',
    backgroundPosition: 'center',
    boxShadow: 'none',
    height: '90px'
  },
  tabs: {
    marginTop: '20px'
  },
  tab: {
    fontSize: 12
  },
  addTab: {
    fontSize: 12,
    paddingBottom: theme.spacing(4)
  },
  spacer: {
    fontSize: 5,
    backgroundColor: '#fff',
    paddingBottom: 0
  },
  rootDrawer: {
    zIndex: '900 !important',
    '& .MuiBackdrop-root': {
      opacity: '0 !important'
    },
    '& .MuiDrawer-paperAnchorBottom': {
      height: '100%',
      paddingTop: '58px',
      backgroundColor: '#EEEEEE'
    }
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 26,
    padding: theme.spacing(3, 3, 3, 4),
    letterSpacing: 2,
    color: '#000000DE',
    backgroundColor: '#fff'
  },
  icon: {
    fontSize: '25px',
    marginBottom: '0 !important'
  },
  addIcon: {
    fontSize: '35px',
    marginBottom: '0 !important'
  },
  wrapItem: {
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#FFFFFF',
    '&:last-child': {
      borderBottom: 'none'
    },
    listContainer: {
      boxShadow:
        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    },
    paddingLeft: theme.spacing(4)
  },
  listTitle: {
    fontWeight: 500,
    letterSpacing: 0,
    color: '#000000DE',
    fontSize: '20px'
  },
  userAvatar: {
    width: 26,
    height: 26
  }
});

export const StyledTabs = withStyles(() => ({
  indicator: {
    top: '0px',
    backgroundColor: '#FFFFFF'
  }
}))(Tabs);

export const StyledTab = withStyles(theme => ({
  root: {
    paddingTop: 0,
    textTransform: 'none',
    '&.Mui-selected': {
      color: theme.palette.text.brand
    },
    '&:active': {
      color: theme.palette.text.brand,
      opacity: 1
    },
    '&:hover': {
      color: theme.palette.text.brand,
      opacity: 1
    }
  }
}))(Tab);

export const StyledBadge = withStyles(() => ({
  badge: {
    top: 4,
    right: 2,
    height: 18,
    minWidth: 17,
    padding: '1px 3px 0 4px',
    fontSize: '0.35em',
    lineHeight: '1em',
    backgroundColor: '#D01801',
    color: 'white',
    border: '#fff solid 2px'
  }
}))(Badge);

export const RoundIconButton = withStyles(theme => ({
  root: {
    padding: 5,
    fontSize: 32,
    color: theme.palette.text.brand,
    borderRadius: '50%',
    boxShadow: '0px 4px 5px #00000033'
  }
}))(IconButton);

export default styles;
