import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import { styles, StyledTextField } from './Greeting.style';

const Greeting = ({ classes, firstName, lastName, isLoading, onChange }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.invite}>
        Hi there, let’s get to know each other a bit
      </Typography>
      <Typography className={classes.presentation}>
        I’m Zo,
        <br />
        your digital personal finance assistant.
      </Typography>
      <Typography className={classes.question}>What’s your name?</Typography>
      <Box className={classes.fieldsContainer}>
        <StyledTextField
          className={classes.field}
          label="First name"
          id="firstName"
          value={firstName}
          error={!isLoading && !firstName}
          helperText={!isLoading && !firstName ? 'Your name, please' : ''}
          disabled={isLoading}
          onChange={onChange}
        />
        <StyledTextField
          className={classes.field}
          label="Last name"
          id="lastName"
          value={lastName}
          error={!isLoading && !lastName}
          helperText={!isLoading && !lastName ? 'Your last name, please' : ''}
          disabled={isLoading}
          onChange={onChange}
        />
      </Box>
    </div>
  );
};

Greeting.defaultProps = {
  firstName: '',
  lastName: ''
};

export default withStyles(styles)(Greeting);
