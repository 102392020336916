export const styles = theme => ({
  root: {
    padding: theme.spacing(7, 2)
  },
  greeting: {
    marginBottom: theme.spacing(5),
    fontSize: 22,
    lineHeight: '40px',
    opacity: 0.57
  },
  welcome: {
    marginBottom: theme.spacing(6),
    fontSize: 24,
    lineHeight: '40px',
    textAlign: 'center'
  },
  listTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 14,
    lineHeight: '22px',
    textAlign: 'center',
    opacity: 0.57
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  listIcon: {
    fontSize: 36,
    color: '#A2869D'
  }
});

export default styles;
