import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter, NavLink as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  LinearProgress,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Paper,
  Divider,
  Modal
} from '@material-ui/core';
import {
  allAuthorDataSelector,
  authorIsFollowedSelector,
  authorArticlesSelector
} from './selector';
import { withFirebase } from '../Firebase';
import {
  getIdFromUrl,
  changeUserCoins,
  getStrippedString
} from '../../common/Helpers';
import { AUTHOR, ARTICLES } from '../../constants/routes';
import ArticleList from '../Articles/ArticleList';
import { nanoid } from 'nanoid';
import ReactTextFormat from 'react-text-format';
import { People, ThumbUp } from '@material-ui/icons';

import {
  styles,
  StyledButton,
  StyledTabList,
  StyledTab,
  StyledTabPanel
} from './Author.style';
import { TabContext } from '@material-ui/lab';
const MAX_CHARACTERS = 240;
const INITIAL_STATE = {
  loading: true,
  currentArticleList: 'recent',
  showFullDescription: false,
  modalIsOpen: false
};
const DEFAULT_AVATAR = '/images/default-avatar.png';

class AuthorPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const {
      authUser,
      location,
      firebase,
      onSetAuthor,
      onSetArticles,
      onSetIsFollowed
    } = this.props;

    const authorId = getIdFromUrl(location.pathname, 'author');

    if (!authorId) {
      this.setState({
        loading: false
      });

      return;
    }

    this.unsubscribe = async function unsubscribe() {
      const authorRef = await firebase.getUser(authorId);
      const newAuthor = {
        ...authorRef,
        uid: authorId
      };
      onSetAuthor(newAuthor);

      if (authUser && authUser.follows && authUser.follows.includes(authorId)) {
        onSetIsFollowed(true);
      }

      const args = {
        filters: [
          {
            field: 'status',
            condition: '==',
            value: 'published'
          },
          {
            field: 'contributorRef',
            condition: '==',
            value: `${authorId}`
          }
        ],
        orders: [
          {
            field: 'created',
            value: 'desc'
          }
        ]
      };

      firebase
        .getPostsWithArgs(args)
        .then(articleRefs => {
          const articles = [];
          articleRefs.forEach(async article => {
            articles.push({
              ...article,
              uid: article.uid,
              contributor: { ...newAuthor }
            });
          });

          onSetArticles(articles);
        })
        .catch(err => {
          console.error('error: ', err);
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  componentDidUpdate() {
    const { authUser, author, isFollowed, onSetIsFollowed } = this.props;

    if (authUser && author) {
      if (
        authUser.follows &&
        authUser.follows.includes(author.uid) &&
        !isFollowed
      ) {
        onSetIsFollowed(true);
        return;
      }

      if (
        authUser.follows &&
        !authUser.follows.includes(author.uid) &&
        isFollowed
      ) {
        onSetIsFollowed(false);
        return;
      }
    }
  }

  componentWillUnmount() {
    const { onSetAuthor, onSetIsFollowed, onSetArticles } = this.props;
    onSetAuthor(null);
    onSetIsFollowed(false);
    onSetArticles(null);
  }

  onFollowClick = () => {
    const { author, authUser, isFollowed, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${AUTHOR}/${author.uid}` },
        'follow the user'
      );
      return;
    }

    if (isFollowed) {
      this.doUnfollows(authUser, author);
      return;
    }

    this.doFollows(authUser, author);
  };

  doFollows = (user, author) => {
    const updatedUser = user.follows
      ? { ...user, follows: [...user.follows, author.uid] }
      : { ...user, follows: [author.uid] };

    const updatedAuthor = author.followers
      ? { ...author, followers: [...author.followers, user.uid] }
      : { ...author, followers: [user.uid] };
    const isFollows = true;
    this.updateFollows(updatedUser, updatedAuthor, isFollows);
  };

  doUnfollows = (user, author) => {
    const newUserFollows = user.follows.filter(id => id !== author.uid);
    const newAuthorFollowers = author.followers.filter(id => id !== user.uid);

    const updatedUser = { ...user, follows: [...newUserFollows] };
    const updatedAuthor = { ...author, followers: [...newAuthorFollowers] };

    this.updateFollows(updatedUser, updatedAuthor);
  };

  updateFollows = (updatedUser, updatedAuthor, isFollows) => {
    const { firebase, onSetAuthUser, onSetAuthor } = this.props;

    const body = {
      data: {
        follows: updatedUser.follows
      },
      merge: true
    };

    firebase
      .editUser(updatedUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });

    const newBody = {
      data: {
        followers: updatedAuthor.followers
      },
      merge: true
    };

    firebase
      .editUser(updatedAuthor.uid, newBody)
      .then(() => {
        onSetAuthor(updatedAuthor);
      })
      .catch(error => {
        console.log('error: ', error);
      });
    if (isFollows) {
      const type = 'newFollower';
      this.makeNotificationOfFollowing(type);
    }
  };

  onLikeClick = articleId => {
    const { author, authUser, articles, showSighInDialog } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      let authorCoins = author.coins || 0;
      authorCoins += 1;
      this.updateArticleLikes(
        articleId,
        newLikes,
        authorCoins,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      const authorCoins = author.coins || 0;
      const newAuthorCoins = authorCoins !== 0 ? authorCoins - 1 : authorCoins;
      this.updateArticleLikes(
        articleId,
        newLikes,
        newAuthorCoins,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    let authorCoins = author.coins || 0;
    authorCoins += 1;
    this.updateArticleLikes(
      articleId,
      newLikes,
      authorCoins,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes = (articleId, likes, coins, doUpdateUserCoins) => {
    const {
      author,
      articles,
      firebase,
      onSetArticles,
      onSetAuthor,
      authUser
    } = this.props;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(articleId, body, authUser.uid)
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        onSetAuthor({
          ...author,
          coins
        });
        onSetArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  switchArticleList = (event, value) => {
    this.setState({
      currentArticleList: value
    });
  };

  onCommentLikeClick = (commentId, articleId) => {
    const { authUser, articles } = this.props;

    const article = articles.find(a => a.uid === articleId);

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid),
        article
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid),
        article
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid),
      article
    );
  };

  updateArticleComments = (commentId, comment, doUpdateUserCoins, article) => {
    const { firebase, articles, onSetArticles } = this.props;

    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };
    firebase
      .article(article.uid)
      .set(
        {
          comments
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        doUpdateUserCoins();
        onSetArticles(newArticles);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  makeNewComment = (comment, articleId) => {
    const { authUser, firebase, articles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    const id = nanoid();
    const isNewComment = true;

    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date())
    };
    const comments = article.comments ? { ...article.comments } : {};
    comments[id] = { ...newComment };

    this.updateComments(comments, isNewComment, article);
  };

  updateComments = (comments, isNewComment = false, article) => {
    const { firebase, articles, onSetArticles, history, authUser } = this.props;

    const body = {
      data: {
        comments: { ...comments }
      }
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response', article);
        }
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        onSetArticles(newArticles);
        history.push({
          pathname: `${ARTICLES}/${article.uid}`,
          state: { fromArticles: true }
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  makeNotification = (type, article) => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;

    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification, article);
  };

  sendNotification = async (notification, article) => {
    const { firebase, authUser } = this.props;
    const followers = await this.collectUsersToNotification(article);

    const body = {
      followers,
      notification
    };
    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  collectUsersToNotification = async article => {
    const { authUser, firebase } = this.props;

    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: article.uid
        }
      ]
    };

    const savedArticleUsersRef = await firebase.getUsersWithArgs(args);

    if (savedArticleUsersRef.length > 0) {
      for (const userRef of savedArticleUsersRef) {
        if (userRef.uid !== authUser.uid) {
          collectedUsers.push(userRef.uid);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };

  makeNotificationOfFollowing = type => {
    const { authUser, firebase } = this.props;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: authUser.avatar ? authUser.avatar : '',
        uid: authUser.uid,
        fullName:
          authUser.firstName && authUser.lastName
            ? `${authUser.firstName} ${authUser.lastName}`
            : authUser.username
      },
      // post: {
      //   uid: elem.key,
      //   title: getStrippedString(title, MAX_CHARACTERS),
      // },
      hasRead: false
    };

    this.sendNotificationOfFollowing(notification);
  };

  sendNotificationOfFollowing = async notification => {
    const { authUser, firebase, author } = this.props;
    const followers = [author.uid];

    const body = {
      followers,
      notification
    };

    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  onClickDescription = value => {
    this.setState({ showFullDescription: value });
    if (!value) {
      window.scrollTo(0, 0);
    }
  };
  handleOpenModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  render() {
    const {
      loading,
      currentArticleList,
      showFullDescription,
      modalIsOpen
    } = this.state;
    const {
      authUser,
      classes,
      author,
      articles,
      isFollowed,
      location
    } = this.props;
    let avatar, fullName, status, followsValue, followersValue;
    let authorCoins = 0;
    const savedArticlesIds =
      authUser && authUser.savedArticles ? authUser.savedArticles : [];
    let topSortedArticles = articles ? [...articles] : [];

    if (articles && articles.length > 1) {
      const notSorted = [...articles];
      topSortedArticles = notSorted.sort((a, b) => {
        const likesCountA = a.likes ? a.likes.length : 0;
        const likesCountB = b.likes ? b.likes.length : 0;

        return likesCountB - likesCountA;
      });
    }

    if (author) {
      const {
        memberStatus,
        firstName,
        lastName,
        username,
        occupation,
        follows,
        followers,
        coins
      } = author;

      avatar = author.avatar || DEFAULT_AVATAR;
      fullName = firstName ? `${firstName} ${lastName}` : username;

      status = memberStatus || '';
      if (occupation) {
        status = status !== '' ? `${status} | ${occupation}` : `${occupation}`;
      }

      followsValue = follows ? follows.length : 0;
      followersValue = followers ? followers.length : 0;

      authorCoins = coins || 0;
    }
    const isDescription = (author && author.description) || '';
    const descriptionNew =
      isDescription && !showFullDescription
        ? getStrippedString(author.description, MAX_CHARACTERS)
        : isDescription;
    const lengthDescription =
      isDescription.length > 240 ? descriptionNew : isDescription;
    const showDescription = !showFullDescription ? 'Read more' : 'Show less';

    const authorId = getIdFromUrl(location.pathname, 'author');

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && !author && (
          <div>
            <Grid item lg={12} md={12} xs={12} className={classes.content}>
              <Typography align="center" gutterBottom variant="body1">
                There is no author available
              </Typography>
            </Grid>
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Grid container direction="column">
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={modalIsOpen}
                onClose={this.handleOpenModal}
                className={classes.modal}>
                {
                  <Avatar
                    alt="Avatar"
                    className={classes.bigAvatar}
                    src={avatar}
                  />
                }
              </Modal>
              <Grid className={classes.mainContainer} container item>
                <Grid className={classes.userWrap} item xs={12}>
                  <Card className={classes.userCard}>
                    <Grid
                      className={classes.userCardHeader}
                      container
                      wrap="nowrap"
                      spacing={2}>
                      <Grid onClick={this.handleOpenModal} item>
                        <Avatar
                          alt="Avatar"
                          className={classes.userAvatar}
                          src={avatar}
                        />
                      </Grid>
                      <Grid item className={classes.userInfo}>
                        <Typography variant="h5">{fullName}</Typography>
                        <Typography component="span" variant="body1">
                          {status}
                        </Typography>
                      </Grid>
                    </Grid>
                    {isDescription && (
                      <CardContent className={classes.userDescription}>
                        <Typography className={classes.descriptionText}>
                          <ReactTextFormat allowedFormats={['URL']}>
                            {lengthDescription}
                          </ReactTextFormat>
                        </Typography>

                        {isDescription.length > 240 && (
                          <Typography
                            onClick={() =>
                              this.onClickDescription(!showFullDescription)
                            }
                            className={classes.textColor}>
                            {showDescription}
                          </Typography>
                        )}
                      </CardContent>
                    )}
                  </Card>
                </Grid>
                <Grid
                  className={classes.folowBtnWrap}
                  container
                  item
                  wrap="nowrap">
                  <Grid item xs={3} className={classes.statistic1}>
                    <Paper className={classes.statistic}>
                      <ThumbUp className={classes.statisticIcon} />
                      <Typography className={classes.likeValue}>
                        {authorCoins}{' '}
                        {authorCoins === 1 ? ` Approve` : ` Approvals`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs className={classes.statistic2}>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={{
                        pathname: `/author/${authorId}/folowers`,
                        state: {
                          fromAuthor: true,
                          followers: author.followers,
                          name: fullName
                        }
                      }}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followersValue} Followers`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs className={classes.statistic3}>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={{
                        pathname: `/author/${authorId}/following`,
                        state: {
                          fromAuthor: true,
                          following: author.follows,
                          name: fullName
                        }
                      }}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followsValue} Following`}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid className={classes.folowBtn} item xs={12}>
                  <StyledButton
                    className={clsx({
                      followBtn: true,
                      following: isFollowed
                    })}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={this.onFollowClick}>
                    {isFollowed ? 'Following' : 'Follow'}
                  </StyledButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <TabContext value={currentArticleList}>
                    <StyledTabList
                      onChange={this.switchArticleList}
                      variant="fullWidth"
                      aria-label="switch current article list">
                      <StyledTab
                        className={classes.tabsWrapLeft}
                        label="Recent Posts"
                        value="recent"
                      />
                      <StyledTab
                        className={classes.tabsWrapRight}
                        label="Top Posts"
                        value="top"
                      />
                    </StyledTabList>
                    <StyledTabPanel className={classes.tabPanel} value="recent">
                      {!loading && (
                        <Grid item xs={12}>
                          {!articles ||
                            (articles.length === 0 && (
                              <Typography align="center" variant="body1">
                                This user doesn&apos;t have any posts yet
                              </Typography>
                            ))}
                          {articles && articles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={articles}
                              savedArticlesIds={savedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                              onCommentLikeClick={this.onCommentLikeClick}
                              makeNewComment={this.makeNewComment}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel className={classes.tabPanel} value="top">
                      {!loading && (
                        <Grid item xs={12}>
                          {!articles ||
                            (articles.length === 0 && (
                              <Typography align="center" variant="body1">
                                This user doesn&apos;t have any posts yet
                              </Typography>
                            ))}
                          {articles && articles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={topSortedArticles}
                              savedArticlesIds={savedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                              onCommentLikeClick={this.onCommentLikeClick}
                              makeNewComment={this.makeNewComment}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

AuthorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  author: allAuthorDataSelector(state),
  articles: authorArticlesSelector(state),
  isFollowed: authorIsFollowedSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  onSetAuthor: author => dispatch({ type: 'AUTHOR_SET', author }),
  onSetArticles: articles =>
    dispatch({ type: 'AUTHOR_ARTICLES_SET', articles }),
  onSetIsFollowed: isFollowed =>
    dispatch({ type: 'AUTHOR_IS_FOLLOWED_SET', isFollowed }),
  showAlert: alert => dispatch({ type: 'ALERT_SET', alert }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(AuthorPage));
