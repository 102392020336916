export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(0, 2)
  },
  title: {
    margin: 'auto 0',
    fontSize: 30,
    lineHeight: '30px',
    opacity: 0.57
  },
  message: {
    margin: theme.spacing(2, 0),
    fontSize: 20,
    lineHeight: '30px',
    opacity: 0.57
  },
  loaderContainer: {
    margin: 'auto'
  },
  loader: {
    color: '#4E3B4B'
  }
});

export default styles;
