export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(0, 2, 0, 3)
  },
  question: {
    marginBottom: theme.spacing(8),
    fontSize: 30,
    lineHeight: '40px',
    letterSpacing: 0
  },
  uploadContainer: {
    position: 'relative',
    paddingTop: 4,
    textAlign: 'center'
  },
  uploadBtn: {
    fontSize: 158,
    color: '#82637C',
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      opacity: 0.7
    }
  },
  userAvatar: {
    width: 131,
    height: 131,
    margin: 14,
    boxSizing: 'content-box'
  },
  invite: {
    fontSize: 16,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.6
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  }
});

export default {
  styles
};
