import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';

import { styles, StyledSlider } from './Level.style';

const Level = ({ classes, value, onChange }) => {
  const marks = [
    {
      value: 1,
      label: 'I’m clueless about money'
    },
    {
      value: 5,
      label: 'Comfortable with complex concepts'
    }
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Great Stuff!</Typography>
      <Typography className={classes.question}>
        One final thing, how would you rate your current level of general
        financial knowledge?
      </Typography>
      <Box className={classes.sliderContainer}>
        <StyledSlider
          id="skillLevel"
          aria-label="level knowledge slider"
          value={value}
          min={1}
          max={5}
          step={1}
          marks={marks}
          onChange={onChange}
        />
      </Box>
    </div>
  );
};

export default withStyles(styles)(Level);
