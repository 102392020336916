import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.analytics = app.analytics();
    this.fieldValue = app.firestore.FieldValue;
    this.fieldPath = app.firestore.FieldPath;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.timeStamp = app.firestore.Timestamp;

    /* Firebase APIs */

    this.auth = app.auth();
    this.store = app.firestore();
    this.storage = app.storage();

    this.functions = process.env.REACT_APP_FUNCTIONS_URL;

    // Get a reference to the storage service, which is used to create references in your storage bucket
    this.storage = app.storage();
    // Create a storage reference from our storage service
    this.storageRef = this.storage.ref();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    this.messaging = null;
    if (app.messaging.isSupported()) {
      this.messaging = app.messaging();
      this.messaging.usePublicVapidKey(
        process.env.REACT_APP_MESSAGING_PUSH_CERT
      );

      // Handle token refresh
      this.messaging.onTokenRefresh(() => {
        this.messaging
          .getToken()
          .then(refreshedToken => {
            console.log('refreshedToken :>> ', refreshedToken);
          })
          .catch(err => {
            console.error('refreshedToken Error', err);
          });
      });

      this.messaging.onMessage(payload => {
        console.log('Message received :>> ', payload);
        // ...
      });
    }
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getUser(authUser.uid).then(user => {
          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...user
          };

          next(authUser);
        });
      } else {
        fallback();
      }
    });

  // FIRESTORE

  // *** User API ***
  getUsers = async () => {
    const response = await fetch(`${this.functions}/api/users`);
    return await response.json();
  };

  getUsersWithArgs = async args => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ args })
    };

    const response = await fetch(`${this.functions}/api/users`, requestOptions);
    return await response.json();
  };

  getUser = async uid => {
    const response = await fetch(`${this.functions}/api/users/${uid}`);
    return await response.json();
  };

  editUser = async (uid, body) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${btoa(uid)}`
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(
      `${this.functions}/api/users/${uid}`,
      requestOptions
    );
    const user = await response.json();

    if (user && (body.data.firstName || body.data.lastName)) {
      const args = {
        filters: [
          {
            field: 'contributorRef',
            condition: '==',
            value: user.uid
          }
        ]
      };

      const userPosts = await this.getPostsWithArgs(args);

      for (const post of userPosts) {
        const body = {
          data: {
            contributor: user
          },
          merge: true
        };

        this.editPost(post.uid, body, user.uid);
      }
    }

    return user;
  };

  saveNotifications = async (uid, body) => {
    body.url = process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${btoa(uid)}`
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(
      `${this.functions}/api/users/saveNotifications`,
      requestOptions
    );
    return await response.json();
  };

  searchUsers = async q => {
    const response = await fetch(`${this.functions}/api/searchUsers?q=${q}`);
    return await response.json();
  };

  user = uid => this.store.doc(`users/${uid}`);

  users = () => this.store.collection('users');

  // *** Article API ***
  getPosts = async () => {
    const response = await fetch(`${this.functions}/api/posts`);
    return await response.json();
  };

  getRandomPosts = async args => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ args })
    };

    const response = await fetch(
      `${this.functions}/api/getRandomPosts`,
      requestOptions
    );
    return await response.json();
  };

  getPostsWithArgs = async args => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ args })
    };

    const response = await fetch(`${this.functions}/api/posts`, requestOptions);
    return await response.json();
  };

  getPostsWithArgsAlt = async args => {
    const { filters, limit, orders } = args;

    let query = this.store.collection('articles');
    const posts = new Set();

    if (filters) {
      for (const filter of filters) {
        if (filter.field && filter.condition && filter.value) {
          if (filter.field === 'documentId') {
            query = query.where(
              this.fieldPath.documentId(),
              filter.condition,
              filter.value
            );
          } else {
            query = query.where(filter.field, filter.condition, filter.value);
          }
        }
      }
    }

    if (limit) {
      query = query.limit(+limit);
    }

    if (orders) {
      for (const order of orders) {
        if (order.field === 'documentId') {
          query = query.orderBy(this.fieldPath.documentId(), order.value);
        } else {
          query = query.orderBy(order.field, order.value);
        }
      }
    }

    return new Promise((resolve, reject) => {
      query.onSnapshot(function(querySnapshot) {
        if (!querySnapshot) reject('Post not valid');

        querySnapshot.forEach(async function(doc) {
          const post = doc.data();

          if (!post.contributor) {
            const existingUser = [...posts].filter(p => {
              return post.contributorRef === p.contributorRef;
            });

            if (existingUser.length > 0) {
              post.contributor = existingUser[0].contributor;
            } else {
              const userRef = await this.getUser(post.contributorRef);

              if (userRef.error) {
                console.error('contributorRef :>> ', post.contributorRef);
                console.error('error :>> ', userRef.error);
              } else {
                post.contributor = userRef;
              }
            }

            const contributor = post.contributor;
            this.editPost(doc.id, { contributor });
          }

          posts.add({ ...post, uid: doc.id });
        });
        console.log('fb posts :>> ', posts);

        resolve([...posts]);
      });
    });
  };

  getPost = async uid => {
    const response = await fetch(`${this.functions}/api/posts/${uid}`);
    return await response.json();
  };

  getPostLive = async uid => {
    return new Promise((resolve, reject) => {
      this.store.doc(`articles/${uid}`).onSnapshot(function(docSnapshot) {
        if (!docSnapshot) reject('Posts not valid');

        // console.log('docSnapshot :>> ', docSnapshot);
        resolve({ ...docSnapshot.data(), uid });
      });
    });
  };

  addPost = async post => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${btoa(post.contributorRef)}`
      },
      body: JSON.stringify({ post })
    };

    const response = await fetch(
      `${this.functions}/api/addPost`,
      requestOptions
    );
    return await response.json();
  };

  editPost = async (postId, body, uid) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${btoa(uid)}`
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(
      `${this.functions}/api/posts/${postId}`,
      requestOptions
    );
    return await response.json();
  };

  deletePost = async (postId, uid) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${btoa(uid)}`
      }
    };

    const response = await fetch(
      `${this.functions}/api/posts/${postId}`,
      requestOptions
    );
    return await response.json();
  };

  searchPosts = async q => {
    const response = await fetch(`${this.functions}/api/searchPosts?q=${q}`);
    return await response.json();
  };

  article = uid => this.store.doc(`articles/${uid}`);

  articles = () => this.store.collection('articles');

  // *** Category API ***

  getCategories = async () => {
    const response = await fetch(`${this.functions}/api/categories`);
    return await response.json();
  };

  category = uid => this.store.doc(`categories/${uid}`);

  categories = () => this.store.collection('categories');

  // *** Tags API ***

  getTags = async () => {
    const response = await fetch(`${this.functions}/api/tags`);
    return await response.json();
  };

  getTagsWithArgs = async args => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ args })
    };

    const response = await fetch(`${this.functions}/api/tags`, requestOptions);
    return await response.json();
  };

  tag = uid => this.store.doc(`tags/${uid}`);

  tags = () => this.store.collection('tags');

  searchTags = async tags => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tags })
    };

    const response = await fetch(
      `${this.functions}/api/searchTags`,
      requestOptions
    );
    return await response.json();
  };
}

export default Firebase;
