/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';

import SignOutPage from '../../../../../../components/SignOut';
import { SIGN_IN } from '../../../../../../constants/routes';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, authUser, onClose } = props;

  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        {pages.map(page => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              onClick={onClose}
              exact>
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}

        {!!authUser && (
          <ListItem className={classes.item} disableGutters key="SignOut">
            <SignOutPage onClose={onClose} />
          </ListItem>
        )}

        {!authUser && (
          <ListItem className={classes.item} disableGutters key="SignIn">
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={SIGN_IN}
              onClick={onClose}
              exact>
              <div className={classes.icon}>
                <LockOpenSharpIcon />
              </div>
              Sign In
            </Button>
          </ListItem>
        )}
      </List>
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  mobilePages: PropTypes.array.isRequired,
  onClose: PropTypes.func
};

export default SidebarNav;
