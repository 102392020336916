export const styles = theme => ({
  '@global': {
    a: {
      // color: theme.palette.primary.main
      color: '#fff'
    }
  },
  contentContainer: {
    padding: theme.spacing(0)
  },

  articleBody: {
    padding: 0,
    fontSize: 14,
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    lineHeight: 1.3572,
    letterSpacing: 0.46,
    color: 'inherit',
    '& .text-wrap': {
      display: 'flex',
      flexWrap: 'wrap'
    },
    '& .text-p-style': {
      marginRight: '3px',
      wordBreak: 'break-word',
      minHeight: '19px'
    },
    '& .link-style': {
      marginRight: '3px',
      wordBreak: 'break-word'
    }
  },
  cardContainer: {
    borderRadius: '10px',
    margin: theme.spacing(0, 2, 2, 2)
  },
  wrapTime: {
    padding: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0'
  },
  wrapTittle: {
    padding: theme.spacing(0, 2),
    margin: '5px 0'
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  videoContainer: {
    marginBottom: theme.spacing(1)
  },
  videoPlayer: {
    height: 240,
    '& iframe': {
      display: 'block'
    },
    padding: '16px'
  },
  mediaContainer: {
    position: 'relative',
    height: 252,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      height: 352
    },
    [theme.breakpoints.up('lg')]: {
      height: 452
    }
  },
  postingTime: {
    display: 'inline-block',
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    letterSpacing: 0.27,
    color: 'inherit',
    lineHeight: '14px'
  },
  imageContainer: {
    height: '100%'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  mediaTopContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  mediaBottomContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  readingTimeIcon: {
    fontSize: 16
  },
  readingTimeValue: {
    marginLeft: 3,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit'
  },
  tagList: {
    listStyle: 'none'
  },
  tagItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 6px',
    fontSize: 12,
    lineHeight: '1em',
    borderRadius: '16px',
    backgroundColor: '#ffffff'
  },
  contributorContainer: {
    width: 'auto',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
    display: 'flex',
    boxShadow: 'none'
  },
  contentPaper: {
    padding: theme.spacing(1),
    borderRadius: '10px',
    margin: theme.spacing(1, 2, 2, 2),
    fontColor: '#fff',
    boxShadow: 'none'
  },
  actionsContainer: {
    color: '#3C3C3C',
    padding: theme.spacing(0, 2, 1, 2)
  },
  likeButton: {
    padding: 0,
    fontSize: 10,
    color: 'inherit',
    borderRadius: 0,
    marginRight: theme.spacing(3)
  },
  likeIcon: {
    fontSize: '18px',
    '&.liked': {
      color: theme.palette.text.brand
    },
    marginRight: '2px'
  },
  likeValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  commentButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0,
    marginRight: theme.spacing(2)
  },
  commentIcon: {
    fontSize: 20,
    marginRight: '2px'
  },
  commentValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  },
  category: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  favoriteBtn: {
    padding: 0,
    fontSize: 12,
    lineHeight: '16px',
    color: 'inherit',
    fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
    borderRadius: 0
  },
  bookmarkIcon: {
    fontSize: '1.75em',
    '&.saved': {
      color: theme.palette.text.brand
    }
  },
  textSize: {
    fontSize: '12px',
    lineHeight: '14px'
  },
  author: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    paddingTop: theme.spacing(1)
  },
  spanBookmark: {
    marginLeft: theme.spacing(2)
  },
  wrapComment: {
    padding: theme.spacing(0, 2)
  },
  wrapCommentLink: {
    padding: theme.spacing(1, 2)
  },
  commentLinkText: {
    fontSize: 12,
    cursor: 'pointer'
  },
  wrapCommentInput: {
    padding: theme.spacing(1, 2)
  },
  commentInput: {
    width: '100%',
    border: '1px solid #7070701A',
    borderRadius: '2px',
    fontSize: '14px',
    height: '35px',
    padding: '1px 10px 0 10px',
    backgroundColor: 'rgba(87, 156, 177, 0.2)'
  },
  summaryWrap: {
    margin: '10px 0px',
    lineHeight: 'normal',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    '& .text-wrap': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'pre',
      flexWrap: 'wrap'
    },
    '& .link-style': {
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },
    color: '#fff'
  },
  forThought240: {
    margin: '10px 0px',
    fontSize: '24px',
    '& .text-wrap': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'pre',
      flexWrap: 'wrap'
    },
    '& .link-style': {
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },
    color: '#fff'
  },
  btnAdd: {
    backgroundColor: 'rgba(166,181,185,0.17)',
    color: '#727272',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'end',
    '& .MuiButton-label': {
      fontSize: '12px',
      textTransform: 'none'
    },
    '& .MuiButton-contained:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(87, 156, 177, 0.2)',
      color: '#707070'
    },
    borderRadius: '9px',
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  addIconContainer: {
    padding: 0,
    flex: 1,
    width: '100%',
    textAlign: 'right'
  },
  addIcon: {
    padding: 0,
    fontSize: 21,
    color: '#727272',
    flex: 1,
    verticalAlign: 'bottom'
  },
  addAvatar: {
    width: 33,
    height: 33,
    marginRight: theme.spacing(2)
  }
});

export default styles;
