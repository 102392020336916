import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';

import {
  ARTICLES,
  LANDING,
  PROFILE,
  SIGN_IN,
  SEARCH,
  NOTIFICATIONS,
  ACCOUNT,
  TRENDS,
  FOLLOWERS,
  FOLLOWING,
  POPULAR_USERS,
  ALL_VIDEOS,
  SETTINGS,
  PRIVACY,
  NEW_POST,
  PASSWORD_FORGET,
  SIGN_UP,
  AUTHOR
} from '../../constants/routes';

const POST = '/post';

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.topBar,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  pageTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
    fontSize: 26,
    paddingLeft: theme.spacing(2),
    letterSpacing: 2,
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      fontSize: 30
    }
  },
  content: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  avatar: {
    width: 30,
    height: 30
  }
});

const INITIAL_STATE = {
  loading: false
};

const titles = {};
class Heading extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.onSetTitles();
  }

  onSetTitles = () => {
    this.unsubscribe = async function unsubscribe() {
      titles[ARTICLES] = 'Feed';
      titles[LANDING] = 'Feed';
      titles[PROFILE] = 'Profile';
      titles[ACCOUNT] = 'Settings';
      titles[SETTINGS] = 'Settings';
      titles[TRENDS] = 'Trends';
      titles[SEARCH] = 'Search';
      titles[NOTIFICATIONS] = 'Notifications';
      titles[FOLLOWERS] = 'Followers';
      titles[FOLLOWING] = 'Following';
      titles[POPULAR_USERS] = 'Popular Users';
      titles[ALL_VIDEOS] = 'Videos';
      titles[PRIVACY] = 'Privacy Policy';
      titles[NEW_POST] = 'New Post';
      titles[PASSWORD_FORGET] = 'Forgot Password';
      titles[SIGN_IN] = 'Sign In';
      titles[SIGN_UP] = 'Sign Up';
      titles[AUTHOR] = 'Author';
      titles[POST] = 'Post';
      titles['/folowers'] = 'Followers';
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { classes, location } = this.props;

    let path = location.pathname;

    const count = (location.pathname.match(/\//g) || []).length;

    if (count === 2) {
      path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

      if (path === ARTICLES) {
        path = POST;
      }
    } else if (count === 3) {
      path = location.pathname.substring(
        location.pathname.lastIndexOf('/'),
        location.pathname.length
      );
    }

    const title = titles[path];

    return (
      <div className={classes.root} elevation={1}>
        <Grid item lg={12} md={12} xs={12} className={classes.content}>
          <Typography className={classes.pageTitle} variant="h1">
            {title}
          </Typography>
        </Grid>
      </div>
    );
  }
}

export default compose(withRouter)(withStyles(styles)(Heading));
