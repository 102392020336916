export const styles = theme => ({
  root: {},
  label: {
    position: 'static',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    padding: theme.spacing(0, 2)
  },
  input: {
    padding: theme.spacing(2, 2),
    fontSize: 12,
    lineHeight: 1.3334,
    letterSpacing: 0.11,
    color: theme.palette.text.secondary,

    '& .MuiInputBase-input ': {
      height: '250px !important',
      maxHeight: '250px !important',
      overflow: 'auto !important'
    }
    // '& .MuiInputBase-input::-webkit-scrollbar': {
    //   width: '1px'
    // }
  }
});

export default styles;
