export const styles = theme => ({
  root: { padding: theme.spacing(3, 0) },
  headerGrid: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  },
  filterGrid: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30%',
      marginRight: '30%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '30%',
      marginRight: '30%'
    }
  },
  articlesGrid: {
    marginBottom: theme.spacing(3)
  },
  header: {
    padding: theme.spacing(0, 2)
  },
  pageTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,
    letterSpacing: 0
  },
  pageSubtitle: {
    fontSize: 14,
    lineHeight: 1.4286
  },
  progress: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default styles;
