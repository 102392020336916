import React, { Component, lazy, Suspense } from 'react';
// import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  LinearProgress,
  CircularProgress,
  Typography,
  Grid
} from '@material-ui/core';

import { nanoid } from 'nanoid';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import {
  allArticlesSelector,
  limitSelector,
  categorySelector
} from './selectors';
import { changeUserCoins, getStrippedString } from '../../common/Helpers';
import { ARTICLES, SIGN_IN } from '../../constants/routes';
import { QUESTION, VIDEO, THOUGHT } from '../../constants/postTypes';
import { AlertTitle } from '@material-ui/lab';

const ArticleListComponent = lazy(() => import('../Articles/ArticleList'));
const NewPostFieldLinkComponent = lazy(() =>
  import('../NewPost/components/NewPostFieldLink')
);

const renderLoader = () => <p>Loading</p>;

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4)
    }
  },
  signUpAlert: {
    margin: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 0, 2, 0),
      margin: theme.spacing(0, '30%', 0, '30%')
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0, 2, 0),
      margin: theme.spacing(0, '30%', 0, '30%')
    }
  },
  pageTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
    fontSize: 26,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      fontSize: 30
    }
  },
  content: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(0, 40, 0, 40)
    }
  },
  newPostLink: {
    margin: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 0, 2, 0),
      margin: theme.spacing(0, '31.3%', 0, '31.3%')
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0, 2, 0),
      margin: theme.spacing(0, '31.3%', 0, '31.3%')
    }
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  moreButton: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  loadingMore: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  refresh: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageSpanWrap: {
    margin: theme.spacing(0, 2, 0.5, 2),
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  pageSpan: {
    [theme.breakpoints.up('lg')]: {
      width: '40%'
    },
    [theme.breakpoints.up('md')]: {
      width: '40%'
    },
    fontWeight: 400,
    fontSize: 16,
    color: '#707070'
  }
});

const loadMore = 6;

class Articles extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingMore: false,
      showRefresh: false,
      refresh: false
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      if (!this.props.articles.length) {
        this.setState({ loading: true });
      }

      this.onListenForArticles();

      const messaging = this.props.firebase.messaging;
      if (messaging) {
        const that = this;

        messaging
          .requestPermission()
          .then(async function() {
            const token = await messaging.getToken();
            that.saveUserMessageToken(token);
          })
          .catch(function(err) {
            console.log('Unable to get permission to notify.', err);
          });

        navigator.serviceWorker.addEventListener('message', message =>
          console.log(message)
        );
      }
    }

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', this.handleRefresh);
  }

  componentDidUpdate(props) {
    if (this._isMounted) {
      if (props.category !== this.props.category) {
        this.setState({
          loading: true
        });
      }

      if (
        props.limit !== this.props.limit ||
        props.category !== this.props.category
      ) {
        this.onListenForArticles();
      }
    }
  }

  updateArticleComments = (commentId, comment, doUpdateUserCoins, article) => {
    const { firebase, articles, onSetArticles } = this.props;
    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };
    firebase
      .article(article.uid)
      .set(
        {
          comments
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        doUpdateUserCoins();
        onSetArticles(newArticles);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  onCommentLikeClick = (commentId, articleId) => {
    const { authUser, articles } = this.props;

    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the comment'
      );
      return;
    }

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid),
        article
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid),
        article
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid),
      article
    );
  };

  onListenForArticles = () => {
    this.unsubscribe = async function unsubscribe() {
      // if (
      //   !this.props.articles.length ||
      //   this.state.loadingMore ||
      //   this.state.refresh ||
      //   this.state.showRefresh
      // ) {
      try {
        const args = {
          filters: [
            {
              field: 'status',
              condition: '==',
              value: 'published'
            },
            {
              field: 'type',
              condition: 'in',
              value: [QUESTION, VIDEO, THOUGHT]
            }
          ],
          orders: [
            {
              field: 'popularityScore',
              value: 'desc'
            },
            {
              field: 'created',
              value: 'desc'
            }
          ],
          limit: this.props.limit
        };

        const posts = await this.props.firebase.getPostsWithArgs(args);

        this.props.onSetArticles(posts);
      } catch (error) {
        this.props.onSetArticles([]);
        console.log('error :>> ', error);
      } finally {
        this.setState({
          loading: false,
          loadingMore: false,
          refresh: false
        });
      }
      //}
    };

    // RANDOM LIST
    // this.unsubscribe = async function unsubscribe() {
    //   if (
    //     !this.props.articles.length ||
    //     this.state.loadingMore ||
    //     this.state.refresh ||
    //     this.state.showRefresh
    //   ) {
    //     try {
    //       const args = {
    //         limit: this.props.limit
    //       };

    //       if (this.props.authUser && this.props.authUser.uid) {
    //         args.userId = this.props.authUser.uid;
    //       }
    //       if (this.state.loadingMore) {
    //         args.loadMore = true;
    //       }

    //       const posts = await this.props.firebase.getRandomPosts(args);

    //       this.props.onSetArticles(posts);
    //     } catch (error) {
    //       this.props.onSetArticles([]);
    //       console.log('error :>> ', error);
    //     } finally {
    //       this.setState({
    //         loading: false,
    //         loadingMore: false,
    //         refresh: false
    //       });
    //     }
    //   }
    // };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;

    //this.unsubscribe();
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleRefresh);
  }

  saveUserMessageToken = messageToken => {
    const { authUser, firebase, onSetAuthUser } = this.props;

    if (!authUser) return;

    const messageTokens = authUser.messageTokens || [];
    if (messageTokens.indexOf(messageToken) === -1) {
      messageTokens.push(messageToken);
    }

    const updatedUser = {
      ...authUser,
      messageToken
    };

    const body = {
      data: {
        messageTokens
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  handleScroll = () => {
    const { loadingMore } = this.state;

    const isBottom =
      document.documentElement.scrollHeight - window.innerHeight <
      window.scrollY + 20;

    if (!isBottom || loadingMore) return;

    this.onLoadMore();
  };

  handleRefresh = () => {
    const { loading, loadingMore, refresh } = this.state;

    if (!window.scrollY <= 0 || loading || loadingMore || refresh) return;

    this.setState({
      showRefresh: true
    });
  };

  doRefresh = () => {
    this.setState({
      showRefresh: false,
      refresh: true
    });

    this.onListenForArticles();
  };

  onLoadMore = () => {
    if (this._isMounted) {
      this.setState({
        loadingMore: true
      });
      this.props.onSetArticlesLimit(this.props.limit + loadMore);
    }
  };

  onLikeClick = articleId => {
    const { authUser, articles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'Approve the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { articles, firebase, onSetArticles, authUser } = this.props;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(articleId, body, authUser.uid)
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        onSetArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  deletePost = articleId => {
    const { authUser, firebase, articles, onSetArticles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!article) {
      console.error('There is no article found');
      return;
    }

    firebase
      .deletePost(articleId, authUser.uid)
      .then(() => {
        this.cleanReferences({ ...article });
        const newArticles = articles.filter(a => a.uid !== articleId);
        onSetArticles(newArticles);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = articleId => {
    const { firebase } = this.props;

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: articleId
        }
      ]
    };

    firebase
      .getUsersWithArgs(args)
      .then(users => {
        users.forEach(async user => {
          const newSavedArticles = user.savedArticles.filter(
            id => id !== articleId
          );

          const body = {
            data: {
              savedArticles: newSavedArticles
            }
          };

          await firebase.editUser(user.uid, body);
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  onCommentFocus = e => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      e.target.blur();
      showSighInDialog({ pathname: `${ARTICLES}` }, 'response the post');
      return;
    }
  };

  makeNewComment = (comment, articleId) => {
    const { authUser, firebase, articles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    const id = nanoid();
    const isNewComment = true;

    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date())
    };
    const comments = article.comments ? { ...article.comments } : {};
    comments[id] = { ...newComment };

    this.updateComments(comments, isNewComment, article);
  };

  updateComments = (comments, isNewComment = false, article) => {
    const { firebase, articles, onSetArticles, history, authUser } = this.props;

    const body = {
      data: {
        comments: { ...comments }
      }
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response', article);
        }
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        onSetArticles(newArticles);
        history.push({
          pathname: `${ARTICLES}/${article.uid}`,
          state: { fromArticles: true }
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  makeNotification = (type, article) => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;

    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification, article);
  };

  sendNotification = async (notification, article) => {
    const { firebase, authUser } = this.props;
    const followers = await this.collectUsersToNotification(article);

    const body = {
      followers,
      notification
    };
    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  collectUsersToNotification = async article => {
    const { authUser, firebase } = this.props;

    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: article.uid
        }
      ]
    };

    const savedArticleUsersRef = await firebase.getUsersWithArgs(args);

    if (savedArticleUsersRef.length > 0) {
      for (const userRef of savedArticleUsersRef) {
        if (userRef.uid !== authUser.uid) {
          collectedUsers.push(userRef.uid);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };

  render() {
    const { articles, classes, authUser } = this.props;
    const { loading, loadingMore, refresh } = this.state;
    const savedArticlesIds = (authUser && authUser.savedArticles) || [];
    // const greetingTime = getGreetingTime(moment());
    // const greeting = authUser
    //   ? `${greetingTime} ${authUser.firstName || authUser.username}`
    //   : `${greetingTime}`;
    //const message = authUser
    //  ? 'Your latest feed…'
    //  : 'Join the discussion! Sign-up using your social account in just a few clicks!';
    // const message = 'Your latest feed…';
    const isAdmin = authUser && authUser.role === 'ADMIN';

    return (
      <div>
        {(loading || refresh) && (
          <div>
            <LinearProgress />
          </div>
        )}

        {/* {showRefresh && (
          <div className={classes.refresh}>
            <IconButton onClick={this.doRefresh}>
              <RefreshIcon /> Refresh
            </IconButton>
          </div>
        )} */}

        <div className={classes.root}>
          {/* <Grid item lg={12} md={12} xs={12} className={classes.content}>
            <Typography className={classes.pageTitle} variant="h1">
              {greeting}
            </Typography>
          </Grid> */}

          <Grid className={classes.newPostLink} item lg={12} md={12} xs={12}>
            <Suspense fallback={renderLoader()}>
              <NewPostFieldLinkComponent authUser={authUser} />
            </Suspense>
          </Grid>

          {!authUser && (
            <Grid>
              <div className={classes.signUpAlert}>
                <Alert
                  elevation={1}
                  severity="error"
                  color="success"
                  action={
                    <Button color="inherit" size="small">
                      SIGN UP
                    </Button>
                  }
                  component={RouterLink}
                  to={SIGN_IN}>
                  <AlertTitle>Join the discussion!</AlertTitle>
                  Sign-up using your social account in just a few clicks!
                </Alert>
              </div>
            </Grid>
          )}

          {!loading && (!articles || articles.length === 0) && (
            <div>
              <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="center" gutterBottom variant="body1">
                  There are no articles available
                </Typography>
              </Grid>
            </div>
          )}

          {!loading && articles && articles.length > 0 && (
            <div>
              {/* <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="left" gutterBottom variant="h3">
                  {heading}
                </Typography>

                <Divider className={classes.divider} />
              </Grid> */}

              <Suspense fallback={renderLoader()}>
                <ArticleListComponent
                  userId={authUser ? authUser.uid : null}
                  articles={articles}
                  isEditable={isAdmin}
                  savedArticlesIds={savedArticlesIds}
                  onLikeClick={this.onLikeClick}
                  onSaveClick={this.onSaveClick}
                  onDeleteClick={this.deletePost}
                  onCommentFocus={this.onCommentFocus}
                  makeNewComment={this.makeNewComment}
                  onCommentLikeClick={this.onCommentLikeClick}
                />
              </Suspense>
            </div>
          )}

          {loadingMore && (
            <div className={classes.loadingMore}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className={classes.loadingMore}>
                <CircularProgress />
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  articles: allArticlesSelector(state),
  limit: limitSelector(state),
  category: categorySelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  onSetArticles: articles => dispatch({ type: 'ARTICLES_SET', articles }),
  onSetArticlesLimit: limit => dispatch({ type: 'ARTICLES_LIMIT_SET', limit }),
  showAlert: alert => dispatch({ type: 'ALERT_SET', alert }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withRouter,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(Articles));
