/* eslint-disable no-loop-func*/
import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  Button,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Paper,
  LinearProgress,
  Divider,
  Modal
} from '@material-ui/core';

import { TabContext } from '@material-ui/lab';
import { People, ThumbUp } from '@material-ui/icons';

import {
  ACCOUNT,
  FOLLOWERS,
  FOLLOWING,
  ARTICLES
} from '../../constants/routes';
import { withEmailVerification, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import {
  changeUserCoins,
  getStrippedString,
  formatDate
} from '../../common/Helpers';

import ArticleList from '../Articles/ArticleList';
import {
  styles,
  StyledTabPanel,
  StyledTab,
  StyledTabList
} from './Profile.style';
import { nanoid } from 'nanoid';
import ReactTextFormat from 'react-text-format';

const DEFAULT_AVATAR = '/images/default-avatar.png';
const INITIAL_STATE = {
  savedArticles: [],
  usersArticles: [],
  currentArticleList: 'saved',
  error: null,
  loading: true,
  progress: 1,
  showFullDescription: false,
  modalIsOpen: false
};

const MAX_CHARACTERS = 240;

const ProfilePage = () => (
  <div>
    <Grid>
      <ProfileDetails />
    </Grid>
  </div>
);

class ProfileDetailsBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getArticles();
  }

  async getArticles() {
    const { authUser, firebase } = this.props;
    let savedArticles = [];
    let usersArticles = [];

    if (authUser.savedArticles && authUser.savedArticles.length > 0) {
      let allData = [];

      for (let i = 0; i < authUser.savedArticles.length; i += 10) {
        const requests = [];

        const args = {
          filters: [
            {
              field: 'status',
              condition: '==',
              value: 'published'
            },
            {
              field: 'documentId',
              condition: 'in',
              value: authUser.savedArticles.slice(i, i + 10)
            }
          ]
        };

        const data = await firebase.getPostsWithArgs(args);
        requests.push(data);

        await Promise.all(requests)
          .then(a => {
            allData = [...allData, ...a[0]];

            allData.sort((a, b) => {
              return formatDate(b.created) - formatDate(a.created);
            });
            savedArticles = [...allData];
          })
          .catch(e => console.log(e));
      }
    }

    const args = {
      filters: [
        {
          field: 'contributorRef',
          condition: '==',
          value: authUser.uid
        }
      ]
    };

    usersArticles = await firebase.getPostsWithArgs(args);

    const anonimArg = {
      filters: [
        {
          field: 'originalContributor',
          condition: '==',
          value: authUser.uid
        }
      ]
    };

    usersArticles = [
      ...usersArticles,
      ...(await firebase.getPostsWithArgs(anonimArg))
    ];

    usersArticles.sort((a, b) => {
      return formatDate(b.created) - formatDate(a.created);
    });

    this.setState({
      savedArticles,
      usersArticles,
      loading: false
    });
  }

  onLikeClick = articleId => {
    const { authUser } = this.props;
    const { savedArticles } = this.state;

    const article = savedArticles.find(a => a.uid === articleId);

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { firebase, authUser } = this.props;
    const { savedArticles } = this.state;

    const body = {
      data: {
        likes,
        likesCount: likes.length
      },
      merge: true
    };

    firebase
      .editPost(articleId, body, authUser.uid)
      .then(() => {
        const newArticles = [...savedArticles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        this.setState({
          savedArticles: newArticles
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = articleId => {
    const { savedArticles } = this.props.authUser;

    const articles = savedArticles.filter(a => a !== articleId);
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { savedArticles } = this.state;

    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    const body = {
      data: {
        savedArticles: articles
      },
      merge: true
    };

    firebase
      .editUser(authUser.uid, body)
      .then(() => {
        onSetAuthUser(updatedUser);
        const newSavedArticles = savedArticles.filter(a =>
          articles.includes(a.uid)
        );
        this.setState({
          savedArticles: newSavedArticles
        });
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  switchArticleList = (event, value) => {
    this.setState({
      currentArticleList: value
    });
  };

  deleteUserPost = articleId => {
    const { firebase, authUser } = this.props;
    const { usersArticles } = this.state;
    const article = usersArticles.find(a => a.uid === articleId);

    if (!article) {
      console.error('There is no article found');
      return;
    }

    firebase
      .deletePost(articleId, authUser.uid)
      .then(() => {
        this.cleanReferences({ ...article });
        const newUsersArticles = usersArticles.filter(a => a.uid !== articleId);

        this.setState({
          usersArticles: [...newUsersArticles]
        });
      })
      .catch(err => {
        console.error('error: ', err);
        this.setState({
          loading: false
        });
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = articleId => {
    const { firebase } = this.props;

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: articleId
        }
      ]
    };

    firebase
      .getUsersWithArgs(args)
      .then(users => {
        users.forEach(async user => {
          const newSavedArticles = user.savedArticles.filter(
            id => id !== articleId
          );

          const body = {
            data: {
              savedArticles: newSavedArticles
            }
          };

          await firebase.editUser(user.uid, body);
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  onCommentLikeClick = (commentId, articleId) => {
    const { authUser } = this.props;
    const { savedArticles, usersArticles, currentArticleList } = this.state;

    const articles =
      currentArticleList === 'saved' ? savedArticles : usersArticles;

    const article = articles.find(a => a.uid === articleId);

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid),
        article
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid),
        article
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid),
      article
    );
  };

  updateArticleComments = (commentId, comment, doUpdateUserCoins, article) => {
    const { firebase } = this.props;
    const { savedArticles, usersArticles, currentArticleList } = this.state;
    const articles =
      currentArticleList === 'saved' ? savedArticles : usersArticles;

    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };
    firebase
      .article(article.uid)
      .set(
        {
          comments
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === article.uid);
        if (articleIndex !== -1) {
          newArticles[articleIndex].comments = { ...comments };
        }
        doUpdateUserCoins();
        currentArticleList === 'saved'
          ? this.setState({ savedArticles: newArticles })
          : this.setState({ usersArticles: newArticles });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  makeNewComment = (comment, articleId) => {
    const { authUser, firebase } = this.props;
    const { savedArticles, usersArticles, currentArticleList } = this.state;
    const articles =
      currentArticleList === 'saved' ? savedArticles : usersArticles;

    const article = articles.find(a => a.uid === articleId);

    const id = nanoid();
    const isNewComment = true;

    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date())
    };
    const comments = article.comments ? { ...article.comments } : {};
    comments[id] = { ...newComment };

    this.updateComments(comments, isNewComment, article);
  };

  updateComments = (comments, isNewComment = false, article) => {
    const { firebase, history, authUser } = this.props;

    const body = {
      data: {
        comments: { ...comments }
      }
    };

    firebase
      .editPost(article.uid, body, authUser.uid)
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response', article);
        }

        history.push({
          pathname: `${ARTICLES}/${article.uid}`,
          state: { fromArticles: true }
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  makeNotification = (type, article) => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;

    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: nanoid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification, article);
  };

  sendNotification = async (notification, article) => {
    const { firebase, authUser } = this.props;
    const followers = await this.collectUsersToNotification(article);

    const body = {
      followers,
      notification
    };
    firebase
      .saveNotifications(authUser.uid, body)
      .then(() => {
        console.log('Notifications send');
      })
      .catch(err => {
        console.error('sendNotification error :>> ', err);
      });
  };

  collectUsersToNotification = async article => {
    const { authUser, firebase } = this.props;

    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    const args = {
      filters: [
        {
          field: 'savedArticles',
          condition: 'array-contains',
          value: article.uid
        }
      ]
    };

    const savedArticleUsersRef = await firebase.getUsersWithArgs(args);

    if (savedArticleUsersRef.length > 0) {
      for (const userRef of savedArticleUsersRef) {
        if (userRef.uid !== authUser.uid) {
          collectedUsers.push(userRef.uid);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };
  onClickDescription = value => {
    this.setState({ showFullDescription: value });
    if (!value) {
      window.scrollTo(0, 0);
    }
  };

  handleOpenModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };
  render() {
    const { classes, authUser } = this.props;
    const {
      loading,
      savedArticles,
      usersArticles,
      currentArticleList,
      showFullDescription,
      modalIsOpen
    } = this.state;

    const {
      firstName,
      lastName,
      username,
      follows,
      followers,
      coins
    } = authUser;
    const coinsCount = coins || 0;
    let sortedSavedArticles = null;
    let sortedUsersArticles = null;
    const avatar = authUser.avatar || DEFAULT_AVATAR;
    const fullName =
      firstName && lastName ? `${firstName} ${lastName}` : username;
    const status = authUser.memberStatus || '';

    const isDescription = authUser.description || '';
    const description =
      isDescription && !showFullDescription
        ? getStrippedString(authUser.description, MAX_CHARACTERS)
        : isDescription;
    const lengthDescription =
      isDescription.length > 240 ? description : isDescription;
    const showDescription = !showFullDescription ? 'Read more' : 'Show less';

    const followsValue = follows ? follows.length : 0;
    const followersValue = followers ? followers.length : 0;
    const userSavedArticlesIds = authUser.savedArticles || [];

    if (savedArticles && savedArticles.length > 1) {
      sortedSavedArticles = authUser.savedArticles.reverse().map(id => {
        return savedArticles.find(a => a.uid === id);
      });
    }

    if (usersArticles && usersArticles.length > 1) {
      sortedUsersArticles = usersArticles.sort(
        (a, b) => formatDate(b.created) - formatDate(a.created)
      );
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Grid container direction="column">
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={modalIsOpen}
                onClose={this.handleOpenModal}
                className={classes.modal}>
                {
                  <Avatar
                    alt="Avatar"
                    className={classes.bigAvatar}
                    src={avatar}
                  />
                }
              </Modal>
              <Grid className={classes.mainContainer} container item>
                <Grid className={classes.userWrap} item xs={12}>
                  <Card className={classes.userCard}>
                    <Grid
                      className={classes.userCardHeader}
                      container
                      wrap="nowrap"
                      spacing={2}>
                      <Grid onClick={this.handleOpenModal} item>
                        <Avatar
                          alt="Avatar"
                          className={classes.userAvatar}
                          src={avatar}
                        />
                      </Grid>
                      <Grid item className={classes.userInfo}>
                        <Typography variant="h5">{fullName}</Typography>
                        <Typography component="span" variant="body1">
                          {status}
                        </Typography>
                      </Grid>
                    </Grid>
                    {isDescription && (
                      <CardContent className={classes.userDescription}>
                        <Typography className={classes.descriptionText}>
                          <ReactTextFormat allowedFormats={['URL']}>
                            {lengthDescription}
                          </ReactTextFormat>
                        </Typography>
                        {isDescription.length > 240 && (
                          <Typography
                            onClick={() =>
                              this.onClickDescription(!showFullDescription)
                            }
                            className={classes.textColor}>
                            {showDescription}
                          </Typography>
                        )}
                      </CardContent>
                    )}
                  </Card>
                </Grid>
                <Grid
                  className={classes.folowBtnWrap}
                  container
                  item
                  wrap="nowrap">
                  <Grid item xs={3} className={classes.statistic1}>
                    <Paper className={classes.statistic}>
                      <ThumbUp className={classes.statisticIcon} />
                      <Typography className={classes.likeValue}>
                        {coinsCount}{' '}
                        {coinsCount === 1 ? ` Approve` : ` Approvals`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs className={classes.statistic2}>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={FOLLOWERS}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followersValue} Followers`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs className={classes.statistic3}>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={FOLLOWING}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followsValue} Following`}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid className={classes.folowBtn} item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    to={ACCOUNT}
                    component={RouterLink}
                    exact>
                    Settings
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <TabContext value={currentArticleList}>
                    <StyledTabList
                      onChange={this.switchArticleList}
                      variant="fullWidth"
                      aria-label="switch current article list">
                      <StyledTab
                        className={classes.tabsWrapLeft}
                        label="Saved Posts"
                        value="saved"
                      />
                      <StyledTab
                        className={classes.tabsWrapRight}
                        label="My Posts"
                        value="users"
                      />
                    </StyledTabList>
                    <StyledTabPanel value="saved" className={classes.tabPanel}>
                      {!loading && (
                        <Grid className={classes.wrapArticles} item xs={12}>
                          {!savedArticles ||
                            (savedArticles.length === 0 && (
                              <Typography align="center" variant="body1">
                                You haven&apos;t saved any posts yet
                              </Typography>
                            ))}
                          {savedArticles && savedArticles.length > 0 && (
                            <ArticleList
                              fromProfile
                              userId={authUser ? authUser.uid : null}
                              articles={sortedSavedArticles || savedArticles}
                              savedArticlesIds={userSavedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                              onCommentLikeClick={this.onCommentLikeClick}
                              makeNewComment={this.makeNewComment}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel value="users">
                      {!loading && (
                        <Grid className={classes.articleWrap} item xs={12}>
                          {!usersArticles ||
                            (usersArticles.length === 0 && (
                              <Typography align="center" variant="body1">
                                You don&apos;t have any posts yet
                              </Typography>
                            ))}
                          {usersArticles && usersArticles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={sortedUsersArticles || usersArticles}
                              savedArticlesIds={userSavedArticlesIds}
                              isEditable={true}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                              onDeleteClick={this.deleteUserPost}
                              onCommentLikeClick={this.onCommentLikeClick}
                              makeNewComment={this.makeNewComment}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

const condition = authUser => !!authUser;

const ProfileDetails = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(withStyles(styles)(ProfileDetailsBase));

ProfileDetailsBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEmailVerification,
  withAuthorization(condition)
)(ProfilePage);

export { ProfileDetails };
